import { ErrorMessage, Field, FormikErrors, FormikTouched } from 'formik';
import cn from 'clsx';
import { ChangeEvent } from 'react';

interface PropsTypes {
  values: {
    password: string;
  };
  message: string;
  handleChange: (e: ChangeEvent<any>) => void;
}
const PasswordForm = (props: PropsTypes) => {
  const { values, message, handleChange } = props;
  return (
    <div className={`psArea mt-[10px]`}>
      <label className="hidden" htmlFor={`password`}>
        Password
      </label>
      <Field
        className={`passwordInput border border-solid border-[#eee] bg-white py-[10px] px-[12px] rounded-[5px] text-[#333] h-[40px] text-[13px] w-full focus:border-primaryRed focus:ring-0 placeholder:text-[11px]`}
        name="password"
        type="password"
        maxLength={15}
        value={values.password}
        onChange={handleChange}
        placeholder="Enter your password (6 characters minimum)"
      />
      {/* 로그인 에러 메세지 출력하기 */}

      <ErrorMessage
        component="p"
        name="password"
        className={cn(
          `erroMsg warning_msg text-primaryRed text-[12px] text-left mt-[4px] mb-[2px] leading-[17px]`,
        )}
      />

      {message && (
        <p
          className={cn(
            `erroMsg warning_msg text-primaryRed text-[12px] text-left mt-[4px] mb-[2px] leading-[17px]`,
          )}
        >
          {message}
        </p>
      )}
    </div>
  );
};
export default PasswordForm;
