import useLogin from '@src/hooks/useLogin';
import cn from 'clsx';
import { BannerStoreModel } from '@toptoon-developers/global.toptoonplus.common.lib/dist/sdk/global/common';
import { PaymentBannerVMType } from '@toptoon-developers/global.toptoonplus.common.lib/dist/sdk/global/HomeViewModel';
import TopcoImg from '../TopcoImg';
import { CrazyTimer } from '../crazyTimer';
import { useRecoilValue } from 'recoil';
import { GlobalProcessContext } from '@src/context/GlobalProcess';
import { globalProcessState } from '@src/atom';
import { useRouter } from 'next/router';
import { TopcoLink } from '../link';
import { openMiniPaymentModal } from '@src/view/modal/payment';
import { useEffect, useState } from 'react';
import { UserSession } from '@src/lib';
import { openLoginModal } from '@src/view/modal/loginAndJoin/login/LoginComponent';

const PaymentBanner = (children: React.ReactNode) => {
  return <>{children}</>;
};

/**
 * global process에서 crazybanner, topPaymentBanner를 가져와 보여짐
 * 메인(+ 선물함) 이외에는 띄우지 않음.
 * @returns
 */
const MainTop = () => {
  const globalProcessContext = useRecoilValue<GlobalProcessContext | null>(
    globalProcessState,
  );

  const router = useRouter();
  const { pathname } = router;

  /**
   * 메인 이외에는 띄우지 않음
   * globalProcessContext 가 null이면 띄우지 않음
   */
  if (pathname !== '/' || !globalProcessContext) return null;

  const { commonCrazyBanner, topPaymentBanner } = globalProcessContext;

  return (
    <CrazyTimer banner={commonCrazyBanner} className="mainTop">
      {topPaymentBanner && (
        <div
          style={{
            background: topPaymentBanner.colorCode,
          }}
          className={`mainTop m-0 h-full relative max-h-[66px] overflow-hidden mo:max-h-[100px]`}
        >
          <TopcoLink.Parent href="/payment" classNames="block h-full">
            <picture className={`my-0 mx-auto`}>
              <source
                srcSet={topPaymentBanner.images.mobile}
                media="(max-width:767px)"
              />
              <img
                src={topPaymentBanner.images.pc}
                alt=""
                className="mx-auto my-0"
              />
            </picture>
          </TopcoLink.Parent>
        </div>
      )}
    </CrazyTimer>
  );
};

const Gift = ({ banner }: { banner: BannerStoreModel | null }) => {
  if (!banner || !banner.images) return null;

  return (
    <section className="w=[100%] max-w-[920px] mt-[0] mx-[auto] mb-[20px] mo:w-[calc(100%+10*2px)] mo:mx-[-10px]">
      <TopcoLink.Parent href="/payment">
        <picture className="block aspect-[920/180] mo:aspect-[750/160]">
          <source srcSet={banner.images.mobile} media="(max-width: 767px)" />
          <TopcoImg
            className="w-[100%]"
            src={banner.images.pc}
            alt="themeBanner"
          />
        </picture>
      </TopcoLink.Parent>
    </section>
  );
};

const Episode = ({
  banner,
  className,
}: {
  banner: string;
  className: string;
}) => {
  const globalProcessContext = useRecoilValue<GlobalProcessContext | null>(
    globalProcessState,
  );
  const [token, setToken] = useState(UserSession.getUserInfo().token);

  useEffect(() => {
    const { token } = UserSession.getUserInfo();
    setToken(token);
  }, []);

  return (
    <CrazyTimer
      banner={
        className === 'paymentMiniTop'
          ? globalProcessContext?.paymentCrazyBanner
          : globalProcessContext?.commonCrazyBanner
      }
      className={className}
    >
      {banner !== '' && (
        <div
          className="lg:block forMoEpisode:block mo:block w-[100%] hidden relative"
          onClick={() => {
            if (!token) {
              openLoginModal();
              return;
            }
            openMiniPaymentModal();
          }}
        >
          <img
            alt={'payment_banner'}
            src={banner}
            className="w-full mo:overflow-hidden"
          />
        </div>
      )}
    </CrazyTimer>
  );
};

const PaymentTop = ({
  banner,
  className,
}: {
  banner: { moImg: string; pcImg: string } | null;
  className: string;
}) => {
  const globalProcessContext = useRecoilValue<GlobalProcessContext | null>(
    globalProcessState,
  );

  return (
    <CrazyTimer
      banner={globalProcessContext?.paymentCrazyBanner}
      className={className}
    >
      {banner && (
        <div className="w-full block relative py-[12.5%] px-0 mo:py-[13.866%] mo:px-0 h-0">
          <picture className="absolute top-0">
            <source srcSet={banner.moImg} media="(max-width: 767px)" />
            <img src={banner.pcImg} alt={'paymentTopBanner'} />
          </picture>
        </div>
      )}
    </CrazyTimer>
  );
};

interface SideMenuProps {
  onClick: (e: any) => void;
  isLoginAfter: boolean;
}
const SideMenu = (props: SideMenuProps) => {
  const { onClick, isLoginAfter = true } = props;

  const globalProcessContext = useRecoilValue<GlobalProcessContext | null>(
    globalProcessState,
  );

  if (!globalProcessContext) return null;

  const { sidemenuPaymentBanner, commonCrazyBanner } = globalProcessContext;

  return sidemenuPaymentBanner || commonCrazyBanner ? (
    <div
      className={cn(
        'absolute -bottom-[23px] py-0 px-[13px] w-full min-h-[36px] cursor-pointer',
        // { 'top-[200px]': !isLoginAfter },
      )}
    >
      <CrazyTimer banner={commonCrazyBanner} className="sideMenu">
        {sidemenuPaymentBanner && (
          <button type="button" onClick={onClick}>
            <img
              className={'rounded-[8px]'}
              src={sidemenuPaymentBanner}
              alt="pay-banner"
            />
          </button>
        )}
      </CrazyTimer>
    </div>
  ) : (
    <></>
  );
};

const ViewerBottom = ({ banner }: { banner: string }) => {
  const { showJoinAndLoginModal } = useLogin();
  const globalProcessContext = useRecoilValue<GlobalProcessContext | null>(
    globalProcessState,
  );

  return (
    <CrazyTimer
      banner={globalProcessContext?.commonCrazyBanner}
      className="viewer"
    >
      {/* ANCHOR: banner img 임시로 하드코딩 처리 */}
      {banner && (
        <img
          className="m-[10px_auto_0] w-full cursor-pointer"
          src={banner}
          alt="payment_banner"
          onClick={() => {
            const { token } = UserSession.getUserInfo();
            if (token === '') {
              showJoinAndLoginModal();
              return;
            }
            openMiniPaymentModal();
          }}
        />
      )}
    </CrazyTimer>
  );
};

PaymentBanner.MainTop = MainTop;
PaymentBanner.Gift = Gift;
PaymentBanner.Episode = Episode;
PaymentBanner.SideMenu = SideMenu;
PaymentBanner.PaymentTop = PaymentTop;
PaymentBanner.ViewerBottom = ViewerBottom;

export default PaymentBanner;
