import {
  TypeCheck,
  UserSessionHeaderType,
} from '@toptoon-developers/global.toptoonplus.common.lib/dist';
import '@toptoon-developers/global.toptoonplus.common.lib/dist/utils/ExtString';
import { v4 as uuid } from 'uuid';
import Cookies from 'js-cookie';
import { TopcoStorage } from '../utils';
import _ from 'lodash';
import { getSSRCookie } from '../utils/utils';
import { UserSession } from '@src/lib/user';
import { TopcoPartner } from '../partner';
import AppManager from '../app/AppManager';
import { PaypalConfirmType } from '@src/view/components/payment/action';
import { UserByVisitorId } from '@src/hooks/useLogin';

export interface CategoryEffectItemType {
  name: string;
  publishedAt: string | null;
  isEffect: boolean;
}

export const uuidv4 = () => {
  return uuid();
};

export enum TopcoStorageType {
  LOGIN_INFO = 'user',
  MATURE_INFO = 'mature',
  COIN_INFO = 'coinInfo',
}

export interface ViewerHistoryItemType {
  comicId: number;
  episodeId?: number;
  scroll?: number;
  index?: number;
}

export default class UserDefault {
  // 17금 썸네일 강제 노출
  static IS_FORCE_17 = true; // 롤빽!!!!

  // 작품을 본 정보 가져오기
  static REMATE_ORIGIN: string = 'remake_origin';

  // 파트너코드 타고 들어온 경우 회차리스트 뒤로가기 버튼 클릭시 홈으로 가도록
  static EPISODE_PARTNER_REFERER: string = 'episode_partner_referer';
  // 현재 정렬값 저장
  static SORT_MEMORY: string = 'sort_memory';
  // 현재 장르값 저장
  static GENRES_MEMORY: string = 'genres_memory';
  // 기다빵 로그아웃시 표기될 수치
  static WAIT_FREE_DEFALUT_COUNT = 3;

  // 파트너 코드 성인 강제 비노출 조건
  static PARTNER_CODE_FORCE_DISABLE_MATURE: string =
    'p_code_force_disable_mature';
  /* -------------------------------------------------------------------------- */
  // ANCHOR: => api header
  /* -------------------------------------------------------------------------- */

  static getUserSession(): UserSessionHeaderType {
    let packageVersion = `${process.env.VERSION}`;

    if (AppManager.getInstance().isStoreApp()) {
      packageVersion = packageVersion.replace('a', 'b');
    }

    const x_origin =
      `${process.env.REACT_APP_PUBLIC_URL}`.split('/')[2] ?? 'comicsdays.com';

    const { userId, token } = UserSession.getUserInfo();
    let result: UserSessionHeaderType = {
      version: packageVersion,
      apiKey: `${process.env.REACT_APP_API_KEY}`,
      baseUrl: `${process.env.REACT_APP_BASE_URL}`,
      uuid: this.env.getUdid(),
      ua: AppManager.getInstance().getPackageName(),
      partnerCode: TopcoPartner.getter(),
      language: TopcoStorage.getItem('i18nextLng') ?? 'en',
      userId: `${userId}`,
      'x-origin': 'comicsdays.com',
      'visitor-id': UserSession.getFingerPrintId(),
      'therok-key': process.env.THEROK_KEY,
    };

    const packageName = AppManager.getInstance().getPackageName();
    if (packageName) {
      result = {
        ...result,
        packageName,
      };
    }

    return result;
  }

  static getUserSessionHeaderSSR(context: any): UserSessionHeaderType {
    const packageVersion = `${process.env.VERSION}`;
    const x_origin =
      `${process.env.REACT_APP_PUBLIC_URL}`.split('/')[2] ?? 'comicsdays.com';
    const uuid = getSSRCookie(context, 'udid');

    return {
      version: packageVersion,
      baseUrl: `${process.env.REACT_APP_BASE_URL}`,
      apiKey: `${process.env.REACT_APP_API_KEY}`,
      uuid: uuid ? uuid : '',
      ua: AppManager.getInstance().getPackageName(),
      partnerCode: TopcoPartner.getter(),
      language: TopcoStorage.getItem('i18nextLng') ?? 'en',
      userId: UserSession.getUserIdBySSR(context),
      'x-origin': x_origin,
      'visitor-id': UserSession.getFingerPrintIdByCookieSSR(context),
    };
  }
  private static apiKeyIteration = 257;

  /**
   * api validation check가 필요한 경우 사용될 header
   * 현재는 v2 api에서 사용되고 content data를 받는 api에서만 사용됨
   */
  static getApiVaildateHeaders(): UserSessionHeaderType {
    const header = {
      ...this.getUserSession(),
      // apiInteration: Number(process.env.REACT_APP_API_KEY_ITERATION),
      apiInteration: this.apiKeyIteration,
    };

    return header;
  }

  /* -------------------------------------------------------------------------- */
  // ANCHOR: => 하드코딩
  /* -------------------------------------------------------------------------- */

  // locale 확인용
  static KEY_USER_LOCALE = 'user_locale';

  static env = {
    // 서버 버전 정보 불러오기
    getServerVersion() {
      const serverVersion = TopcoStorage.getItem('sVersion');
      if (serverVersion) {
        return `build ${serverVersion}`;
      }
      return '';
    },
    setServerVersion(value: string) {
      TopcoStorage.setItem('sVersion', value);
    },
    /**
     * ANCHOR: user Device 값 (난수)
     */
    setUdid() {
      const agent = uuidv4();
      TopcoStorage.setItem('udid', agent);
    },
    getUdid() {
      if (!TopcoStorage.isUsed()) return 'not found window';
      const result = TopcoStorage.getItem('udid');
      if (result) {
        return result.toLowerCase();
      }
      this.setUdid();
      const udid = TopcoStorage.getItem('udid');

      return udid ? udid.toLowerCase() : '';
    },
    // ANCHOR: 쿠키 사용정책 동의
    setAgreeCookiePolicy(isAgree: boolean) {
      const userAgreement = { agree: isAgree };
      TopcoStorage.setItem('agreeCookie', JSON.stringify(userAgreement));
    },
    // ANCHOR: 쿠키 사용 사용자 동의 정보 가져오기
    getAgreeCookiePolicy() {
      return TopcoStorage.getItem('agreeCookie') ? true : false;
    },

    /**
     * @deprecated
     * ANCHOR: 사용자 로케일 정보 저장
     */
    setLocationString(location: string) {
      TopcoStorage.setItem('userLocationString', location);
    },
    /**
     * @deprecated
     * @returns
     */
    getLocationString() {
      const userLocale = TopcoStorage.getItem('userLocationString') ?? null;

      if (userLocale) {
        return userLocale;
      }
      return 'us';
    },
    setLocation(location: string) {
      TopcoStorage.setItem(UserDefault.KEY_USER_LOCALE, location);
    },
    getLocation() {
      const userLocale =
        TopcoStorage.getItem(UserDefault.KEY_USER_LOCALE) ?? null;

      if (userLocale) {
        return userLocale;
      }
      return 'us';
    },
  };

  /* -------------------------------------------------------------------------- */
  // ANCHOR: => login
  /* -------------------------------------------------------------------------- */

  /**
   * remake 작품을 본 정보를 가져옴
   */
  static getRemakeOriginId() {
    if (Cookies.get(this.REMATE_ORIGIN) === undefined) {
      return 0;
    }
    return Number(Cookies.get(this.REMATE_ORIGIN));
  }

  /**
   * 자동 로그인 후, toast 띄우기위한 cookie
   * toast는 페이지 이동시 사라지기때문에, 페이지 이동 후 띄우기 위해 cookie 사용
   */
  static setTempToast() {
    Cookies.set('temp_toast', 'true', {
      expires: 1,
    });
  }

  static getTempToast() {
    const temp = Cookies?.get('temp_toast');
    if (temp) {
      Cookies.remove('temp_toast');
      return temp;
    }

    return null;
  }

  /**
   * PG 페이먼트 심사용 계정인지 정보 저장
   * @param value 심사용 계정이면 true
   */
  static setIsTestAccout = (value: boolean) => {
    TopcoStorage.setItem('test_account', JSON.stringify(value));
  };

  /**
   * PG 페이먼트 심사용 계정인지 정보 반환
   * @returns true => 심사용 계정
   */
  static getIsTestAccout = () => {
    const value = TopcoStorage.getItem('test_account');
    if (!value) return false;
    return JSON.parse(value);
  };

  /**
   * PG 페이먼트 심사용 계정인지 정보를 로컬스토리지에서 삭제
   */
  static removeIsTestAccout = () => {
    TopcoStorage.removeItem('test_account');
  };
  /* -------------------------------------------------------------------------- */
  // ANCHOR: => 사전 인증
  /* -------------------------------------------------------------------------- */

  /**
   * 성인 메뉴 활성화 / 비활성화 처리
   */
  static getNormalMode() {
    // 파터너 유입시 비활성화(광고 검수)
    const { is17 } = TopcoPartner.getInfo();

    if (is17) return true;

    return Cookies.get('snm') === '0';
  }

  /**
   * 회원 가입 클릭 이벤트 확인
   * 210907 플래그 기반 수정 처리
   */
  static clickSignUpEvent() {
    this.setSignUpCheck();
  }

  /**
   * ANCHOR: 회원 가입 체크 플래그 활성화
   */
  static setSignUpCheck() {
    const value = { timestamp: new Date().getTime() };
    TopcoStorage.setItem('signUp', JSON.stringify(value));
  }

  /* -------------------------------------------------------------------------- */
  // ANCHOR: => 성인확인 코드 (회원 가입 및 로그인시 사용됨)
  /* -------------------------------------------------------------------------- */

  /**
   * ANCHOR: 성인 플래그 체크
   * @param value 0 비대상 1 대상
   */
  static set18Over(value: number) {
    TopcoStorage.setItem('checked18Over', `${value}`);
  }

  static isWindowCheck() {
    return typeof window !== 'undefined';
  }
  /**
   * ANCHOR: 회원 가입시 성인 플래그 가져오기
   */
  static get18Over(): number {
    // 0 비대상 1 대상
    let result = TopcoStorage.getItem('checked18Over') ?? 0;

    const code = TopcoPartner.getter();

    result = code === '' ? result : 0;
    return Number(result);
  }

  /* -------------------------------------------------------------------------- */
  // ANCHOR: => 결제 관련
  /* -------------------------------------------------------------------------- */

  /**
   * ANCHOR: 코인 정보 갱신을 위한 쿠키 삭제
   */
  static coinResetManual() {
    Cookies.remove('coinRefresh');
  }

  /* -------------------------------------------------------------------------- */
  // ANCHOR: => Viewer Info 저장용
  /* -------------------------------------------------------------------------- */
  private static VIEWER_CONTENT_DATA = 'vc_content';

  static viewerContent = {
    clear: () => {
      TopcoStorage.removeItem(this.VIEWER_CONTENT_DATA);
    },
    setter: (content: string) => {
      TopcoStorage.setItem(this.VIEWER_CONTENT_DATA, content);
    },
    getter: () => {
      const selectItem = TopcoStorage.getItem(this.VIEWER_CONTENT_DATA);

      if (selectItem) {
        const result = selectItem.decodeString('test');
        const data = JSON.parse(result);
        return data;
      }
      return null;
    },
  };

  /* -------------------------------------------------------------------------- */
  // ANCHOR: => Viewer scroll 저장용 (읽었던 만화 스크롤 위치 저장)
  /* -------------------------------------------------------------------------- */
  private static VIEWER_HIS_KEY = 'viewer_history_v2';
  private static VIEWER_HIS_SIZE = 3;

  static viewerHistory = {
    clear: () => {
      Object.keys(TopcoStorage).forEach(key => {
        if (!key.startsWith('viewer_history')) return;
        if (key === this.VIEWER_HIS_KEY) return;
        TopcoStorage.removeItem(key);
      });
    },
    setter: (viewerHistoryItem: ViewerHistoryItemType) => {
      const { comicId, episodeId } = viewerHistoryItem;

      if (!TopcoStorage) return;

      const data = TopcoStorage.getItem(this.VIEWER_HIS_KEY) ?? null;
      if (!data) {
        const arr = [];
        arr.push({ comicId, episodeId, scroll: 0, index: 0 });

        TopcoStorage.setItem(this.VIEWER_HIS_KEY, JSON.stringify(arr));
      } else {
        const queue = JSON.parse(data);
        const item = _.find(queue, { comicId });

        if (item) {
          if (item.episodeId === episodeId) return;

          item.episodeId = episodeId;
          item.scroll = 0;
          item.index = 0;
        } else {
          queue.push({ comicId, episodeId, scroll: 0, index: 0 });
        }

        if (queue.length > this.VIEWER_HIS_SIZE) {
          queue.shift();
        }
        TopcoStorage.setItem(this.VIEWER_HIS_KEY, JSON.stringify(queue));
      }
    },
    update: (viewerHistoryItem: ViewerHistoryItemType) => {
      const { comicId, episodeId, scroll } = viewerHistoryItem;
      const data = TopcoStorage.getItem(this.VIEWER_HIS_KEY) ?? null;
      if (!data || scroll === 0) return;

      const index = Number(TopcoStorage?.getItem('viewer_index')) + 2;

      const queue = JSON.parse(data);
      const item = _.find(queue, { comicId });
      if (item) {
        if (scroll) item.scroll = scroll;
        if (index) item.index = index;
      }
      TopcoStorage.setItem(this.VIEWER_HIS_KEY, JSON.stringify(queue));
    },
    getter: (viewerHistoryItem: ViewerHistoryItemType) => {
      const { comicId } = viewerHistoryItem;
      const data = TopcoStorage.getItem(this.VIEWER_HIS_KEY);

      if (!data) return;

      const queue = JSON.parse(data);

      if (queue) {
        const item = _.find(queue, { comicId });
        return item;
      }

      return null;
    },
  };

  /* -------------------------------------------------------------------------- */
  // ANCHOR: => 카테고리 (new) 최신정보 effect 추가
  /* -------------------------------------------------------------------------- */
  private static CATEGORY_EFFECT_KEY = 'category_effect';

  static categoryEffect = {
    clear: () => {
      TopcoStorage.removeItem(this.CATEGORY_EFFECT_KEY);
    },
    isItemEffect: (name: string): boolean => {
      const data = TopcoStorage.getItem(this.CATEGORY_EFFECT_KEY);
      if (!data) return false;

      const list: CategoryEffectItemType[] = JSON.parse(data);
      const item = _.find(list, { name });
      if (!item) return false;

      return item.isEffect;
    },
    getter: (): CategoryEffectItemType[] => {
      const data = TopcoStorage.getItem(this.CATEGORY_EFFECT_KEY);
      if (!data) return [];

      const list = JSON.parse(data);
      if (list) return list;
      return [];
    },
    setter: (name: string, date: string | null) => {
      const data = TopcoStorage.getItem(this.CATEGORY_EFFECT_KEY);
      if (!data) {
        const array: CategoryEffectItemType[] = [];
        array.push({
          name,
          publishedAt: date,
          isEffect: true,
        });

        TopcoStorage.setItem(this.CATEGORY_EFFECT_KEY, JSON.stringify(array));
      } else {
        const list: CategoryEffectItemType[] = JSON.parse(data);

        const item = _.find(list, { name });

        if (item) {
          if (item.publishedAt === date) return;

          item.isEffect = true;
          item.publishedAt = date;
        } else {
          list.push({
            name,
            publishedAt: date,
            isEffect: true,
          });
        }

        TopcoStorage.setItem(this.CATEGORY_EFFECT_KEY, JSON.stringify(list));
      }
    },
    update: (name: string, isEffect: boolean) => {
      const data = TopcoStorage.getItem(this.CATEGORY_EFFECT_KEY);

      if (!data) return;

      const list = JSON.parse(data);
      const item = _.find(list, { name });
      if (!item) return;

      item.isEffect = isEffect;

      TopcoStorage.setItem(this.CATEGORY_EFFECT_KEY, JSON.stringify(list));
    },
  };

  /**
   * ANCHOR: Thumbnail이 17금 모드인지 확인 (성인인증된 유저가 비성인 모드일경우도 17금으로 처리)
   * @param auth
   * @param matureState
   */
  static is17Mode(auth: number, matureState: number) {
    const { is17, code } = TopcoPartner.getInfo();
    if (!is17 && code !== '') {
      return false;
    }

    if (auth === 1 && matureState === 1) return false;
    return true;
  }

  /**
   * ANCHOR: 프리티켓(선물함) 최초 1회 노출 정책 수행 적용 페이지에서 Goods값 확인 후 수행처리
   * @return {boolean} 로컬스토리지 기준 없을 시 true
   */
  static freeTicketVisiblePolicy(): boolean {
    if (TopcoStorage.getItem('freeTicketOneTime')) {
      return false;
    }
    return true;
  }

  static freeTicketVisibleDisable() {
    localStorage.setItem('freeTicketOneTime', 'true');
  }

  // ANCHOR: 마케팅을 통해 회차리스트 진입시 뒤로가기 버튼 클릭시 홈으로 이동하도록
  /* -------------------------------------------------------------------------- */
  static episodePartnerReferer = {
    setter: () => {
      sessionStorage.setItem(this.EPISODE_PARTNER_REFERER, 'a');
    },
    getter: () => {
      return sessionStorage.getItem(this.EPISODE_PARTNER_REFERER) ?? null;
    },
    clear: () => {
      sessionStorage.removeItem(this.EPISODE_PARTNER_REFERER);
    },
  };

  // ANCHOR: 정렬값 유지
  /* -------------------------------------------------------------------------- */
  static sortMemory = {
    setter: (key: 'genres' | 'artworks' | 'completed', value: number) => {
      const prevData = sessionStorage.getItem(this.SORT_MEMORY) ?? '{}';
      const data = JSON.stringify({
        ...JSON.parse(prevData),
        [key]: value,
      });
      sessionStorage.setItem(this.SORT_MEMORY, data);
    },

    getter: (key: 'genres' | 'artworks' | 'completed') => {
      const prevData = sessionStorage.getItem(this.SORT_MEMORY) ?? '';
      if (prevData) {
        return JSON.parse(prevData)?.[key];
      }
      return 0;
    },
    clear: () => {
      sessionStorage.removeItem(this.SORT_MEMORY);
    },
  };
  // ANCHOR: 선택 장르 유지
  /* -------------------------------------------------------------------------- */
  static activeGenreMemory = {
    setter: (value: string) => {
      sessionStorage.setItem(this.GENRES_MEMORY, value);
    },
    getter: () => {
      const prevData = sessionStorage.getItem(this.GENRES_MEMORY) ?? '';
      return prevData;
    },
    clear: () => {
      sessionStorage.removeItem(this.GENRES_MEMORY);
    },
  };

  static isDevEnv() {
    return `${process.env.REACT_APP_BASE_URL}`.includes('staging');
  }

  /* -------------------------------------------------------------------------- */
  // ANCHOR: modal 관련 조건들
  /* -------------------------------------------------------------------------- */
  /**
   * 자정(23시59분59초) 자동 삭제 모달
   */
  static setModalClearMidnight(key: string) {
    const date = new Date();
    const resetDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59),
    );

    Cookies.set('key', 'true', { expires: resetDate });
  }

  /**
   * 211006 가입완료 레이어 체크 플래그 제거
   */
  static resetJoinRedirect() {
    Cookies.remove('joinRedirect2');
  }

  /**
   * fingerprint에 연결된 아이디와 자동로그인한 아이디가 다를때 modal 모달 띄움
   * @param loginId
   * @param provider
   */
  static setIsAutoLoginNotice = (user: UserByVisitorId) => {
    const value = JSON.stringify(user);
    Cookies.set('is_notice_a_l', value, { expires: 1000 * 60 });
  };

  static getIsAutoLoginNotice = () => {
    try {
      const value = Cookies.get('is_notice_a_l') || '';
      return JSON.parse(value);
    } catch (e) {
      return '';
    }
  };

  static removeIsAutoLoginNotice = () => {
    Cookies.remove('is_notice_a_l');
  };

  /* -------------------------------------------------------------------------- */
  // ANCHOR: PRODUCT 사용
  /* -------------------------------------------------------------------------- */
  static payment = {
    isPgTest(): boolean {
      return TopcoStorage.getItem('pgtest') !== null;
    },
    setBuckets(refOrderIds: any) {
      Cookies.set('buckets', JSON.stringify(refOrderIds), { expires: 1 });
    },
    getBuckets() {
      return Cookies.get('buckets') ?? '';
    },
    removeBuckets() {
      Cookies.remove('buckets');
    },
    setBucketsNormal(refOrderIds: any) {
      const encode = JSON.stringify(refOrderIds).unicode().encodeString('test');
      Cookies.set('bucketsNormal', encode, { expires: 1 });
    },
    getBucketsNormal() {
      const data = Cookies.get('bucketsNormal');
      if (!data) return null;

      const decode = data.decodeString('test');
      return JSON.parse(decode);
    },
    removeBucketsNormal() {
      Cookies.remove('bucketsNormal');
    },
    setUserPaymentInfo(request: any) {
      if (window) {
        // 유효기간 5분
        const expiredAt = new Date(new Date().getTime() + 5 * 60 * 1000);
        Cookies.set('payInfo-310', JSON.stringify(request), {
          expires: expiredAt,
        });
      }
    },
    getUserPaymentInfo(token: string) {
      const payInfo = Cookies.get('payInfo-310');
      if (payInfo) {
        const request = JSON.parse(payInfo);
        if (TypeCheck.isValidObject(request)) {
          const data: PaypalConfirmType = {
            accessToken: token,
            userId: TypeCheck.itemsByPath(request, 'userId'),
            pg: TypeCheck.itemsByPath(request, 'pg'),
            orderId: TypeCheck.itemsByPath(request, 'orderId'),
            referenceId: TypeCheck.itemsByPath(request, 'referenceId'),
          };
          return data;
        }

        return null;
      }
    },
  };

  /* -------------------------------------------------------------------------- */
  // ANCHOR: server check
  /* -------------------------------------------------------------------------- */
  static serverCheck = {
    setter: () => {
      const expiredAt = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
      Cookies.set('server-check', 'update', { expires: expiredAt });
    },
    getter: (): string | null => {
      return Cookies.get('server-check') || null;
    },
  };
}
