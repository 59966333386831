import { UserDefault, UserSession } from '@src/lib';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import ApiPayment from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/rest/ApiPayment';
import { PaypalPurchase } from '@src/view/components/payment/action';
import { ProductItemViewModel } from '@src/view/components/payment/viewModel';
import { useEffect, useState } from 'react';

interface PropsType {
  product: ProductItemViewModel;
  orderId?: string;
  onCancel: () => void;
  onResponse: (res: boolean, message: string) => void;
}

const PaypalBtn = (props: PropsType) => {
  const { product, orderId, onResponse, onCancel } = props;
  const { discountPrice = '' } = product;
  const { token, userId } = UserSession.getUserInfo();

  const initialOptions = {
    'client-id': `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
    currency: product.currencyCode || 'USD',
    intent: 'capture',
    // debug: true,
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        style={{ layout: 'vertical' }}
        createOrder={(data, actions) => {
          let value = '';
          if (discountPrice) value = discountPrice;
          else if (product) value = `${product.price}`;
          const result = actions.order.create({
            purchase_units: [
              {
                reference_id: orderId,
                amount: {
                  value,
                },
              },
            ],
            application_context: {
              shipping_preference: 'NO_SHIPPING',
            },
          });

          return new Promise<string>(resolve => {
            result.then(r => {
              // Api test 중 (paypal)
              ApiPayment.getInstance(UserDefault.getUserSession())
                .paymentUpdateOrderId(`${orderId}`, r, token)
                .then(() => {
                  resolve(r);
                })
                .catch(() => {
                  resolve(r);
                });
            });
          });
        }}
        onApprove={(data, actions) => {
          if (!actions || !actions.order) {
            throw new Error('payment fail');
          }

          return (
            actions.order
              .capture()
              .then(details => {
                const pg = 'paypal';
                const { id } = details;
                const request = {
                  accessToken: token,
                  pg,
                  orderId: id,
                  referenceId: orderId || '',
                  userId,
                };

                return request;
              })
              // .then(request => PaypalPurchase.paypalConfirm(request, ''))
              .then((response: any | null) => {
                if (response !== null) {
                  onResponse(response, '');
                } else {
                  onResponse(response, '');
                }
              })
              .catch(error => {
                console.warn('pay error', error);
                throw new Error('payment fail');
              })
          );
        }}
        onCancel={data => {
          onCancel();
        }}
        onError={error => {
          console.warn(error);
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PaypalBtn;
