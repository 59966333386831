import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { fetchMature } from '@src/atom/fetchMature';
import useUserCoin from '@src/hooks/useUserCoin';
import { UserDefault, UserSession } from '@src/lib';
import { showAutoLoginAfterModal } from '@src/view/modal/loginAndJoin/autoLoginAfter/AutoLoginAfterModal';
import { AccountInfoDom } from '@src/view/modal/loginAndJoin/common';
import { _days } from '@toptoon-developers/global.toptoonplus.common.lib';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useSetRecoilState } from 'recoil';

/**
 * useAppContext에 있던 내용
 * 모든페이지에서 새로고침될때 동작하기 위함.
 * @returns
 */
const UserCoinResponse = () => {
  const router = useRouter();
  const { getUserData, refreshLogo, refreshSnb, menuUpdate } = useUserCoin();
  const setFetchMature = useSetRecoilState(fetchMature);

  const [extendedResult, updateExtendedResult] = useState(false);
  const { isLoading, error, data, getData } = useVisitorData(
    { extendedResult: false },
    { immediate: false },
  );
  const { addToast } = useToasts();

  useEffect(() => {
    if (UserDefault.getIsAutoLoginNotice() !== '') {
      showAutoLoginAfterModal();
    } else if (UserDefault.getTempToast() && UserSession.getUserInfo().userId) {
      const { loginId, provider } = UserSession.getUserInfo();

      addToast(
        <>
          Signed in with your existing account.
          <br />
          {AccountInfoDom({
            isEmail: loginId.includes('@'),
            loginId: loginId,
            provider: provider,
            type: 'TOAST',
          })}
        </>,
        { autoDismissTimeout: 3 * 1000 },
      );
    }
  }, []);

  useEffect(() => {
    if (extendedResult) {
      getData();
    }
  }, [extendedResult]);

  useEffect(() => {
    if (!data) return;
    // if (!data.visitorFound) return;

    UserSession.setFingerPrintId(data.visitorId);
    updateExtendedResult(false);
  }, [data]);

  useEffect(() => {
    getFingerKey();
    refreshLogo();
    refreshSnb();
    menuUpdate(router);

    const { token, mature } = UserSession.getUserInfo();

    setFetchMature({ isFetchMature: mature === 1 });
    if (!token) return;

    const isPathCheck = (path: string) => {
      const list = [
        '/comic/[comicId]/[episodeId]',
        '/content/[comicId]/[episodeId]',
        '/comic/[comicId]',
        '/content/[comicId]',
        '/gift',
        '/mypage',
        '/cb/[pg]',
        '/complete/[payment]',
        '/coupon',
        '/genres',
      ];

      return list.includes(path);
    };

    const pathname = router.pathname;

    if (isPathCheck(pathname)) {
      getUserData(token);
      return;
    }

    const { refreshAt } = UserSession.getCoinInfo();

    if (refreshAt) {
      const now = dayjs().utc();
      const refreshDate = dayjs(refreshAt).utc();
      // refreshAt 시간과 현재 시간을 utc로 비교하여 갱신 유무 결정
      if (now.diff(refreshDate) >= 0) {
        getUserData(token);
        return;
      }
    }

    /**
     * 해당 쿠키값은 coin 정보 획득시 생성 되며 ttl이 종료되어 삭제 되었을 경우 다시 요청하는 용도로 사용
     */
    const refresh = Cookies.get('coinRefresh');
    if (!refresh) {
      getUserData(token);
      return;
    }
  }, [router]);

  const getFingerKey = useCallback(() => {
    /**
     * localhost에서는 fingerprint 예외처리
     */
    if (process.env.THEROK_KEY) return;

    const fId = UserSession.getFingerPrintId();
    if (fId.length === 0) {
      updateExtendedResult(true);
    } else {
      UserSession.setFingerPrintIdByCookie(fId);
    }
  }, [router]);

  return <></>;
};

export default UserCoinResponse;
