import { GroupTypes } from '../data/_constants';

export enum TopcoPaymentRequest {
  getProductList = 'product/lists',
}

export enum Code {
  LoginNonPaid = 'LOGIN_NON_PAID',
  NonPaid = 'NON_PAID',
  ReturnerNonPaid = 'RETURNER_NON_PAID',
  Today = 'TODAY',
}

export interface Info {
  title: string;
  code: string;
  userGroupCode: string | null;
  description: string;
}

export interface BonusCoin {
  coin: number;
  label: string;
  name: string;
  order: number;
  period: number;
  periodUnit: string;
  type: number;
}

export interface ProductDescription {
  code: Code[];
}

export interface PaymentBannerType {
  moImg: string;
  pcImg: string;
}

export interface ProductItemViewModel {
  id: number;
  info: Info;
  productId: number;
  storeId?: string;
  name: string;
  originalPrice: string;
  price: string;
  currencyCode: string;
  coin: number;
  type: number;
  order: number;
  comment: string;
  description: string;
  defaultProduct: number;
  productType: string;
  productDescription: ProductDescription;
  planId: null | string;
  groups: Info[];
  groupString: string | null;
  bonusCoin: BonusCoin[];
  icons: string[];
  cycle?: string; // membership 결제 주기
  discountPrice?: string | undefined;
}

export interface PgItemType {
  type: string;
}

export interface PaymentMethodViewModel {
  id: number;
  pg: string;
  method: string;
  deviceBit: number;
  matureBit: number;
  paymentType: string;
  order: number;
  display: number;
}

export declare module DiscountCouponModel {
  export interface Description {
    discountPrice?: string;
    minimumPrice: string;
    currencyCode: string;
  }

  export interface DiscountCoupon {
    goodsId: number;
    goodsType: string;
    name: string;
    availableAt: string;
    expiredAt: string;
    quantity: number;
    description: Description;
  }

  export interface RootObject {
    goodsDiscountCoupon: DiscountCoupon[];
  }
}
