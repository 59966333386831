import { UserDefault } from '@src/lib';
import AppManager from '@src/lib/app/AppManager';
import ApiApp from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/rest/ApiApp';

const useAppData = () => {
  const updateFcmToken = (
    accessToken: string,
    fcmToken: string,
    version: string,
  ) => {
    if (accessToken === null) return;

    if (fcmToken.length === 0) return;

    ApiApp.getInstance(UserDefault.getUserSession())
      .updatePushToken(accessToken, fcmToken, `${version}`)
      .then((res: any) => {})
      .catch((err: any) => {});
  };

  return { updateFcmToken };
};

export default useAppData;
