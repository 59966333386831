import { ModalWrapper } from '@src/lib/CustomModal';
import { CloseBtn } from '@src/view/common/closeBtn';
import React, { useEffect, useMemo } from 'react';
import cn from 'clsx';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import { UserDefault, UserSession } from '@src/lib';
import { AccountInfoDom } from '../common';

const EXIST_ID_MODAL = 'autoLoginAfterModal';

export const closeAutoLoginAfterModalModal = () => {
  ModalInstance.getInstance().delete(EXIST_ID_MODAL);
  UserDefault.removeIsAutoLoginNotice();
};

export const showAutoLoginAfterModal = () => {
  ModalInstance.getInstance().push({
    key: EXIST_ID_MODAL,
    shouldCloseOnOverlayClick: false,
    shouldCloseOnOverlayCallback: (e: any) => {
      closeAutoLoginAfterModalModal();
    },
    component: <AutoLoginAfterModal />,
  });
};

/**
 * 자동로그인 됐지만 핑거트린트 아이디에 연결된 아이디와 다른 계정으로 로그인 됐을때 팝업
 * @param props
 * @returns
 */
const AutoLoginAfterModal = () => {
  const existUserInfo = UserDefault.getIsAutoLoginNotice();
  const loginUserInfo = UserSession.getUserInfo();

  return (
    <ModalWrapper.Default addClassName="max-h-[300px] h-[98vh] w-[98vw] max-w-[320px] text-center tracking-0.1 dark:bg-[#222] dark:border-[#494949] dark:border-[1px] dark:text-white">
      <div className="mx-auto overflow-hidden p-0 relative w-full h-full">
        <CloseBtn
          imgPath="/images/alert/close/ico_close.png"
          className="h-[35px] w-[35px] right-[4px] top-[4px] absolute"
          onClick={(e: any) => {
            closeAutoLoginAfterModalModal();
          }}
        />
        <div className="p-[20px] flex justify-between flex-col h-full gap-1 underMo:gap-1">
          {/* notice */}
          <div className="flex justify-center items-center">
            <img src="/images/existId/notice.png" alt="existid_notice_img" />
            <span className="ml-[6px] font-semibold text-[24px] block">
              Notice
            </span>
          </div>
          {/* notice */}

          {/* notice text */}
          <div className="text-[12px] leading-[19.5px]">
            <span className="flex flex-wrap justify-center items-center gap-1">
              <span>You are signed in with</span>
              {AccountInfoDom({
                isEmail: loginUserInfo.loginId.includes('@'),
                loginId: loginUserInfo.loginId,
                provider: loginUserInfo.provider,
                type: 'LOGIN',
              })}
            </span>
            Please sign in with your existing account to be eligible for our
            promos.
          </div>
          {/* notice text */}

          {/* exist account */}
          <div className="border-t-[1px] border-[#494949] text-[14px] leading-[21px] pt-[10px]">
            <span className="text-[#FF6666]">Existing account</span>
            <br />
            {AccountInfoDom({
              isEmail: existUserInfo.isEmail,
              loginId: existUserInfo.loginId,
              provider: existUserInfo.method,
              type: 'EXIST',
            })}
          </div>
          {/* exist account */}

          {/* button */}
          <button
            id="signInButton"
            className={cn(
              `shadow-logInAndJoin max-w-[180px] h-[40px] text-white text-[17px] font-semibold leading-[25.5px] rounded-[50px] w-full mx-auto bg-[#FF323A]`,
            )}
            onClick={() => {
              closeAutoLoginAfterModalModal();
            }}
          >
            Close
          </button>
          {/* button */}
          <span className="text-666 text-[10px] leading-[15px]">
            For inquiries, please contact our customer support.
          </span>
        </div>
      </div>
    </ModalWrapper.Default>
  );
};

export default AutoLoginAfterModal;
