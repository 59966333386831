export const enum LogoImgPathType {
  DEFAULT = '/images/common/logo/logo_daycomics.png', // 기본 로고
  SECOND = '/images/common/logo/logo_blackfriday.png', // 미사용
  MEMBERSHIP_BASIC = '/images/common/logo/logo_basic_dk.png',
  MEMBERSHIP_STANDARD = '/images/common/logo/logo_standard.png',
  MEMBERSHIP_PREMIUM = '/images/common/logo/logo_premium_membership_dk.png',
  EVENT = '/images/common/logo/logo_0214.png', // 이벤트 용 로고
}

export interface LogoType {
  path: string;
}
