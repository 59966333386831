
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '../styles/globals.css';
import type { AppProps } from 'next/app';
import 'swiper/css';
import 'swiper/css/navigation';
// import 'swiper/css/pagination';  // custom pagination 사용을 위한 주석처리
import { initializeApp } from '@firebase/app';
import { FirebaseOptions } from '@firebase/app-types';
import firebaseConfig from '@src/auth/appConfig';
import { ToastProvider } from 'react-toast-notifications';
import { BottomCenterToastType1 } from '@src/view/modal/toast';
import { ModalContainer } from '@src/lib/CustomModal';
import { WithHistory } from '@src/hoc';
import Loading from '@src/view/error/TopcoLoading';
import { RecoilRoot } from 'recoil';
import { TheLayout } from '@src/view/theLayout';
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import { SeoComponent } from '@src/view/common/seo';

const options: FirebaseOptions = firebaseConfig.env.firebaseAuth;
initializeApp(options);

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      {/* <CommonLoading /> */}
      {/* <Loading.Text /> */}
      <Loading.LottieLoading />
      <FpjsProvider
        loadOptions={{
          apiKey: `${process.env.FINGER_PRINT_KEY}`,
          // endpoint: `https://fpapi.${CompanyInfo.HOST_NAME_DEFAULT}.com`,
        }}
      >
        <ToastProvider
          autoDismiss
          autoDismissTimeout={2 * 1000}
          components={{ Toast: BottomCenterToastType1 }}
        >
          <RecoilRoot>
            <ModalContainer />
            <SeoComponent.Default />
            <TheLayout>
              <WithHistory
                propsChildd={{ ...pageProps }}
                WrappedComponent={Component}
              />
            </TheLayout>
          </RecoilRoot>
        </ToastProvider>
      </FpjsProvider>
      {/* </PersistGate>
      </Provider> */}
    </>
  );
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  