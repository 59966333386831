import { UserDefault } from '@src/lib';
import TopcoPageHistory from '@src/lib/utils/topcoPageHistory';
import Loading from '@src/view/error/TopcoLoading';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

interface WithHistoryProps {
  propsChildd: any;
  WrappedComponent: React.ComponentType<any>;
}

const BlockUpdateList: string[] = ['/content', '/comic'];

const WithHistory: React.FC<WithHistoryProps> = props => {
  const { WrappedComponent, propsChildd } = props;

  const router = useRouter();

  const updatePageHistoryScroll = (url: string) => {
    if (BlockUpdateList.find((i: string) => url.startsWith(i))) return;
    TopcoPageHistory.getInstance().updateScroll({
      path: url,
      scroll: window.scrollY,
      fix: '',
    });
  };

  useEffect(() => {
    TopcoPageHistory.getInstance().setter({
      path: router.asPath,
      scroll: window.scrollY,
      fix: '',
    });

    router.events.on('routeChangeStart', updatePageHistoryScroll);

    return () => {
      router.events.off('routeChangeStart', updatePageHistoryScroll);
    };
  }, [router]);

  return (
    <>
      <WrappedComponent {...propsChildd} />
    </>
  );
};

export default WithHistory;
