import { UserDefault } from '@src/lib';
import { NetworkCallback } from '@toptoon-developers/global.toptoonplus.common.lib';
import ApiUser from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/rest/ApiUser';

const useAccount = () => {
  const findPassword = (loginId: string, callback?: NetworkCallback) => {
    ApiUser.getInstance(UserDefault.getApiVaildateHeaders())
      .findPassword(loginId)
      .then((res: any) => {
        callback?.onComplete(res);
      })
      .catch(err => {
        callback?.onError(err);
      });
  };

  return { findPassword };
};

export default useAccount;
