import ApiEvent from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/rest/ApiEvent';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { UserDefault, UserSession } from '../user';
import { TopcoStorage } from '../utils';
import MobileDetect from 'mobile-detect';

const enum PARTNER_KEY {
  // 서버에서 p_id 캐치시 저장 _gen_token_ga2에 저장시 삭제
  PARTNER_CODE_BEFORE = '_gen_req',
  // 파트너 코드 저장용
  PARTNER_CODE = '_gen_token_ga2',
  // 마케팅 MODE (non_addult === 비성인)
  PARTNER__MODE = '_gen_mode',
}

const enum PARTNER_MODE_TYPE {
  NONE = '',
  NON_ADULT = 'na',
  ADULT = 'naxc',
}
interface PartnerItemType {
  pid: string;
  deviceBit: number; // none: 0, pc: 2 mobile : 4 (bit 연산, 0 없음)
  matureBit: number; // 0:비노출 1 비성인 2:성인
  isReferrer: number;
}

const enum DEVICE_BIT {
  PC = 2,
  MOBILE = 4,
}

const enum MATURE_BIT {
  DISABLE = 0,
  NON_ADULT = 1,
  ADULT = 2,
}
const env =
  process.env.REACT_APP_ENVIRONMENT === 'production' ? 'production' : 'staging';
const PartnerListPath = `https://d3juz1fzbt8tvf.cloudfront.net/${env}/partnerCode/partnerCode_en.json`;
// const PartnerListPath = process.env.PARTNER_LiST_JSON_URL ?? '';
export namespace TopcoPartner {
  /**
   * S3 저장소에서 정적 파일로 리스트 호출
   * @param code
   * @returns
   */
  const getCurrentItemJSON = async (code: string): Promise<PartnerItemType> => {
    try {
      const res = await fetch(PartnerListPath);

      const jsonData = await res.json();
      if (jsonData) {
        const r = _.filter(jsonData, v => v.pid === code);

        if (r.length === 0)
          throw new Error('exception TopcoPartner no matches');

        return r[0];
      } else {
        throw new Error('exception TopcoPartner no matches');
      }
    } catch (e) {
      throw new Error('exception TopcoPartner no matches');
    }
  };

  /**
   * API를 통해서  리스트 호출
   * @param code
   */
  const getCurrentItemApi = async (code: string): Promise<PartnerItemType> => {
    throw new Error('exception TopcoPartner no matches');
  };

  /**
   * serverside p_id 들어올 시 저장
   * @param context
   * @returns true일 경웅 p_id 제거
   */
  export const setterSSR = async (context: any): Promise<boolean> => {
    const { query } = context;

    const { p_id } = query;

    // p_id 없으면 동작 안함
    if (!p_id) return false;

    try {
      let ret = false;
      const currentItem = await getCurrentItemJSON(p_id);

      const { referer } = context.req.headers;

      // url을 직접 입력할 경우 main으로 이동 -> isReferrer 값이 1일 때만
      if (currentItem.isReferrer && !referer) {
        return true;
      }

      const md = new MobileDetect(context.req.headers['user-agent'] ?? '');

      // 모바일
      if (md.tablet() || md.mobile()) {
        ret = checkMobile(currentItem);
      } else {
        // tablet, mobile
        ret = checkPC(currentItem);
      }

      const convert = JSON.stringify(p_id).unicode().encodeString('test');

      const inFifteenMinutes = new Date(
        Date.now() + 60 * 60 * 24 * 1000 * 1, //1일
      ).toUTCString();

      let mod = PARTNER_MODE_TYPE.NONE;

      // 비성인 파트너코드
      if (currentItem.matureBit & MATURE_BIT.NON_ADULT)
        mod = PARTNER_MODE_TYPE.NON_ADULT;
      else if (currentItem.matureBit & MATURE_BIT.ADULT)
        mod = PARTNER_MODE_TYPE.ADULT;

      const setCookie = [
        `${PARTNER_KEY.PARTNER_CODE_BEFORE}=${convert}; path=/; expires=${inFifteenMinutes}`,
        `${PARTNER_KEY.PARTNER__MODE}=${mod}; path=/; expires=${inFifteenMinutes}`,
      ];

      context.res.setHeader('set-Cookie', setCookie);

      delete query['p_id'];

      return ret;
    } catch (e) {
      // 오류일 경우 등록되지 않은 파트너 코드로 보고 인덱스로 이동시킴
      console.error(e);
      return true;
    }
  };

  /**
   * server side props에서 저장된 cookie가 존재할 경우에만 동작
   * @param token
   * @returns
   */
  export const setter = async (token: string): Promise<boolean> => {
    if (!TopcoStorage.isUsed()) return false;

    // 입력된 파트너코드
    const code = Cookies.get(PARTNER_KEY.PARTNER_CODE_BEFORE) || '';
    if (code === '') return false;
    const sendCode = JSON.parse(code.decodeString('test'));

    // 저장되어 있던 파트너코드
    const storedCode = Cookies.get(PARTNER_KEY.PARTNER_CODE) || '';

    saveCookie(code);

    // 입력된 코드와 저장되어 있던 코드가 동일하면 통계 로직 잡히지 않도록 처리
    if (
      storedCode !== '' &&
      sendCode === JSON.parse(storedCode.decodeString('test'))
    )
      return false;

    try {
      const result = await ApiEvent.getInstance(
        UserDefault.getUserSession(),
      ).updatePartnerCode(token, {
        partnerCode: sendCode,
      });

      if (_.has(result.data.data, 'mature')) {
        UserSession.setMature(result.data.data.mature);
      }
      return true;
    } catch (e) {
      return false;
    }
  };

  /**
   * 파트너 코드 획득
   * @returns
   */
  export const getter = (): string => {
    if (!TopcoStorage.isUsed()) return '';

    const value = Cookies.get(PARTNER_KEY.PARTNER_CODE) || '';

    if (value === '') return value;

    return JSON.parse(value.decodeString('test'));
  };

  /**
   * 파트너 코드 데이터 획득
   * @returns
   */
  export const getInfo = (): {
    is17: boolean;
    code: string;
  } => {
    const init = {
      is17: false,
      code: '',
    };

    if (!TopcoStorage.isUsed()) return init;

    const pCode = getter();
    if (pCode === '') {
      return init;
    }

    const value = Cookies.get(PARTNER_KEY.PARTNER__MODE) || '';
    init.is17 = value === PARTNER_MODE_TYPE.NON_ADULT;

    return init;
  };

  /**
   * cookie 저장시 기존 server side props에서 받은 cookie 제거
   * @param code
   */
  const saveCookie = (code: string) => {
    if (TopcoStorage.isUsed()) {
      const inFifteenMinutes = new Date(
        new Date().getTime() + 1 * 60 * 60 * 1000,
      );

      Cookies.remove(PARTNER_KEY.PARTNER_CODE_BEFORE);
      Cookies.set(PARTNER_KEY.PARTNER_CODE, code, {
        expires: inFifteenMinutes,
      });
    }
  };

  const checkMobile = (item: PartnerItemType): boolean => {
    if (item.deviceBit & DEVICE_BIT.MOBILE) return true;

    return false;
  };

  const checkPC = (item: PartnerItemType): boolean => {
    if (item.deviceBit & DEVICE_BIT.PC) return true;

    return false;
  };
}
