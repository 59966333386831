import { ErrorMessage, Field, FormikErrors, FormikTouched } from 'formik';
import cn from 'clsx';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import _ from 'lodash';

interface PropsTypes {
  values: {
    email: string;
    domain: string;
  };
  errors: FormikErrors<{
    email: string;
    domain: string;
  }>;

  touched: FormikTouched<{
    email: string;
    domain: string;
  }>;

  type: string; // 'join' || 'login' (gmail.com 처리 구분을 위함)
  handleChange: (e: ChangeEvent<any>) => void;
}

const domainList = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'icloud.com',
  'outlook.com',
  'others',
];

const init = {
  email: '',
  domain: '',
  password: '',
  policyCheckbox: true,
  adCheck: false,
};

const DomainForm = (props: PropsTypes) => {
  const { values = init, errors, touched, handleChange, type } = props;

  const [isInputField, setIsInputField] = useState(false); // 도메인으로 others(직접선택) 클릭했는지
  const [isInitValue, setIsInitValue] = useState(true); // 도메인 선택하기 전인지
  const [isOpen, setIsOpen] = useState(false); // SelectBox 열려있는지

  const domainRef = useRef<any>();

  const clickDomainItem = (e: any) => {
    const domain = e.target.innerText;

    setIsInitValue(false);

    if (domain === 'others') {
      setIsInputField(true);
      values.domain = ''; // 빈 값으로 초기화
    } else {
      values.domain = domain;
    }
  };

  // Select Box 영역 외 클릭 시 닫히도록
  const onClick = (e: any) => {
    if (isOpen && !domainRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', onClick);
    }
    return () => {
      document.removeEventListener('mousedown', onClick);
    };
  });

  return (
    <div className={`emailArea justify-between`}>
      {/* ID */}
      <Field
        className={`emailInput border border-solid border-[#eee] bg-white py-[9px] px-[12px] rounded-[5px] text-[#333] h-[40px] text-[13px] w-[50%] focus:border-primaryRed focus:ring-0 placeholder:text-[11px]`}
        name="email"
        type="text"
        value={values.email}
        onChange={(e: any) => {
          // '@' 입력 시 도메인 select box focus 되도록
          const value = e.target.value;
          if (value.includes('@')) {
            domainRef.current.focus();
            setIsOpen(true);
          } else handleChange(e);
        }}
        placeholder="Enter your email"
        autoFocus
      />
      <div className="w-[7%] text-[13px] text-black inline-block text-center">
        {`@`}
      </div>

      {/* DOMAIN */}
      {isInputField ? (
        // others 눌렀을 때 나오는 input 창
        <Field
          className={`w-[43%] border border-solid border-[#eee] bg-white py-[10px] px-[12px] rounded-[5px] text-[#333] h-[40px] text-[13px] focus:border-primaryRed focus:ring-0 placeholder:text-[11px]`}
          type="text"
          name="domain"
          value={values.domain}
          onChange={handleChange}
          autoFocus
        />
      ) : (
        // Select Box
        <button
          ref={domainRef}
          type="button"
          onClick={() => {
            setIsOpen(prev => !prev);
          }}
          className={cn(
            `domain inline-block w-[43%] text-[11px] text-[#6c7381] leading-[20px] border border-solid border-[#eee] bg-white py-[9px] rounded-[5px] h-[40px] focus:border-[#333] focus:ring-0`,
            {
              'text-[13px] text-[#333]': !isInitValue,
            },
          )}
        >
          <div className="flex justify-between items-center px-[12px]">
            <div className="truncate">
              {values.domain !== '' ? values.domain : 'Choose your domain'}
            </div>
            <div>
              <img src="/images/common/arrow_down_black.png" alt="arrow" />
            </div>
          </div>

          <div
            className={cn(
              'relative text-left py-[4px] z-[10] w-full bg-[#fff] mt-[10px] border border-[#333] border-solid rounded-[5px]',
              {
                block: isOpen,
                hidden: !isOpen,
              },
            )}
          >
            {_.map(domainList, (item, index) => {
              return (
                <div key={index} className="group">
                  <div
                    className={
                      'z-[9] py-[4px] px-[19px] group-hover:bg-[#EFEFEF]'
                    }
                    onClick={clickDomainItem}
                  >
                    {item}
                  </div>
                </div>
              );
            })}
          </div>
        </button>
      )}

      {/* 에러 문구 출력 */}
      {errors.email && touched.email && (
        <ErrorMessage
          component="p"
          name="email"
          className={cn(
            `erroMsg warning_msg text-primaryRed text-[12px] text-left mt-[4px] mb-[2px] leading-[17px]`,
          )}
        />
      )}
      {errors.domain && touched.domain && (
        <ErrorMessage
          component="p"
          name="domain"
          className={cn(
            `erroMsg warning_msg text-primaryRed text-[12px] text-left mt-[4px] mb-[2px] leading-[17px]`,
          )}
        />
      )}
    </div>
  );
};
export default DomainForm;
