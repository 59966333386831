import { PaymentWindowModeType, ProductGroupType } from '../../data/_constants';
import { ProductItemViewModel, TopcoPayment } from '../../viewModel';
import { ProductList } from './list';
import { UserInfoType } from '@src/lib/user/types';
import AppManager from '@src/lib/app/AppManager';
import Cookies from 'js-cookie';

interface PropsType {
  windowMode: PaymentWindowModeType;
  productList: ProductItemViewModel[];
  selectProductItem: ProductItemViewModel | null;
  isStoreApp: boolean;
  loginInfo: UserInfoType;
  token: string;
  topcoPayment: TopcoPayment;
  setSelectItem?: (item: ProductItemViewModel) => void;
}

const ProductContent = (props: PropsType) => {
  const {
    topcoPayment,
    windowMode,
    selectProductItem,
    setSelectItem,
    isStoreApp,
    loginInfo,
    token,
  } = props;

  return (
    <>
      {/* <div className="flex items-center justify-between mb-[14px]"> */}
      {/* <div className="text-[.9rem] font-[500] text-black">
          Please select a product
        </div> */}

      {/* membership 없을시 숨김 */}
      {/* {topcoPayment.getGroupByMembership().length && (
          <button
            type="button"
            className="min-w-[17px]"
            onClick={e => {
              openMembershipInfoModadl();
            }}
          >
            <img
              src="/images/payment/ico_detail_gray.svg"
              alt="membership-notice"
            />
          </button>
        )} 
      </div> */}

      {/* 원래 gap-y-[8px] 속성 있었음 - (22.12.26) 리뉴얼로 인해 속성 제거 */}
      <div className="tablet:p-0 tablet:-mt-[20px] flex flex-col">
        {/* oneDollar 상품 리스트 */}
        {/* {TypeCheck.isValidArray(topcoPayment.getGroupByOneDollar()) && (
            <ProductItemType1 {...props} />
          )} */}
        {/* oneDollar 상품 리스트 */}
        {/* 코인 월 자동충전 상품 리스트 */}
        {/* {TypeCheck.isValidArray(topcoPayment.getGroupByAutoPayment()) && (
            <ProductItemType2 {...props} />
          )} */}
        {/* 코인 월 자동충전 상품 리스트 */}

        {/* 리뉴얼 상품 리스트 (22.12.26) */}
        {/* <ProductList
          groupType={ProductGroupType.membership}
          windowMode={windowMode}
          selectProductItem={selectProductItem}
          productItems={topcoPayment.getGroupByMembership()}
          setSelectItem={setSelectItem}
        /> */}
        {/* 리뉴얼 상품 리스트 (22.12.26) */}

        {/* 멤버십 상품 리스트 */}
        {/* <ProductList
          groupType={ProductGroupType.membership}
          windowMode={windowMode}
          selectProductItem={selectProductItem}
          productItems={topcoPayment.getGroupByMembership()}
          setSelectItem={item => {
            setSelectItem && setSelectItem(item);
            if (isStoreApp) {
              if (!loginInfo) return;
              AppManager.getInstance().currentPage.setter();
              const { userId } = loginInfo;
              AppManager.getInstance().action.inappBilling(
                `pid=${item.productId}&userId=${userId}&accessToken=${token}&storeId=${item.storeId}&type=recurring`,
              );
            }
          }}
        /> */}
        {/* 멤버십 상품 리스트 */}

        {/* 일반 상품 리스트 */}
        {/* 리뉴얼 상품 리스트 (22.12.26) */}
        <ProductList
          groupType={ProductGroupType.normal}
          windowMode={windowMode}
          selectProductItem={selectProductItem}
          productItems={topcoPayment.getGroupByNormal()}
          setSelectItem={item => {
            setSelectItem && setSelectItem(item);
            if (isStoreApp) {
              if (!loginInfo) return;
              AppManager.getInstance().currentPage.setter();
              const { userId } = loginInfo;

              Cookies.remove('coinRefresh');

              AppManager.getInstance().action.inappBilling(
                `pid=${item.productId}&userId=${userId}&accessToken=${token}&storeId=${item.storeId}&type=normal`,
              );
            }
          }}
        />

        {/* 리뉴얼 상품 리스트 (22.12.26) */}
        {/* 일반 상품 리스트 */}
      </div>
    </>
  );
};

export default ProductContent;
