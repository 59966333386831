import React from 'react';
import SEO, {
  OrganizationJS,
  mainSEO,
  originalsSEO,
  newSEO,
  genresSEO,
  rankingSEO,
  artworksSEO,
} from 'next-seo.config';
import { NextSeo, DefaultSeo, OrganizationJsonLd } from 'next-seo';
import { SeoItemVM } from './types';

const SeoComponent = (children: React.ReactNode) => {
  return { children };
};

const DEFAULT_PATH = 'https://comicsdays.com';
const DEFAULT_TITLE =
  'DAYCOMICS | manhwa | toon | comics | free | read | comic | online | cartoons | korean | Manga | webtoon';

const Default = () => {
  return (
    <>
      <DefaultSeo {...SEO} />
      {/* <OrganizationJsonLd {...OrganizationJS} /> */}
    </>
  );
};

const Footer = (props: SeoItemVM) => {
  const { seoItemDes, showSeo } = props;
  if (!showSeo) return null;

  return (
    <div id="seoItemDes" className="sr-only">
      {seoItemDes}
    </div>
  );
};

interface EpiAndViewerPropTypes {
  title: string;
  path: string;
  description: string;
  thumbnail: {
    path: string;
    width: number;
    height: number;
  };
}

const Epi = (props: EpiAndViewerPropTypes) => {
  const { path, thumbnail } = props;
  const title = props.title === '' ? DEFAULT_TITLE : props.title;
  const description =
    props.description === '' ? SEO.description : props.description;

  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        type: '',
        url: `${DEFAULT_PATH}${path}`,
        title,
        description,
        images: [
          {
            url: thumbnail.path,
            width: thumbnail.width,
            height: thumbnail.height,
            alt: '',
            type: '',
          },
        ],
      }}
      additionalMetaTags={[
        {
          name: 'keywords',
          content: `${title}, webtoon, comics, comic, comix, webtoons, cartoons, cartoon, comicbook, comicbooks, manhwa, manhua, manga`,
        },
        {
          name: 'twitter:url',
          content: `${DEFAULT_PATH}${path}`,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
        {
          name: 'twitter:image',
          content: thumbnail.path,
        },
        {
          name: 'twitter:image:width',
          content: `${thumbnail.width}`,
        },
        {
          name: 'twitter:image:height',
          content: `${thumbnail.height}`,
        },
      ]}
    />
  );
};

const Viewer = (props: EpiAndViewerPropTypes) => {
  const { thumbnail, path } = props;
  const title = props.title === '' ? DEFAULT_TITLE : props.title;
  const description =
    props.description === '' ? SEO.description : props.description;

  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        url: path,
        title: title,
        description: description,
        images: [
          {
            url: thumbnail.path,
            width: thumbnail.width,
            height: thumbnail.height,
          },
        ],
        site_name: 'comicsdays.com',
      }}
      additionalMetaTags={[
        {
          name: 'keywords',
          content: `${title}, webtoon, comics, comic, comix, webtoons, cartoons, cartoon, comicbook, comicbooks, manhwa, manhua, manga`,
        },
        {
          name: 'twitter:url',
          content: `${DEFAULT_PATH}${path}`,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: 'twitter description',
        },
        {
          name: 'twitter:image',
          content: thumbnail.path,
        },
        {
          name: 'twitter:image:width',
          content: `${thumbnail.width}`,
        },
        {
          name: 'twitter:image:height',
          content: `${thumbnail.height}`,
        },
      ]}
    />
  );
};

interface ArtworksProps {
  item: any;
}

const ArtWorks = (props: ArtworksProps) => {
  const { item } = props;

  if (!item) return null;
  const img = item.images['webp'][0].path;
  const imgWidth = item.images['webp'][0].width;
  const imgHeight = item.images['webp'][0].height;

  return (
    <NextSeo
      title={item.title}
      description={item.description}
      openGraph={{
        url: `${DEFAULT_PATH}/artworks#${item.artworksId}}`,
        title: item.title,
        description: item.description,
        images: [
          {
            url: img,
            width: imgWidth,
            height: imgHeight,
          },
        ],
        site_name: 'comicsdays.com',
      }}
      additionalMetaTags={[
        {
          name: 'twitter:url',
          content: `${DEFAULT_PATH}/artworks#${item.artworksId}}`,
        },
        {
          name: 'twitter:title',
          content: item.title,
        },
        {
          name: 'twitter:description',
          content: item.description,
        },
        {
          name: 'twitter:image',
          content: img,
        },
        {
          name: 'twitter:image:width',
          content: imgWidth,
        },
        {
          name: 'twitter:image:height',
          content: imgHeight,
        },
      ]}
    />
  );
};

interface PagePropTypes {
  title?: string;
  description?: string;
}

/**
 * 공용 SEO 획득
 * @param props.page 페이지에 해당하는 SEO title, discription 처리
 * @returns
 */
const getSeo = (props: PagePropTypes) => {
  const { title, description } = props;

  return <NextSeo title={title} description={description} />;
};

SeoComponent.Default = Default;
/**
 * 메인 페이지 SEO
 */
SeoComponent.Main = () => {
  return getSeo({
    title: mainSEO.title,
    description: mainSEO.description,
  });
};
/**
 * originals SEO
 */
SeoComponent.Originals = () => {
  return getSeo({
    title: originalsSEO.title,
    description: originalsSEO.description,
  });
};
/**
 * new SEO
 */
SeoComponent.New = () => {
  return getSeo({
    title: newSEO.title,
    description: newSEO.description,
  });
};
/**
 * genres SEO
 */
SeoComponent.Genres = () => {
  return getSeo({
    title: genresSEO.title,
    description: genresSEO.description,
  });
};
/**
 * ranking SEO
 */
SeoComponent.Ranking = () => {
  return getSeo({
    title: rankingSEO.title,
    description: rankingSEO.description,
  });
};

/**
 * artworks SEO
 */
SeoComponent.Artworks = () => {
  return getSeo({
    title: artworksSEO.title,
    description: artworksSEO.description,
  });
};

SeoComponent.Footer = Footer;
SeoComponent.Epi = Epi;
SeoComponent.Viewer = Viewer;
SeoComponent.ArtWorks = ArtWorks;

export default SeoComponent;
