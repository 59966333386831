import { BonusCoin, ProductItemViewModel } from '../../../viewModel';
import cn from 'clsx';
import {
  PaymentWindowModeType,
  ProductGroupType,
} from '../../../data/_constants';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import ProductPrice from './ProductPrice';

interface PropsType {
  windowMode: PaymentWindowModeType;
}

const ItemHeader = (props: PropsType) => {
  const { windowMode } = props;
  return (
    <>
      {/* item-Header */}
      <div
        className={cn(
          'grid text-center font-[500] border-solid border-[1px] border-t-[#fff] border-[#3f3f3f] bg-[#2C2D30]',
          'mo:text-[0.85rem] mo:leading-[20px]',
          {
            'grid-rows-[minmax(52px,1fr)] mo:grid-rows-[minmax(40px,1fr)] grid-cols-[30%_1fr_30%] text-[18px] leading-[27px] mo:grid-cols-[75px_minmax(160px,_1fr)_94px] forPaymentItem:grid-cols-[23%_1fr_23%]':
              windowMode === PaymentWindowModeType.LARGE,
            'grid-rows-[minmax(40px,1fr)] grid-cols-[22%_1fr_25%] text-[0.85rem] leading-[20px]':
              windowMode === PaymentWindowModeType.MINI,
          },
        )}
      >
        <div className="flex items-center justify-center">Pricing</div>
        <div className="flex items-center justify-center min-h-[47px] border-x-[1px] border-x-[#3f3f3f] mo:min-h-[40px]">
          Coin + Bonus
        </div>
        <div className="flex items-center justify-center">Benefit</div>
      </div>
      {/* item-Header */}
    </>
  );
};

export default ItemHeader;
