export class BlackListException extends Error {
  constructor() {
    super('blacklist user');

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, BlackListException.prototype);
  }

  getMessage() {
    return this.message;
  }
}
