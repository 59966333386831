import AppManager from '@src/lib/app/AppManager';
import { UserDefault } from '@src/lib';
import { PaymentPrepareRequest } from '@toptoon-developers/global.toptoonplus.common.lib/dist/api';
import { PaymentContext } from '../viewModel';
import { PaymentWindowModeType } from '../data/_constants';

export namespace PaymentWallPurchase {
  // ANCOR: paymentWall 일반결제
  export const paymentWallPurchase = (
    context: PaymentContext,
    payMethod: string,
  ) => {
    const {
      selectProductItem,
      selectCoupon,
      loginInfo,
      token,
      windowMode,
      isMobile,
    } = context;

    if (!loginInfo || !selectProductItem) return;

    const { loginId, provider, userId } = loginInfo;

    const paymentWall: PaymentPrepareRequest.PaymentPrepareType = {
      accessToken: token,
      productId: Number(selectProductItem?.productId),
      pg: 'paymentwall',
      viewType: windowMode ?? PaymentWindowModeType.LARGE,
      latestLocation: `${window.location.pathname}` ?? '/',
      CouponItem: selectCoupon,
    };

    // 상품정보 저장
    UserDefault.payment.setBuckets(paymentWall);

    if (AppManager.getInstance().isApp()) {
      const encodeSelectItem = JSON.stringify(paymentWall)
        .unicode()
        .encodeString('test');

      const rUri = AppManager.getInstance().currentPage.getter();
      AppManager.getInstance().action.paymentPopup(
        `accessToken=${token}&provider=${provider}&userId=${userId}&loginId=${loginId}&selectItem=${encodeSelectItem}&redirectUri=${rUri}&pgString=paymentwall`,
      );
    } else {
      window.open('/popup/paymentWall', 'payViewer', 'width=980, height=714');
    }
  };
}
