import React, { useEffect, useState } from 'react';
import cn from 'clsx';
import { ProductItemViewModel } from '../../../viewModel';
import { PaymentWindowModeType } from '../../../data/_constants';
import _ from 'lodash';

import { ProductBenefit, ProductCoin, ProductPrice } from '.';

interface PropsType {
  isSelect?: boolean;
  productItem: ProductItemViewModel;
  windowMode: PaymentWindowModeType;
  payType?: string; // 결제수단 paypal에서 문구 제거용 : 모바일에서 너무 비좁아서 기획쪽 요청
}

const ItemContent = (props: PropsType) => {
  const { productItem, isSelect, windowMode, payType } = props;
  const {
    coin,
    originalPrice,
    price,
    currencyCode,
    bonusCoin,
    comment,
    description,
  } = productItem;

  return (
    <div
      className={cn(
        'grid min-h-[60px] border-x-[1px] border-b-[1px] border-[#3f3f3f]',
        'mo:font-[500]',
        {
          'bg-[#411C1C]': isSelect,
          'bg-[#1E1F21]': !isSelect,
          'grid-cols-[30%_1fr_30%] mo:grid-cols-[75px_minmax(160px,_1fr)_94px] forPaymentItem:grid-cols-[23%_1fr_23%]':
            windowMode === PaymentWindowModeType.LARGE,
          'grid-cols-[22%_1fr_25%]': windowMode === PaymentWindowModeType.MINI,
        },
        {
          ['border-t-[1px] border-[#3f3f3f] grid-cols-[22%_1fr] border-l-0']:
            payType === 'paypal',
        },
      )}
    >
      {/* priceArea */}
      <ProductPrice.Normal
        price={Number(price)}
        originPrice={Number(originalPrice)}
        currencyCode={currencyCode}
        windowMode={windowMode}
        payType={payType}
      />
      {/* priceArea */}

      {/* coinArea */}
      <ProductCoin
        isSelect={isSelect}
        bonusCoin={bonusCoin}
        coin={coin}
        windowMode={windowMode}
        payType={payType}
      />
      {/* coinArea */}

      {/* benefits */}
      {payType != 'paypal' && (
        <ProductBenefit
          comment={comment}
          description={description}
          windowMode={windowMode}
        />
      )}
      {/* benefits */}
    </div>
  );
};

export default ItemContent;
