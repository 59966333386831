import Cookies from 'js-cookie';
import { TopcoStorage } from '../utils';
import { AppStateType } from './types';
import {
  ANDROID_PACKAGE_NAME,
  APP_ENV_STATE,
  APP_KEY,
  APP_MARKET_STATE,
  APP_STATE,
  IOS_PACKAGE_NAME,
} from './_constants';

export default class AppManager {
  private static instance: AppManager;
  // 앱 배포 라인, 앱 종류, 앱 스키마
  private appState: AppStateType = APP_STATE.WEB;
  // 앱 버전
  public appVersion: number = 0;

  public fcmToken: string = '';

  constructor() {
    this.setAppState();
    this.setAppVersionToNumber();
    this.setFcmToken();
  }

  public static getInstance(): AppManager {
    return this.instance || (this.instance = new this());
  }

  /**
   * 사용중인 패키지 이름
   * @returns
   */
  public getPackageName(): string {
    return Cookies.get(APP_KEY.PACKAGE_NAME) ?? APP_ENV_STATE.WEB;
  }

  public getFcmToken(): string {
    return Cookies.get(APP_KEY.FCM_TOKEN) ?? '';
  }

  /**
   * 앱 버전
   * @returns number가 아니면 0
   */
  private setAppVersionToNumber() {
    this.appVersion = Number(Cookies.get(APP_KEY.VERSION)) || 0;
  }

  public getAppVersion() {
    return this.appVersion;
  }

  /**
   * push token
   * @returns
   */
  private setFcmToken() {
    this.fcmToken = this.getFcmToken();
  }

  /**
   * appMarket, appScheme, appEnv 셋팅
   * @returns
   */
  private setAppState(): void {
    const packageName = this.getPackageName();

    /**
     * 안드로이드 자체앱 환경 셋팅
     */
    if (ANDROID_PACKAGE_NAME.ORIGIN_APP.includes(packageName)) {
      this.appState = APP_STATE.ANDROID_ORIGIN;
      return;
    }
    /**
     * 안드로이드 스토어앱 환경 셋팅
     */
    if (ANDROID_PACKAGE_NAME.STORE_APP.includes(packageName)) {
      this.appState = APP_STATE.ANDROID_STORE;
      return;
    }

    /**
     * IOS 자체앱 환경 셋팅
     */
    if (IOS_PACKAGE_NAME.ORIGIN_APP.includes(packageName)) {
      this.appState = APP_STATE.IOS_ORIGIN;
      return;
    }

    /**
     * IOS 스토어앱 환경 셋팅
     */
    if (IOS_PACKAGE_NAME.STORE_APP.includes(packageName)) {
      this.appState = APP_STATE.IOS_STORE;
      return;
    }

    this.appState = APP_STATE.WEB;
  }

  /**
   * app 정보(localstorage) 셋팅
   * * 현재 : login 페이지에서 사용
   * @param
   */
  public setAppInfo({
    packageName,
    version,
    fcmToken,
  }: {
    packageName: string | null;
    version: string | null;
    fcmToken?: string;
  }) {
    if (TopcoStorage.isUsed()) {
      if (packageName && version) {
        Cookies.set(APP_KEY.PACKAGE_NAME, packageName);
        Cookies.set(APP_KEY.VERSION, version);

        // app 정보 셋팅 되는 시점에 필드값 변경
        this.setAppState();
        this.setAppVersionToNumber();
      }
      if (fcmToken) {
        TopcoStorage.setItem(APP_KEY.FCM_TOKEN, fcmToken);
        // app 정보 셋팅 되는 시점에 필드값 변경
        this.fcmToken = fcmToken;
      }
    }
  }

  /**
   * 앱인지 판단
   * @returns
   */
  public isApp(): boolean {
    return this.appState.appMarket !== APP_MARKET_STATE.NONE;
  }

  /**
   * store app 인지 판단
   * @returns
   */
  public isStoreApp(): boolean {
    return this.appState.appMarket === APP_MARKET_STATE.STORE_APP;
  }

  /**
   * 자체 app 인지 판단
   * @returns
   */
  public isOriginApp(): boolean {
    return this.appState.appMarket === APP_MARKET_STATE.ORIGIN_APP;
  }

  /**
   * iosApp인지 판단
   * @returns
   */
  public isIosApp(): boolean {
    return this.appState.appEnv === APP_ENV_STATE.IOS;
  }

  /**
   * android App인지 판단
   * @returns
   */
  public isAndroidApp(): boolean {
    return this.appState.appEnv === APP_ENV_STATE.ANDROID;
  }

  public isStagingAppDomain(): boolean {
    return window.location.hostname === 'staging-app.toptoonplus.com';
  }

  /**
   * app과 통신하는 scheme 정리
   */
  public action = {
    /**
     * app browser 닫기
     * pg, snsCallback, paypal, eximbay 에서 사용
     */
    close: () => {
      window.location.href = `${this.appState.appScheme}close-webview`;
    },
    /**
     * 웹뷰 화면 리로드
     * 페이팔 모달에서 호출함
     */
    reload: () => {
      window.location.href = `${this.appState.appScheme}reload`;
    },
    /**
     * 유저 정보를 전송
     * TODO: 현재 사용하지 않고 추후 사용할 수도 있음
     * @param query userId, accessToken, isRedirect
     */
    setUser: (query: string) => {
      window.location.href = `${this.appState.appScheme}userInfo/?${query}`;
    },
    /**
     * sns로그인
     * 추후 더 추가 할 수 있음
     * @param provider google, twitter
     */
    snsLogin: (provider: string) => {
      window.location.replace(`${this.appState.appScheme}login/${provider}`);
    },
    /**
     * 애플 로그인은 ios에서만 사용함
     * ios에서 appleLoginKit을 사용하지 못함
     * 웹에서 firebase auth를 이용해서 uuid를 획득하여 앱으로 스키마 전달
     * @param query uuid
     */
    sendAppleLogin: (query: string) => {
      window.location.replace(`${this.appState.appScheme}login/apple?${query}`);
    },
    /**
     * TODO: 사용하지 않고 어떤 함수인지 모름
     * @param query
     */
    sendTwitterToken: (query: string) => {
      window.location.href = `${this.appState.appScheme}?${query}`;
    },
    /**
     * 로그아웃
     */
    logout: () => {
      window.location.href = `${this.appState.appScheme}logout/?isRedirect=true`;
    },
    /**
     * 회원가입/로그인 시 성인 인증을 처리까지 동시에 진행하기 위한 스키마
     * TODO: 현재는 사용하지 않고 추후 사용할 수도 있음
     * @param value   1 === true
     *                0 === false
     */
    set18Over: (value: number) => {
      window.location.href = `${this.appState.appScheme}set18Over?is18Over=${value}`;
    },
    /**
     * staging 서버일 경우 env를 변경하는 함수
     * @param env env-staging, env-production
     */
    envChange: (env: string) => {
      window.location.href = `${this.appState.appScheme}${env}`;
    },
    /**
     * 구글 inapp에서만 사용함
     * 구글에서 야 인앱결제써!! 보안상 문제로 안쓰면 짜른다 해서 들어간 로직 구글 수수료 먹기 위함
     * 나쁜놈들
     * @param query pid=${productId}&
     *              userId=${userId}&
     *              accessToken=${token}&
     *              storeId=${item.storeId}&
     *              type=normal
     */
    inappBilling: (query: string) => {
      window.location.href = `${this.appState.appScheme}inapp?${query}`;
    },
    /**
     * pg사 stripe 추가로 생긴 결제 로직
     * @param query accessToken=${token}&
     *              provider=${provider}&
     *              userId=${userId}&
     *              loginId=${loginId}&
     *              selectItem=${encodeSelectItem}
     */
    paymentStripe: (query: string) => {
      window.location.href = `${this.appState.appScheme}payment/stripe?${query}`;
    },
    /**
     * pg사 payletter(월결제), eximbay(일반결제) 추가로 생긴 결제 로직
     * @param query accessToken=${token}&
     *              provider=${provider}&
     *              userId=${userId}&
     *              loginId=${loginId}&
     *              selectItem=${encodeSelectItem}&
     *              redirectUri=${rUri}&
     *              pgString=payletter`,
     */
    paymentPopup: (query: string) => {
      window.location.href = `${this.appState.appScheme}payment/popup?${query}`;
    },
    /**
     * pg사 eximbay(월결제) 추가로 생긴 결제 로직
     * @param query accessToken=${token}&
     *              provider=${provider}&
     *              userId=${userId}&
     *              loginId=${loginId}&
     *              selectItem=${encodeSelectItem}&
     *              redirectUri=${rUri}`,
     */
    paymentRecurring: (query: string) => {
      window.location.href = `${this.appState.appScheme}payment/popup/recurring?${query}`;
    },
    /**
     * 결제 성공 했을때 전달 로직
     * 현재는 사용하지 않음 --> close 호출
     * @param redirectUrl 쿠키값 에서 획득한 url currentPage.getter
     */
    paymentComplete: (redirectUrl: string) => {
      window.location.href = `${this.appState.appScheme}payment/complete?dest=${redirectUrl}`;
    },
    /**
     * 브라우저 새로운 창 띄우는 로직
     * apple login 로직에서 사용
     * @param url 새로운 창에 사용할 url
     */
    showBrowser: (url: string) => {
      window.location.href = `${this.appState.appScheme}popup/browser?url=${url}`;
    },
  };

  /**
   * 현재 페이지를 쿠키에 현재 url setter, geetter
   * * setter : 쿠키에 10분간 저장
   * * getter : 쿠키값 가져온 후 삭제
   *  @return url : 쿠키에 값이 있다면, 쿠키값을 지운 후
   *  @return / : 쿠키에 값이 없으면
   */
  public currentPage = {
    setter: (): void => {
      const path = `${window.location.pathname}${window.location.search}`;
      const inFifteenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);
      Cookies.set('current_location', path, { expires: inFifteenMinutes });
    },
    getter: (): string => {
      const url = Cookies.get('current_location');
      if (url) {
        Cookies.remove('current_location');
        return url;
      }
      return '/';
    },
  };
}
