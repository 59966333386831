import { LogoImgPathType } from '@src/view/theLayout/Header/logo/viewModel/logoVM';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export interface LogoVM {
  logoPath: string;
}

const localStorage =
  typeof window !== 'undefined' ? window.localStorage : undefined;

const { persistAtom } = recoilPersist({
  key: 'logoPersist',
  storage: localStorage,
});

export const LogoState = atom<LogoVM>({
  key: `logoState`,
  default: {
    logoPath: LogoImgPathType.DEFAULT,
  },
  effects_UNSTABLE: [persistAtom],
});
