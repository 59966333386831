import React from 'react';
import cn from 'clsx';

export interface Props {
  isEmail: boolean;
  loginId: string;
  provider: string;
  type: 'LOGIN' | 'EXIST' | 'TOAST';
}

const AccountInfoDom = (props: Props) => {
  const { isEmail, loginId, provider, type } = props;

  const img_src = provider.replace('.com', '');
  // email
  if (provider === 'global' || provider === 'idAndPassword')
    return (
      <span className={cn({ 'text-[14px] break-all': type === 'LOGIN' })}>
        {loginId}
      </span>
    );

  /*
   * sns일때, email인 것과 아닌것 분기처리
   */
  if (isEmail) {
    return (
      <div
        className={cn({
          'flex justify-center items-center gap-1':
            type === 'EXIST' || type === 'TOAST',
          'mb-[2px]': type === 'LOGIN',
        })}
      >
        <img
          className={cn({
            'w-[22px]': type === 'EXIST' || type === 'TOAST',
            'w-[17px] inline mr-[3px]': type === 'LOGIN',
          })}
          src={`/images/sns/ico_${img_src}.png`}
          alt={`${img_src}_icon`}
        />
        <span className={cn({ 'text-[14px] break-all': type === 'LOGIN' })}>
          {loginId}
        </span>
      </div>
    );
  }
  return (
    <div
      className={cn({
        'flex justify-center items-center gap-1':
          type === 'EXIST' || type === 'TOAST',
        'mb-[2px]': type === 'LOGIN',
      })}
    >
      {type === 'EXIST' && <span>Signed in with</span>}
      <img
        className={cn({
          'w-[22px]': type === 'EXIST' || type === 'TOAST',
          'w-[17px] inline mr-[3px]': type === 'LOGIN',
        })}
        src={`/images/sns/ico_${img_src}.png`}
        alt={`${img_src}_icon`}
      />
    </div>
  );
};

export default AccountInfoDom;
