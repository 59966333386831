import { PaymentBanner } from '@src/view/common/banner';
import { PaymentWindowModeType } from '../data/_constants';
import { PaymentContext } from '../viewModel';
import { Notice } from './notice';
import { ProductContent } from './product';
import cn from 'clsx';
import PaymentContent from './payment/PaymentContent';

const PaymentMain = (context: PaymentContext) => {
  const {
    windowMode,
    productList,
    topcoPayment,
    setSelectItem,
    selectProductItem,
    pgList,
    osType,
    isStoreApp = false,
    token,
    loginInfo,
  } = context;

  const mode = windowMode ?? PaymentWindowModeType.LARGE;

  return (
    <div className="max-w-[920px] w-full my-0 mx-auto relative">
      {/* Banner */}
      <PaymentBanner.PaymentTop
        banner={context.paymentBanner}
        className={
          windowMode === PaymentWindowModeType.MINI
            ? 'paymentMiniTop'
            : 'paymentTop'
        }
      />
      {/* Banner */}

      {/* ProductGroup */}
      <div
        id="producGroup"
        className={cn('mt-[35px] w-full ', {
          'forPayment:py-0 forPayment:px-[5px]':
            windowMode === PaymentWindowModeType.LARGE,
          'px-[10px]': windowMode === PaymentWindowModeType.MINI,
        })}
      >
        <ProductContent
          windowMode={mode}
          productList={productList}
          topcoPayment={topcoPayment}
          selectProductItem={selectProductItem ?? null}
          isStoreApp={isStoreApp}
          setSelectItem={setSelectItem}
          loginInfo={loginInfo}
          token={token}
        />
      </div>
      {/* ProductGroup */}

      {/* paymentGroup */}
      <div
        id="paymentGroup"
        className={cn('mt-[35px] w-full ', {
          'forPayment:py-0 forPayment:px-[5px]':
            windowMode === PaymentWindowModeType.LARGE,
          'px-[10px]': windowMode === PaymentWindowModeType.MINI,
        })}
      >
        <PaymentContent {...context} />
      </div>
      {/* paymentGroup */}

      {/* noticeGroup */}
      <div
        id="paymentGroup"
        className={cn('mt-[35px] w-full ', {
          'forPayment:py-0 forPayment:px-[5px]':
            windowMode === PaymentWindowModeType.LARGE,
          'px-[10px]': windowMode === PaymentWindowModeType.MINI,
        })}
      >
        <Notice windowMode={mode} />
      </div>
      {/* noticeGroup */}
    </div>
  );
};
export default PaymentMain;
