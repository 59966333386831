import { isMobile } from 'react-device-detect';
import { openPaypalModal } from './../../../modal/payment/PaypalModal';
import { UserDefault } from '@src/lib';
import AppManager from '@src/lib/app/AppManager';
import { closeConfirmModal, openConfirmModal } from '@src/view/modal/payment';
import {
  PaymentPrepareRequest,
  TypeCheck,
} from '@toptoon-developers/global.toptoonplus.common.lib';
import ApiPaymentV2 from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/rest/ApiPaymentV2';
import { PaymentWindowModeType } from '../data/_constants';
import { PaymentContext, ProductItemViewModel } from '../viewModel';
import _ from 'cypress/types/lodash';

export interface PaypalConfirmType {
  accessToken: any;
  pg: string;
  orderId: string;
  referenceId: string;
  userId: any;
}
export namespace PaypalPurchase {
  export const paypalSelector = async (
    props: PaymentContext,
    payMethod: string,
  ) => {
    const { token, selectProductItem, windowMode, isMobile = false } = props;
    if (!selectProductItem) return;

    const request: PaymentPrepareRequest.PaymentPrepareType = {
      accessToken: token,
      productId: selectProductItem?.productId || 0,
      pg: 'xsolla',
      latestLocation: `${window.location.href}`,
      viewType: windowMode ?? PaymentWindowModeType.LARGE,
      paymentMethodId: payMethod,
    };

    const res = await ApiPaymentV2.getInstance(
      UserDefault.getUserSession(),
    ).paymentPrepare(request);

    if (TypeCheck.itemsByPath(res, 'data.data.message')) {
      const key = 'paymentFailModal';
      openConfirmModal({
        key,
        msg: `${res.data.data.message}`,
        callback: {
          onClose(e) {
            closeConfirmModal(key);
            if (AppManager.getInstance().isIosApp()) {
              AppManager.getInstance().action.close();
            }
            window.close();
          },
          onConfirm(e) {
            closeConfirmModal(key);
            if (AppManager.getInstance().isIosApp()) {
              AppManager.getInstance().action.close();
            }
            window.close();
          },
        },
      });
      return;
    }

    const { data } = res;
    const resData = TypeCheck.itemsByPath(data, 'data');

    if (resData) {
      console.log('test1');
      openXsollaNewWindow(res.data.data.xsollaToken, isMobile);
      // openmodal
    } else {
      // todo:결제 선택 가능하게 변경
      /* PaymentController.setPendingBlock(dispatch, false); */
      // 실패 모달 만들기
      console.log('test2');
      const key = 'confirmModal';
      openConfirmModal({
        key,
        msg: 'Your Purchase Could Not Be Completed',
        callback: {
          onClose(e) {
            closeConfirmModal(key);
          },
          onConfirm(e) {
            closeConfirmModal(key);
          },
        },
      });
    }
  };

  // xsolla 결제창
  export const openXsollaNewWindow = (token: string, isMobile: boolean) => {
    if (window === undefined) {
      throw new Error('not support window');
    }

    // 요청시 코인 정보 리셋
    UserDefault.coinResetManual();

    const w = 748;
    const h = 775;

    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;

    const url = `${process.env.REACT_APP_ENVIRONMENT}`.includes('production')
      ? `https://secure.xsolla.com/paystation3/?access_token=${token}`
      : `https://sandbox-secure.xsolla.com/paystation3/?access_token=${token}`;

    // const url = `https://staging.toptoon.jp/complete/xsolla`;

    if (!isMobile) {
      const newWindow = window.open(
        url,
        'xsolla',
        `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `,
      );

      newWindow?.focus();
    } else {
      setTimeout(() => {
        window.open(url)?.focus();
      });
    }
  };
}
