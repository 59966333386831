import {
  GnbMenuItemVM,
  GnbMenuState,
  HeaderType,
  initSnbMenu,
  LogoVM,
  sempleDefault,
  SnbMenuState,
  TicketState,
} from '@src/atom';
import { UserSubscribeState } from '@src/atom/user';
import { MembershipCode } from '@src/constants';
import { UserDefault, UserSession } from '@src/lib';
import { isEvent, uuidv4 } from '@src/lib/utils/utils';
import { LogoImgPathType } from '@src/view/theLayout/Header/logo/viewModel/logoVM';
import {
  TypeCheck,
  _days,
} from '@toptoon-developers/global.toptoonplus.common.lib';
import ApiUser from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/rest/ApiUser';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { NextRouter, useRouter } from 'next/router';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

const useUserCoin = () => {
  const router = useRouter();
  const setGnbMenuState = useSetRecoilState(GnbMenuState);
  const setSnbMenuState = useSetRecoilState(SnbMenuState);
  const setTicketState = useSetRecoilState(TicketState);
  const setUserSubscribeState = useSetRecoilState(UserSubscribeState);

  // /********************************************************
  //  * login 통계 4시간마다 호출
  //  ********************************************************/
  // useEffect(() => {
  //   const { token } = UserSession.getUserInfo();
  //   if (!token || !UserSession.isLoginCountCheck()) return;

  //   new ApiLogin(UserDefault.getUserSession())
  //     .updateLoginCount(token, UserDefault.env.getUdid())
  //     .then(res => {})
  //     .catch(err => {});
  // }, []);

  /**
   * menu 상태 update
   */
  const menuUpdate = useCallback(
    (router: NextRouter) => {
      const path = router.pathname;
      const { mature } = UserSession.getUserInfo();

      const gnbMainItems = _.transform(
        sempleDefault.main,
        (result: GnbMenuItemVM[], c) => {
          let isActive = false;

          if (c.mature !== mature && c.mature !== 2) return;

          if (
            path.startsWith('/originals') &&
            c.link.startsWith('/originals')
          ) {
            isActive = true;
          } else {
            if (mature === 0 && path === '/') {
              isActive = c.link === '/ongoing';
            } else if (path === '/new' || path === '/newsfeed') {
              isActive = path === c.link;
            } else {
              isActive = path.includes(c.link);
            }
          }
          result.push({
            ...c,
            isActive: isActive,
          });
        },
        [],
      );

      const gnbSubItems = _.transform(
        sempleDefault.sub,
        (result: GnbMenuItemVM[], c) => {
          let isActive = false;

          if (c.mature !== mature && c.mature !== 2) return;

          isActive = path === c.link;

          result.push({
            ...c,
            isActive: isActive,
          });
        },
        [],
      );

      setGnbMenuState({ main: gnbMainItems, sub: gnbSubItems });
    },
    [router.pathname],
  );

  /**
   * logo updadte 확인
   */
  const refreshLogo = useCallback(() => {
    const logoItem: LogoVM = {
      logoPath: LogoImgPathType.DEFAULT,
    };

    if (isEvent()) {
      logoItem.logoPath = LogoImgPathType.EVENT;
    }

    const { token } = UserSession.getUserInfo();
    const { userSubscribe } = UserSession.getCoinInfo();

    if (token && userSubscribe) {
      const code = userSubscribe.code;

      if (code === MembershipCode.BASIC)
        logoItem.logoPath = LogoImgPathType.MEMBERSHIP_BASIC;
      else if (code === MembershipCode.STANDARD)
        logoItem.logoPath = LogoImgPathType.MEMBERSHIP_STANDARD;
      else if (code === MembershipCode.PREMIUM)
        logoItem.logoPath = LogoImgPathType.MEMBERSHIP_PREMIUM;
    }

    // 같은 주소의 이미지 파일로 변경할 경우 브라우저 캐시로 인해 이미지가 변경되지 않아서 Date 추가
  }, [router.pathname]);

  const refreshSnb = useCallback(() => {
    const userInfo = UserSession.getUserInfo();
    const coinInfo = UserSession.getCoinInfo();
    if (userInfo.token) {
      let membershipCode = MembershipCode.NOT_SET;
      let membershipExpireAt = '';
      const { userSubscribe } = coinInfo;
      if (userSubscribe && userSubscribe.code) {
        membershipCode = userSubscribe.code as MembershipCode;
        membershipExpireAt = _days.remainingDateForDHM(
          userSubscribe.expiredAt || '',
        );
      }

      setSnbMenuState(prev => {
        return {
          ...prev,
          headerType: HeaderType.login,
          userInfo: {
            provider: userInfo.provider,
            userId: userInfo.loginId,
            userCoin: coinInfo.coinInfo.coin,
            userBonusCoin: {
              coin: coinInfo.coinInfo.bonusCoin,
              refreshAt: _days.remainingDateForDHM(coinInfo.refreshAt || ''),
            },
            userMembership: {
              code: membershipCode,
              refreshAt: membershipExpireAt,
            },
          },
        };
      });
    } else {
      setSnbMenuState(initSnbMenu);
    }
  }, [router.pathname]);

  const getUserData = useCallback(
    (token: string) => {
      ApiUser.getInstance(UserDefault.getUserSession())
        .getCoinInfo(token)
        .then((res: any) => {
          const data = res.data.data;

          // 5min get current coin 정보 갱신(코인 정보) => global process와 공유됨 (21.12.09)
          const inTimeoutMin = new Date(new Date().getTime() + 5 * 60 * 1000);
          Cookies.set('coinRefresh', uuidv4(), {
            expires: inTimeoutMin,
          });

          if (_.has(data, 'effectCategory')) {
            data.effectCategory.forEach((element: any) => {
              UserDefault.categoryEffect.setter(
                element.category === 'complete' ? 'binge' : element.category,
                element.publishedAt,
              );
            });
          }

          const coinInfo = {
            coin: 0,
            bonusCoin: 0,
          };
          if (!TypeCheck.itemsByPath(res, 'coins')) {
            const conis = data.coins || [];

            coinInfo.coin = _.sum(
              _.map(
                _.filter(conis, v => v.type < 6 || v.type === 7),
                'coin',
              ),
            );
            coinInfo.bonusCoin = _.sum(
              _.map(
                _.filter(conis, v => v.type >= 6 && v.type !== 7),
                'coin',
              ),
            );
          }

          const subscribe =
            TypeCheck.itemsByPath(data, 'userMembership.code') === null // paymentCount를 이용한 Membership 조건 확인용
              ? {
                  code: MembershipCode.NOT_SET,
                  expiredAt: '0',
                }
              : TypeCheck.itemsByPath(data, 'userMembership');

          UserSession.setCoinInfo({
            coinInfo: coinInfo,
            refreshAt: TypeCheck.itemsByPath(data, 'refreshAt'),
            goods: TypeCheck.itemsByPath(data, 'goods'),
            paymentCount: TypeCheck.itemsByPath(data, 'paymentCount'),
            userSubscribe: subscribe,
            waitFreeComic: TypeCheck.itemsByPath(data, 'waitFreeComic'),
            loginCount: TypeCheck.itemsByPath(data, 'loginCount'),
          });

          setUserSubscribeState(subscribe);
          setTicketState({
            freeTicketIds: UserSession.getFreeTicketIds(data.goods),
            freeTicketCount: UserSession.getFreeTicketCount(data.goods),
          });

          refreshLogo();
          refreshSnb();
        })
        .catch((err: any) => {
          console.error(err);
        });
    },
    [router.pathname],
  );
  return {
    getUserData,
    refreshLogo,
    refreshSnb,
    menuUpdate,
  };
};

export default useUserCoin;
