interface propTypes {
  children: any;
  onDismiss: any;
}

const BottomCenterToastType1 = ({ children, onDismiss }: propTypes) => {
  return (
    <div className="toast_ep mo:w-[80vw] mo:max-w-[410px]">
      <div className="text-[1rem] text-[#fff] font-normal inline-block leading-normal traking-[0.2]">
        {children}
      </div>
    </div>
  );
};

export default BottomCenterToastType1;
