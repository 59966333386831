import cn from 'clsx';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import React from 'react';
import { UserSession } from '@src/lib';
import AppManager from '@src/lib/app/AppManager';
import _ from 'lodash';
import { useAppData, useTheLayouotCtrl } from '@src/hooks';
import UserCoinResponse from '@src/responsive/UserCoinResponse';
import { getAuth, getRedirectResult } from '@firebase/auth';
import useLogin from '@src/hooks/useLogin';
import Cookies from 'js-cookie';
import { TopcoLoadingController } from '../error/TopcoLoading';
import TestToolBox from '../components/testTools/TestToolBox';
import UserDefault from '../../lib/user/UserDefault';
import dynamic from 'next/dynamic';
import AuthController from '@src/auth/AuthController';
import TopcoPageHistory from '@src/lib/utils/topcoPageHistory';
import { openEpGiftBoxModal } from '../modal/epGiftBoxModal/EpGiftBoxModal';

// import { Header } from './Header';
// import { Footer } from './Footer';
// import {
//   ApiResponse,
//   GlobalEventResponse,
//   PersistResponse,
// } from '@src/responsive';

const ApiResponse = dynamic<any>(() => import('@src/responsive/ApiResponse'), {
  ssr: false,
});

const GlobalEventResponse = dynamic<any>(
  () => import('@src/responsive/GlobalEventResponse'),
  { ssr: false },
);

const PersistResponse = dynamic<any>(
  () => import('@src/responsive/PersistResponse'),
  { ssr: false },
);

const Header = dynamic<any>(
  () => import('@src/view/theLayout/Header').then((mod: any) => mod.Header),
  { ssr: false },
);

const Footer = dynamic<any>(
  () => import('@src/view/theLayout/Footer').then((mod: any) => mod.Footer),
  { ssr: false },
);

interface PropTypes {
  children: React.ReactNode;
}
const TheLayout = (props: PropTypes) => {
  const { children } = props;
  const router = useRouter();

  const { token } = UserSession.getUserInfo();
  const { updateFcmToken } = useAppData();
  const { snsLoginApi } = useLogin();
  const { layoutHidden, isLoading } = useTheLayouotCtrl();

  const [appBlock, setAppBlock] = useState(false);
  const [isTestMod, setIsTestMod] = useState(false);

  const { headerHidden, footerHidden, bodyWrapperHidden } = layoutHidden;

  // (230127) 파트너코드 리로드되지 않도록 수정
  // 파트너 코드 타고 들어올시 pid || p_id 제거후 나머지 queryString 붙여서 리로드
  // useEffect(() => {
  //   if (rUrl) {
  //     router.replace(rUrl);
  //   }
  // }, [rUrl]);

  async function handleCallback(response: any) {
    AuthController.googleLogin(response.credential, () => {
      window.location.href =
        TopcoPageHistory.getInstance().getLastHistory().path;
    });
  }

  useEffect(() => {
    if (Cookies?.get('topco_test') && UserDefault.isDevEnv()) {
      setIsTestMod(true);
    }

    // google login button initialize
    // => renderButton 전에 initialize 해줘야되는데 구글 버튼이 여러 개여서 sync 맞추기 위해 TheLayout으로 올림
    if (typeof window !== undefined) {
      const { google }: any = window;
      if (!google) return;

      google.accounts.id.initialize({
        client_id: `${process.env.REACT_APP_GOOGLE_ID}`,
        callback: handleCallback,
      });
    }
  }, []);

  useEffect(() => {
    //firebase 관련 로직
    const auth = getAuth();

    getRedirectResult(auth)
      .then(result => {
        if (result) {
          const { user } = result;
          const { uid } = user;
          snsLoginApi('apple', { uid });
        }
      })
      .catch(err => {
        console.error('error', err);
      });
  }, []);

  useEffect(() => {
    if (
      !(
        router.asPath.startsWith('/comic/') ||
        router.asPath.startsWith('/content/')
      ) &&
      Cookies.get('saveOrder')
    ) {
      Cookies.remove('saveOrder');
    }

    if (router.pathname === '/') {
      const { packageName, redirectUrl, fcmToken, version } = router.query;

      if (packageName !== undefined && version !== undefined) {
        const oldFcmToken = AppManager.getInstance().getFcmToken();
        AppManager.getInstance().setAppInfo({
          packageName: `${packageName}`,
          fcmToken: fcmToken ? `${fcmToken}` : '',
          version: `${version}`,
        });

        // 추후노출
        // const v = Number(version);
        // if (_.isNaN(v) || v < 54) {
        //   setAppBlock(true);
        //   // openAppUpdateModadl();
        // }

        /**
         * login 상태인제 fcmToken이 바뀐경우
         */
        if (fcmToken !== '' && oldFcmToken !== `${fcmToken}` && token !== '') {
          const fcmToken = AppManager.getInstance().getFcmToken();
          const appVersion = AppManager.getInstance().getAppVersion();
          updateFcmToken(token, fcmToken, `${appVersion}`);
        }
      }
    }

    if (!appBlock && !isLoading) {
      TopcoLoadingController.hide();
    }
  }, [router, isLoading]);

  if (appBlock) {
    return null;
  }

  return (
    <>
      <Header hidden={headerHidden} />
      <div
        id="bodyWrapperCon"
        className={cn(
          `max-w-[1200px] w-full mt-[30px] mx-auto px-[10px] h-auto min-h-[70vh] mo:min-h-[65vh]`,
          'tablet:mt-[20px]',
          // 회차리스트 페이지 헤더 비노출 조건에 맞춰 CSS 수정 함
          {
            ['tablet:px-0']: headerHidden,
            ['middle:px-[15px] mo:px-[10px]']:
              bodyWrapperHidden && headerHidden,
            ['max-w-full !mt-0 px-0 middle:px-0 mo:!px-0 min-h-0 mo:min-h-0']:
              bodyWrapperHidden,
          },
        )}
      >
        {children}
      </div>
      <Footer hidden={footerHidden} />
      <ApiResponse />
      <GlobalEventResponse />
      <PersistResponse />
      <UserCoinResponse />
      {isTestMod && <TestToolBox />}
    </>
  );
};
export default TheLayout;
