import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export interface TicketVM {
  freeTicketIds: number[];
  freeTicketCount: number;
}

const localStorage =
  typeof window !== 'undefined' ? window.localStorage : undefined;

const { persistAtom } = recoilPersist({
  key: 'ticketPersist',
  storage: localStorage,
});

export const TicketState = atom<TicketVM>({
  key: `ticketState`,
  default: { freeTicketIds: [], freeTicketCount: 0 },
  effects_UNSTABLE: [persistAtom],
});
