import cn from 'clsx';
import { PaymentWindowModeType } from '../../data/_constants';
import { PaymentMethodViewModel } from '../../viewModel';
import PayMethodItem from './PayMethodItem';

interface PropTypes {
  pgItem: PaymentMethodViewModel;
  windowMode: PaymentWindowModeType;
  onClick: (e: any) => void;
}

const PgItem = (props: PropTypes) => {
  const { pgItem, windowMode, onClick } = props;
  const { pg, method } = pgItem;
  const isPaymentWall = pg === 'paymentwall' && !method;

  return (
    <div
      id={method || pg}
      className={cn(
        'relative border-solid border-[1px] border-[#575960] bg-[#2C2d30]',
      )}
    >
      {/* Local Payments */}
      {/* {pg === 'paymentwall' && !method && (
        <div className="absolute leading-[1.05rem] -top-[10px] left-[50%] min-w-[96px] px-[6px] text-center text-[#1e1f21] text-[10px] rounded-[12px] bg-[#FFC452] -translate-x-[50%]">
          Local Payments
        </div>
      )} */}
      {/* Local Payments */}

      {/* Fast & Easy */}
      {pg === 'eximbay' && !method && (
        <div className="absolute leading-[1.05rem] -top-[10px] left-[50%] min-w-[75px] px-[6px] text-center text-[#1e1f21] text-[10px] rounded-[12px] bg-[#528dff] -translate-x-[50%]">
          Fast & Easy
        </div>
      )}
      {/* Fast & Easy */}
      {/* 3D Secure */}
      {pg === 'payletter' && (
        <div className="absolute leading-[1.05rem] -top-[10px] left-[50%] min-w-[75px] px-[6px] text-center text-[#1e1f21] text-[10px] rounded-[12px] bg-[#ff4f4f] -translate-x-[50%]">
          3D Secure
        </div>
      )}
      {/* 3D Secure */}

      {/* 결제수단별 컴포넌트 분리 */}
      {/* 결제수단별 컴포넌트 분리 */}

      {/* paymentWall */}
      {isPaymentWall && (
        <PayMethodItem.PaymentWall
          pgItem={pgItem}
          windowMode={windowMode}
          onClick={onClick}
        />
      )}

      {/* eximbay */}
      {pg === 'eximbay' && !method && (
        <PayMethodItem.Eximbay
          pgItem={pgItem}
          windowMode={windowMode}
          onClick={onClick}
        />
      )}

      {/* payletter */}
      {pg === 'payletter' && (
        <PayMethodItem.Payletter
          pgItem={pgItem}
          windowMode={windowMode}
          onClick={onClick}
        />
      )}

      {/* unionpay */}
      {pg === 'eximbay' && method === 'unionpay' && (
        <PayMethodItem.UnionPay
          pgItem={pgItem}
          windowMode={windowMode}
          onClick={onClick}
        />
      )}

      {/* eximbay, payletter, unionpay, paymentwall 외 모든 결제수단  */}
      {pg !== 'payletter' && !isPaymentWall && pg !== 'eximbay' && (
        <PayMethodItem.NormalPay
          pgItem={pgItem}
          windowMode={windowMode}
          onClick={onClick}
        />
      )}
    </div>
  );
};
export default PgItem;
