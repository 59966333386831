import { Field, Form, Formik } from 'formik';
import cn from 'clsx';
import {
  DomainForm,
  LoginAndJoinBtn,
  PasswordForm,
  TitleComponent,
} from '../common';
import { CloseBtn } from '@src/view/common/closeBtn';
import { Banner } from '@src/view/common/banner';
import { UserDefault } from '@src/lib';
import { ErrorMsgV2 } from '@src/lib/utils/userUtils';
import { useEffect, useState } from 'react';
import { openLoginModal } from '../login/LoginComponent';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import useLogin from '@src/hooks/useLogin';
import { useRouter } from 'next/router';
import TopcoPageHistory from '@src/lib/utils/topcoPageHistory';

const JOIN_MODAL_KEY = 'joinModal';

export const closeJoinModal = () => {
  ModalInstance.getInstance().delete(JOIN_MODAL_KEY);
};

export const openJoinModal = () => {
  ModalInstance.getInstance().push({
    key: JOIN_MODAL_KEY,
    shouldCloseOnOverlayClick: false,
    component: <JoinComponent />,
  });
};

let doing = false;

/**
 * @TODO: 회원가입 후 동작 정리
 * - 회원가입시 현재페이지 유지
 * - 회원가입완료 레이어 노출 하도록 함
 * @returns
 */
const JoinComponent = () => {
  const [message, setMessage] = useState('');
  const [isSubmitBtn, setIsSubmitBtn] = useState(true);
  // 성인 체크 박스
  const [adultCheck, setAdultCheck] = useState<number>(0);
  // 성인 체크 박스 활성화/비활성화
  const [adultCheckEnable, setAdultCheckEnable] = useState<boolean>(true);
  // signUp 버튼 활성화/비활성화
  const [signUp, setSignUp] = useState(false);

  const router = useRouter();

  const { emailJoin, loginAfterAction, blockJoinAction } = useLogin();

  // Sign up with email 버튼 클릭시
  const signUpBtnClick = () => {
    blockJoinAction({
      onPossibleJoin: () => {
        setSignUp(true);
      },
      onClose: () => {
        closeJoinModal();
      },
    });
  };

  const initState = () => {
    setMessage('');
  };

  const initialize = () => {
    // 성인 체크 박스 기본상태
    setAdultCheck(UserDefault.get18Over());

    // 파터너 유입시 비활성화(광고 검수)
    const result = UserDefault.getNormalMode();
    if (UserDefault.IS_FORCE_17) {
      setAdultCheck(0);
      UserDefault.set18Over(0);
      setAdultCheckEnable(false);
    } else if (UserDefault.env.getLocationString().toLowerCase() === 'kr') {
      setAdultCheckEnable(false);
    } else {
      setAdultCheckEnable(!result);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const noticeMessage = (action: string) => {
    switch (action) {
      case 'exist':
        return setMessage('auth fail');
      // TODO: 수정 봇 처리
      case 'robot':
        return setMessage(
          'An error has occurred. Please contact customer support.',
        );
      default:
        return setMessage('unknown');
    }
  };

  const onSubmit = (values: any, actions: any) => {
    if (!signUp) return;

    setIsSubmitBtn(false);
    if (doing) return;
    doing = true;
    // 회원가입 플래그 체크
    UserDefault.clickSignUpEvent();
    actions.setSubmitting(true);

    const loginId = `${values.email}@${values.domain}`;

    // 회원가입 처리
    emailJoin(loginId, values.password, {
      onLogin() {
        doing = false;
        initState();
        setIsSubmitBtn(true);
        closeJoinModal();
        loginAfterAction();
      },
      onError(action) {
        doing = false;
        noticeMessage(action);
        setIsSubmitBtn(true);
      },
    });
  };

  return (
    <div className="animation_fadein bg-white z-200 max-w-[400px] w-[88vw] max-h-[90vh] overflow-hidden overflow-y-auto scrollbar-hide">
      <div className="relative popupInner">
        <CloseBtn
          imgPath="/images/alert/close/ico_close_white.png"
          className="absolute top-[2px] right-[2px] w-[35px] p-[5px]"
          onClick={(e: any) => {
            TopcoPageHistory.getInstance().clearFix();
            const { action } = router.query;
            if (action && action === 'login') {
              router.replace('/', undefined, { shallow: true });
            } else {
              router.replace(router.asPath, undefined, { shallow: true });
            }
            closeJoinModal();
            setSignUp(false);
          }}
        />

        {/* topBanner */}
        <Banner.LoginAndJoin type="join" />
        {/* topBanner */}

        {/* contentArea */}
        <div className="contentCon">
          <Formik
            initialValues={{
              email: '',
              domain: '',
              password: '',
              policyCheckbox: true,
              adCheck: false,
            }}
            validationSchema={ErrorMsgV2()}
            onSubmit={onSubmit}
          >
            {({ values, errors, touched, handleChange }) => {
              return (
                <Form className="p-[0_25px_30px] mo:p-[0.2rem_25px_1.7rem] underMo:p-[1rem] max-w-[350px] mx-auto text-[.8rem] text-[#666] leading-[1.1rem]">
                  {/* snsArea */}
                  {/* <div className={`text-center relative mb-[10px]`}>
                    <TitleComponent
                      title="One-click social media Sign In"
                      type="snsTit"
                    />
                  </div>
                  <SnsButtons type={SnsBtnTypeCode.Join} /> */}

                  {/* Sign up with email 클릭전 */}
                  {!signUp && (
                    <div>
                      {/* <div className="flex">
                        <div
                          className={cn(
                            "before:content-['']  before:grow-[1] before:mx-[16px] before:h-[1px] before:text-[0px] before:leading-0 before:bg-[#EEEEEE]",
                            'flex basis-full items-center text-[12px] my-[8px]',
                            "after:content-[''] after:grow-[1] after:mx-[16px] after:h-[1px] after:text-[0px] after:leading-0 after:bg-[#EEEEEE]",
                          )}
                        >
                          OR
                        </div>
                      </div> */}

                      <div className="btnLogin mt-[5px]">
                        <button
                          id="signUpButton"
                          className={cn(
                            `disabled:bg-[#eee] disabled:shadow-none shadow-logInAndJoin max-w-[165px] h-[35px] text-white text-[12px] font-medium rounded-[50px] text-center w-full mx-auto flex justify-center items-center bg-primaryRed`,
                          )}
                          onClick={signUpBtnClick}
                        >
                          Sign up with email
                        </button>
                      </div>
                    </div>
                  )}

                  {/* Sign up with email 클릭후 */}
                  {signUp && (
                    <div>
                      <h1 className="socialLine bg-[#f2f2f5] w-full h-[1px] my-[15px]" />
                      {/* snsArea */}
                      <TitleComponent title="Sign up without vertification" />
                      {/* DomainForm */}
                      <DomainForm
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        type={'join'}
                      />
                      {/* DomainForm */}
                      {/* PasswordForm */}
                      <PasswordForm
                        values={values}
                        message={message}
                        handleChange={handleChange}
                      />
                      {/* PasswordForm */}
                      {/* joinBtn */}
                      <LoginAndJoinBtn
                        disabled={
                          !(
                            errors.email === undefined &&
                            errors.domain === undefined &&
                            errors.password === undefined &&
                            values.policyCheckbox &&
                            values.email &&
                            values.domain &&
                            values.password &&
                            isSubmitBtn
                          )
                        }
                        title="Sign Up"
                      />
                      {/* joinBtn */}
                    </div>
                  )}

                  {/* checkArea */}
                  <div className={cn('adultCheck mt-[10px]')}>
                    <Field
                      className="w-[15px] h-[15px] text-[#ff414b] bg-[#e9e9e9] border-gray-300 focus:ring-[#ff414b] focus:ring-0 "
                      type="checkbox"
                      id="policyCheckbox"
                      name="policyCheckbox"
                      // checked
                      // checked={adultCheck === 1}
                      // onChange={e => {
                      //   const value = e.target.checked ? 1 : 0;
                      //   setAdultCheck(value);
                      //   UserDefault.set18Over(value);
                      //   if (UserDefault.isApp()) {
                      //     window.location.replace(
                      //       `toptoonplus://set18Over?is18Over=${value}`,
                      //     );
                      //   }
                      // }}
                    />
                    <label
                      className="cursor-pointer text-[12px] -tracking-[0.2px] leading-[19px] ml-2 text-[#aaa]"
                      htmlFor="policyCheckbox"
                    >
                      By clicking this button, you agree to our Terms of
                      Services & Privacy Policy.
                      <b className="font-medium text-black">(Required)</b>
                    </label>
                  </div>
                  {/* checkArea */}

                  {/* subBtn */}
                  <div
                    className={cn(
                      'sbtLogin overflow-hidden mt-[8px] text-center',
                    )}
                  >
                    <button
                      className="text-black font-semibold underline text-[16px] py-[2px]"
                      type="button"
                      onClick={() => {
                        openLoginModal();
                        closeJoinModal();
                      }}
                    >
                      Sign In
                    </button>
                  </div>
                  {/* subBtn */}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default JoinComponent;
