import React from 'react';
import cn from 'clsx';
import { TopcoLink } from '../link';
import { isAndroid, isIOS } from 'react-device-detect';
import { UserDefault } from '@src/lib';
import AppManager from '@src/lib/app/AppManager';

const Banner = (children: React.ReactNode) => {
  return { children };
};

interface LoginAndJoinPropTypes {
  type: string;
}

const LoginAndJoin = (props: LoginAndJoinPropTypes) => {
  const { type } = props;
  const imgPath =
    type === 'login'
      ? '/images/banner/signin/bnr_top_login_v9.png'
      : '/images/banner/signin/bnr_top_login_v9.png';
  return (
    <div className="loginAndJoinBanner aspect-[720/193] w-full">
      <img src={imgPath} alt="" />
    </div>
  );
};

interface CommonBannerPropTypes {
  pcImg: string;
  moImg: string;
  link?: string;
}
const Main = (props: CommonBannerPropTypes) => {
  const { pcImg, moImg, link = '' } = props;
  return (
    <section
      className={cn(
        'banner m-[0_auto_35px] max-w-[1240px] px-[30px] relative w-full',
        'middle:px-[15px] mo:m-[0_auto_22px] mo:px-[12px]',
      )}
    >
      <TopcoLink.Parent href={link}>
        <picture>
          <source media="(max-width: 767px)" srcSet={moImg} />
          <img src={pcImg} alt="" />
        </picture>
      </TopcoLink.Parent>
    </section>
  );
};

const DownLoadApp = () => {
  const pcImg = '/images/downLoadApp/android_ios_PC_banner.png';
  const moImg = isAndroid
    ? '/images/downLoadApp/android_banner.png'
    : isIOS
    ? '/images/downLoadApp/ios_banner.png'
    : '/images/downLoadApp/android_ios_banner.png';

  if (AppManager.getInstance().isApp()) return null;
  return (
    <section
      className={cn(
        'banner block mx-auto my-0 max-w-[1240px] px-[30px] relative w-full',
        'table:hidden',
        'middle:px-[15px] mo:m-[0_auto_22px] mo:px-[12px] mo:block',
      )}
    >
      <TopcoLink.Parent href="/download/app">
        <picture>
          <source media="(max-width: 767px)" srcSet={moImg} />
          <img src={pcImg} alt="" />
        </picture>
      </TopcoLink.Parent>
    </section>
  );
};

Banner.LoginAndJoin = LoginAndJoin;
Banner.Main = Main;
Banner.DownLoadApp = DownLoadApp;
export default Banner;
