import { PaymentWindowModeType } from '../../data/_constants';
import cn from 'clsx';
import { PaymentContext } from '../../viewModel';
import { PgItem } from '.';

const PaymentContent = (props: PaymentContext) => {
  const { windowMode, pgList, selectProductItem, purchaseAction } = props;

  return (
    <>
      {/* paymentNotice */}
      <div className={cn('mb-[15px]')}>
        <h3
          className={cn(
            'mb-[8px] text-[#fff] text-[1.1rem] font-[500] tracking-[-0.2px] mo:text-[0.9375rem] mo:mx-auto mo:mb-0',
          )}
        >
          Payment method
        </h3>
        <p
          className={cn(
            'mt-[5px] text-[#999] leading-[17px] mo:text-[11.2px]',
            {
              'text-[12.6px]': windowMode === PaymentWindowModeType.LARGE,
              'text-[11.2px]': windowMode === PaymentWindowModeType.MINI,
            },
          )}
        >
          {`Day Comics' partnered payment methods keep your payment information safe and secure.`}
        </p>
      </div>
      {/* paymentNotice */}
      {/* pgList */}
      <div id="pgItemCon">
        <div className="titArea flex items-center justify-between mb-[14px]">
          <div className="text-[.9rem] font-[500] text-[#fff]">
            How do you want to pay?
          </div>
        </div>

        <div
          id="pgList"
          className={cn('grid h-full', {
            'gap-[10px] mo:gap-[5px] grid-cols-3 mo:grid-cols-2':
              windowMode === PaymentWindowModeType.LARGE,
            'flex-wrap gap-[5px] mt-[10px] grid-cols-2':
              windowMode === PaymentWindowModeType.MINI,
          })}
        >
          {pgList.map(item => {
            return (
              <PgItem
                key={`pg-item-${item.pg}-${item.method || ''}`}
                pgItem={item}
                windowMode={windowMode ?? PaymentWindowModeType.LARGE}
                onClick={e => {
                  e.preventDefault();
                  purchaseAction && purchaseAction(item, props);
                }}
              />
            );
          })}
        </div>
      </div>
      {/* pgList */}
    </>
  );
};

export default PaymentContent;
