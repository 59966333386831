import Link from 'next/link';

interface BasicLinkProps {
  href: string;
  title?: string;
  classNames?: any;
  onClick?: (e: any) => void;
}

interface FCLinkProps extends BasicLinkProps {
  children: React.ReactNode;
}

const TopcoLink = () => {
  return null;
};

/**
 * title을 감싼 a 태그
 * @param "href, title, className, onClick"
 */
const Normal = (props: BasicLinkProps) => {
  const { href, title, classNames = '', onClick } = props;
  if (onClick) {
    return (
      <Link
        href={href}
        className={classNames}
        onClick={(e: any) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {title}
      </Link>
    );
  }
  return (
    <Link className={classNames} href={href}>
      {title}
    </Link>
  );
};

/**
 * children을 감싼 a 태그
 * @param "href, title, className, onClick"
 */
const Parent = (props: FCLinkProps) => {
  const { children, href, classNames = '', onClick } = props;
  if (onClick) {
    return (
      <Link
        href={href}
        className={classNames}
        onClick={(e: any) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </Link>
    );
  }
  return (
    <Link className={classNames} href={href}>
      {children}
    </Link>
  );
};

TopcoLink.Normal = Normal;
TopcoLink.Parent = Parent;
export default TopcoLink;
