import { PaymentContext, ProductItemViewModel } from '../viewModel';
import { PaymentPrepareRequest } from '@toptoon-developers/global.toptoonplus.common.lib/dist/api';
import { PaymentWindowModeType } from '../data/_constants';
import { UserDefault } from '@src/lib';
import AppManager from '@src/lib/app/AppManager';

export namespace PayLetterPurchase {
  // ANCHOR: 페이레터 월결제
  export const payLetterPurchase = (context: PaymentContext) => {
    const {
      selectProductItem,
      selectCoupon,
      loginInfo,
      token,
      windowMode,
      isMobile,
    } = context;

    if (!loginInfo || !selectProductItem) return;

    const { loginId, provider, userId } = loginInfo;

    const payLetter: PaymentPrepareRequest.PaymentPrepareType = {
      accessToken: token,
      productId: Number(selectProductItem?.productId),
      pg: 'payletter',
      viewType: windowMode ?? PaymentWindowModeType.LARGE,
      latestLocation: `${window.location.pathname}` ?? '/',
      CouponItem: selectCoupon,
    };

    UserDefault.payment.setBuckets(payLetter); // 상품 정보 저장

    if (AppManager.getInstance().isApp()) {
      const encodeSelectItem = JSON.stringify(payLetter)
        .unicode()
        .encodeString('test');

      const rUri = AppManager.getInstance().currentPage.getter();
      AppManager.getInstance().action.paymentPopup(
        `accessToken=${token}&provider=${provider}&userId=${userId}&loginId=${loginId}&selectItem=${encodeSelectItem}&redirectUri=${rUri}&pgString=payletter`,
      );
    } else {
      window.open('/popup/payLetter', 'payViewer', 'width=980, height=714');
    }
  };
  // ANCHOR: 페이레터 월결제
  export const payLetterAutoPurchase = (context: PaymentContext) => {
    const {
      selectProductItem,
      selectCoupon,
      loginInfo,
      token,
      windowMode,
      isMobile,
    } = context;

    if (!loginInfo || !selectProductItem) return;

    const { loginId, provider, userId } = loginInfo;

    const payLetter: PaymentPrepareRequest.PaymentPrepareType = {
      accessToken: token,
      productId: Number(selectProductItem?.productId),
      pg: 'payletter',
      viewType: windowMode ?? PaymentWindowModeType.LARGE,
      latestLocation: `${window.location.pathname}` ?? '/',
      CouponItem: selectCoupon,
    };

    UserDefault.payment.setBuckets(payLetter); // 상품 정보 저장

    if (AppManager.getInstance().isApp()) {
      const encodeSelectItem = JSON.stringify(payLetter)
        .unicode()
        .encodeString('test');

      const rUri = AppManager.getInstance().currentPage.getter();
      AppManager.getInstance().action.paymentPopup(
        `accessToken=${token}&provider=${provider}&userId=${userId}&loginId=${loginId}&selectItem=${encodeSelectItem}&redirectUri=${rUri}&pgString=payletter`,
      );
    } else {
      window.open('/popup/payLetter', 'payViewer', 'width=980, height=714');
    }
  };
}
