import { MembershipCode } from '@src/constants';
import { UserByVisitorId } from '@src/hooks/useLogin';
import { TopcoStorage } from '@src/lib';
import { CoinRow, CoinRowType } from '@src/view/modal/snbMenu/data/_constants';
import {
  TypeCheck,
  _days,
} from '@toptoon-developers/global.toptoonplus.common.lib';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { getSSRCookie } from '../utils/utils';
import { UserInfoType, UserCoinType, TicketInGetCurrentCoin } from './types';

export const initUserStorage: UserInfoType = {
  token: '',
  loginId: '',
  provider: '',
  userId: 0,
  mature: 0,
  auth: 0,
};

export const initCoinStorage: UserCoinType = {
  coinInfo: {
    coin: 0,
    bonusCoin: 0,
  },
  refreshAt: '',
  goods: null,
  paymentCount: 0,
  userSubscribe: null,
  waitFreeComic: null,
  loginCount: 0,
};

export class UserSession {
  private static USER_SESSION_KEY = 'userSession';
  private static COIN_SESSION_KEY = 'coinSession';
  private static USER_LAST_LOGIN_EMAIL = 'lastUserEmail';
  private static USER_LAST_LOGIN_PROVIDER = 'lastProvider';
  private static PERSIST = 'persist:topco';
  // 로그인 카운트 수집용 cookie
  private static LOGIN_COUNT_CHECK: string = 'login_count_check';

  // 타임 이벤트 수집용 localstorage
  private static GWORKS_LIST: string = 'gworks_list';

  // User Fingerprint KEy
  private static USER_FINGER_PRINT_KEY = 'u_fp_v2';
  // 자동로그인 localstorage Key
  private static USER_AUTO_LOGIN_KEY = 'u_a';

  /*********************************************************************************
   * => ANCHOR : User Info
   *********************************************************************************/

  /**
   * Login 정보 저장
   * @param data
   */
  static setUserInfo = (data: UserInfoType) => {
    const encodeResult = JSON.stringify(data).unicode().encodeString('test');
    this.setLastLoginProvider(data.provider);
    Cookies.set(this.USER_SESSION_KEY, encodeResult, { expires: 30 });
  };

  /**
   * Cookie에 저장된 userInfo 가져오기
   * @returns {UserLoginInfoType} token, loginId, provider, userId, mature, auth
   */
  static getUserInfo = (): UserInfoType => {
    const objString: string | undefined = Cookies.get(this.USER_SESSION_KEY);

    if (!objString) return initUserStorage;

    const loginInfo: UserInfoType = JSON.parse(objString.decodeString('test'));

    return {
      token: loginInfo.token,
      loginId: loginInfo.loginId,
      provider: loginInfo.provider,
      userId: Number(loginInfo.userId),
      mature: Number(loginInfo.mature),
      auth: Number(loginInfo.auth),
    };
  };

  static setMature = (mature: number) => {
    const loginInfo = this.getUserInfo();

    if (loginInfo.token === '') return;

    this.setUserInfo({
      ...loginInfo,
      mature: mature,
    });
  };

  /**
   * 자동 인증 처리 함수
   * @param flag
   */
  static setAutoAuth = (flag: boolean) => {
    const loginInfo = this.getUserInfo();

    if (loginInfo.token === '' || !flag) return;

    this.setUserInfo({
      ...loginInfo,
      auth: 1,
      mature: 1,
    });
  };

  /**
   * Cookie에 저장된 userInfo(token) 리셋
   */
  static clearUserSession = () => {
    const encodeResult = JSON.stringify(initUserStorage)
      .unicode()
      .encodeString('test');
    Cookies.set(this.USER_SESSION_KEY, encodeResult);
    this.clearUserCoin();
    this.removeLoginCountCookie();
    this.removeUserEventTimeLines();
  };

  /*********************************************************************************
   * => ANCHOR : Coin Info
   *********************************************************************************/

  static setCoinInfo = (data: UserCoinType) => {
    const encodeResult = JSON.stringify(data).unicode().encodeString('test');
    TopcoStorage.setItem(this.COIN_SESSION_KEY, encodeResult);
  };

  /**
   * Cookie에 저장된 coinInfo 가져오기
   */
  static getCoinInfo = (): UserCoinType => {
    const objString: string | null | undefined = TopcoStorage.getItem(
      this.COIN_SESSION_KEY,
    );

    if (!objString) return initCoinStorage;

    const coinInfo: UserCoinType = JSON.parse(objString.decodeString('test'));
    return coinInfo;
  };

  /**
   * LocalStorage 저장된 Coin 리셋
   */
  static clearUserCoin = () => {
    const encodeResult = JSON.stringify(initCoinStorage)
      .unicode()
      .encodeString('test');
    localStorage.setItem(this.COIN_SESSION_KEY, encodeResult);
  };

  static getCoinRowList = () => {
    const list: CoinRowType[] = [];

    const { coinInfo, waitFreeComic, userSubscribe, refreshAt } =
      this.getCoinInfo();

    const coin = coinInfo.coin;
    const bCoin = coinInfo.bonusCoin;
    const fawCount = waitFreeComic?.usableCount;

    list.push({
      type: CoinRow.Coin,
      value: `${coin}`,
      subText: '',
    });

    if (refreshAt) {
      list.push({
        type: CoinRow.Bcoin,
        value: `${bCoin}`,
        subText: _days.remainingDateForDHM(refreshAt ?? ''),
      });
    } else {
      list.push({
        type: CoinRow.Bcoin,
        value: `${bCoin}`,
        subText: '',
      });
    }
    if (userSubscribe?.code !== MembershipCode.NOT_SET) {
      list.push({
        type: CoinRow.MemberShip,
        value: userSubscribe?.code ?? '',
        subText: _days.remainingDateForDHM(userSubscribe?.expiredAt ?? ''),
      });
    }

    return list;
  };

  static getFreeTicketIds = (
    goods: TicketInGetCurrentCoin.RootObject,
  ): number[] => {
    if (!goods.ticket) return [];

    const tempArr: number[] = [];
    // 수령 대기
    let comicIdsRegister = TypeCheck.itemsByPath(goods, 'ticket.register');
    // 수령
    let comicIdsReserve = TypeCheck.itemsByPath(goods, 'ticket.reserve');

    if (comicIdsRegister !== null) {
      const comicIds = TypeCheck.itemsByPath(comicIdsRegister[0], 'comicId');
      if (typeof comicIds === 'string') {
        tempArr.push(Number(comicIds));
      }

      if (typeof comicIds === 'number') {
        tempArr.push(Number(comicIds));
      }
    } else {
      comicIdsRegister = [];
    }

    if (comicIdsReserve !== null) {
      const comicIds = TypeCheck.itemsByPath(comicIdsReserve[0], 'comicId');
      if (typeof comicIds === 'string') {
        tempArr.push(Number(comicIds));
      }

      if (typeof comicIds === 'number') {
        tempArr.push(Number(comicIds));
      }
    } else {
      comicIdsReserve = [];
    }

    const origin = comicIdsRegister.concat(comicIdsReserve);

    const transformIds = _.transform(
      origin,
      (result: number[], n) => {
        const items = TypeCheck.itemsByPath(n, 'comicId.in');

        if (items) {
          items.map((v: number) => {
            return result.push(v);
          });
        }
      },
      [],
    );
    return transformIds;
  };

  /**
   * 헤더 선물함 아이콘에 표시할 개수 정보 수정
   * 기존 register와 reserve내의 'quantity'값 대신
   * register와 reserve의 length값의 합계로 대체 함.
   * @param goods
   * @returns number
   */
  static getFreeTicketCount = (goods: TicketInGetCurrentCoin.RootObject) => {
    if (!goods.ticket) return 0;

    const register = TypeCheck.itemsByPath(goods, 'ticket.register');
    const reserve = TypeCheck.itemsByPath(goods, 'ticket.reserve');

    const ticketItemCount = (
      ticketItem: TicketInGetCurrentCoin.Reserve[] | null,
    ) => {
      if (!ticketItem) return 0;
      return ticketItem.reduce((a, b) => a + (b['quantity'] || 0), 0);
      // return tickeItem.length;
    };
    return ticketItemCount(register) + ticketItemCount(reserve);
  };

  /*********************************************************************************
   * => ANCHOR : Login Info
   *********************************************************************************/

  /**
   * 마지막 로그인한 UserId 저장
   * @param userId
   * @returns
   */
  static setLastUserEmail = (userId: string | undefined | null) => {
    if (!userId || !TopcoStorage.isUsed()) return;

    Cookies.remove(this.USER_LAST_LOGIN_PROVIDER);
    Cookies.set(this.USER_LAST_LOGIN_EMAIL, userId, {
      expires: 30,
    });
  };

  /**
   * 마지막 로그인한 user email
   * @returns aaa@bbbb.xxcccx || ''
   */
  static getLastUserEmail = (): string => {
    return Cookies.get(this.USER_LAST_LOGIN_EMAIL) || '';
  };

  /**
   * 마지막 로그인한 user Id
   * @returns aaa || ''
   */
  static getLastUserId = (): string => {
    const email = Cookies.get(this.USER_LAST_LOGIN_EMAIL) || '';
    if (email === '') return '';
    return email.split('@')[0];
  };

  /**
   * 마지막 로그인한 user domain
   * @returns bbbb.ccc || ''
   */
  static getLastUserDomain = (): string => {
    const email = Cookies.get(this.USER_LAST_LOGIN_EMAIL) || '';
    if (email === '') return '';
    return email.split('@')[1];
  };

  /**
   * 마지막 로그인 provider
   * @param provider
   * @returns
   */
  static setLastLoginProvider = (provider: string | null) => {
    if (!provider || !TopcoStorage.isUsed()) return;
    Cookies.set(this.USER_LAST_LOGIN_PROVIDER, provider, {
      expires: 30,
    });
  };

  /**
   * 마지막 로긍인한 타입
   * @returns
   */
  static getLastLoginProvider = (): string => {
    return Cookies.get(this.USER_LAST_LOGIN_PROVIDER) || '';
  };

  /**********************************************************************************************
   * SSR Function
   **********************************************************************************************/

  static getUserInfoBySSR = (context: any): UserInfoType | null => {
    const cookies = getSSRCookie(context, this.USER_SESSION_KEY);

    try {
      if (cookies) {
        return JSON.parse(cookies.decodeString('test'));
      } else {
        return null;
      }
    } catch (e) {
      console.warn(e);
      return null;
    }
  };

  static getUserIdBySSR(context: any): string {
    const info = this.getUserInfoBySSR(context);
    if (info == null) return '';

    return info.userId === 0 ? '' : `${info.userId}`;
  }

  static getUserInfoTokenBySSR = (context: any): string => {
    const loginInfo: UserInfoType | null = this.getUserInfoBySSR(context);

    if (loginInfo) {
      return loginInfo.token;
    } else {
      return '';
    }
  };

  /**
   * cta=>fe로 이관시 persist(userInfo, coinInfo) 관련 작업
   * 비로그인 상태일때만 cra의 persist를 새로 set합니다.
   * @returns
   */
  static getPersistInfo() {
    const persist = TopcoStorage.getItem(this.PERSIST);
    if (persist) return JSON.parse(persist);
    return null;
  }

  static getPersistUserToken() {
    if (this.getPersistInfo()) {
      const persist = this.getPersistInfo();
      const persist_user = JSON.parse(persist.user);
      return persist_user.accessToken;
    }
    return null;
  }

  static setPersistInfo() {
    try {
      if (this.getPersistInfo()) {
        const persist = this.getPersistInfo();
        const persist_user = JSON.parse(persist.user);
        const persist_mature = JSON.parse(persist.mature);
        const persist_coinInfo = JSON.parse(persist.coinInfo);

        const userInfo: UserInfoType = {
          token: persist_user.accessToken ?? '',
          loginId: persist_user.loginId ?? '',
          provider: persist_user.provider ?? '',
          userId: persist_user.userId,
          mature: persist_mature.matureState,
          auth: persist_mature.auth,
        };
        const coinInfo: UserCoinType = {
          coinInfo: {
            coin: persist_coinInfo.coinInfo.coin,
            bonusCoin: persist_coinInfo.coinInfo.bonusCoin,
          },
          goods: persist_coinInfo.goods,
          refreshAt: persist_coinInfo.refreshAt,
          paymentCount: persist_coinInfo.paymentCount,
          userSubscribe: persist_coinInfo.userSubscribe,
          waitFreeComic: persist_coinInfo.waitFreeComic,
          loginCount: persist_coinInfo.loginCount,
        };

        this.setCoinInfo(coinInfo);
        this.setUserInfo(userInfo);
      }
    } catch {
      throw new Error('error persist setter');
    }
  }

  static removePersistInfo() {
    if (TopcoStorage.getItem(this.PERSIST))
      TopcoStorage.removeItem(this.PERSIST);
  }

  /**********************************************************************************************
   * 통계용
   **********************************************************************************************/
  /**
   * ANCHOR 로그인 카운트 수집용 쿠키
   * 쿠키가 없을 경우 쿠키를 생성하고 api를 보냄
   * accessToken이 있을때만 동작 (로그인 상태에서만)
   */
  static isSendLoginApi = (token: string): boolean => {
    if (!token || Cookies.get(this.LOGIN_COUNT_CHECK)) {
      return false;
    }
    // 4시간 기준으로 호출 하도록 함.
    const inFifteenMinutes = new Date(
      new Date().getTime() + 4 * 60 * 60 * 1000,
    );

    Cookies.set(this.LOGIN_COUNT_CHECK, 'set', { expires: inFifteenMinutes });
    return true;
  };

  /**
   * 로그인 시점에 해당 쿠키 삭제
   */
  static removeLoginCountCookie = () => {
    Cookies.remove(this.LOGIN_COUNT_CHECK);
  };

  /**
   * 로그인 실패 하거나 로그 아웃시 로컬스토리지의 gworks_list key 삭제
   * gworks_list는 user data sync를 처리하기 위한 로컬스토리지 데이터
   */
  static removeUserEventTimeLines = () => {
    TopcoStorage.removeItem(this.GWORKS_LIST);
  };

  /*********************************************************************************
   * => ANCHOR : User 고유 값
   *********************************************************************************/
  static getFingerPrintId = (): string => {
    const r = TopcoStorage.getItem(this.USER_FINGER_PRINT_KEY) || '';
    if (r.length > 0) {
      return r;
    }
    Cookies.remove(this.USER_FINGER_PRINT_KEY);
    return '';
  };

  static setFingerPrintId = (value: string) => {
    TopcoStorage.setItem(this.USER_FINGER_PRINT_KEY, value);
    this.setFingerPrintIdByCookie(value);
  };

  static removeFingerPrintId = () => {
    TopcoStorage.removeItem(this.USER_FINGER_PRINT_KEY);
    Cookies.remove(this.USER_FINGER_PRINT_KEY);
  };

  static getFingerPrintIdByCookieSSR = (context: any): string => {
    const cookies = getSSRCookie(context, this.USER_FINGER_PRINT_KEY);

    try {
      return cookies;
    } catch (e) {
      console.warn(e);
      return '';
    }
  };

  static setFingerPrintIdByCookie = (value: string) => {
    const inFifteenMinutes = new Date(
      new Date().getTime() + 30 * 60 * 60 * 1000,
    );
    Cookies.set(this.USER_FINGER_PRINT_KEY, value, {
      expires: inFifteenMinutes,
    });
  };

  /*********************************************************************************
   * => ANCHOR : 자동 로그인용
   *********************************************************************************/

  /**
   * 자동로그인을 위한 revokeToken 저장
   * @param revokeToken
   */
  static setRevokeToken = (revokeToken: string) => {
    const encodeData = JSON.stringify(revokeToken)
      .unicode()
      .encodeString('test');
    TopcoStorage.setItem(this.USER_AUTO_LOGIN_KEY, encodeData);
  };

  static getRevokeToken = (): string => {
    try {
      const decodeData = TopcoStorage.getItem(this.USER_AUTO_LOGIN_KEY);
      if (!decodeData) return ''; // decodeString은 빈 스트링일시 에러남.

      return JSON.parse(decodeData.decodeString('test')) || '';
    } catch (e) {
      console.log('error', e);
      return '';
    }
  };
  static removeRevokeToken = () => {
    TopcoStorage.removeItem(this.USER_AUTO_LOGIN_KEY);
  };

  static originalSiteMove = (() => {
    const ORIGINAL_SITE_MOVE = 'originalSiteMove';
    return {
      set: (expires: number) => {
        const minutes = (min: number) =>
          new Date(new Date().getTime() + min * 60 * 1000);
        Cookies.set(ORIGINAL_SITE_MOVE, '1', { expires: minutes(expires) });
      },
      move: () => {
        const originalSiteURL =
          Cookies.get('originalSite') ?? 'https://daycomics.com';
        if (Cookies.get(ORIGINAL_SITE_MOVE)) {
          return;
        } else {
          if (window !== undefined) {
            window.location.href = originalSiteURL;
          }
        }
      },
    };
  })();
}
