import {
  currencyAndPriceToCurrencySymbol,
  currencyAndPriceToCurrencySymbolWithFractionDigits,
} from '@src/lib/utils/utils';
import _ from 'lodash';
import cn from 'clsx';
import React from 'react';
import { PaymentWindowModeType } from '../../../data/_constants';

interface CurrencyAndPriceProps {
  currencyCode: string; // 'ko-KR'
  price: number; // 5, 10, 15
  originPrice?: number;
  discountPrice?: string | null;
  isOneDollar?: boolean;
  isSelect?: boolean;
  windowMode: PaymentWindowModeType;
  payType?: string; // 결제수단 paypal에서 문구 제거용 : 모바일에서 너무 비좁아서 기획쪽 요청
}

const ProductPrice = (child: React.ReactNode) => {
  return <>{child}</>;
};

const Normal = (props: CurrencyAndPriceProps) => {
  const {
    currencyCode,
    price,
    originPrice = 0,
    discountPrice = null,
    isOneDollar,
    isSelect,
    windowMode,
    payType,
  } = props;

  return (
    <div
      className={cn(
        'priceCon text-center flex mo:flex-col items-center justify-center',
        'mo:text-[0.825rem] mo:leading-[18px]',
        {
          'text-[0.9rem] leading-[20px]':
            windowMode === PaymentWindowModeType.LARGE,
          'text-[0.825rem] leading-[18px]':
            windowMode === PaymentWindowModeType.MINI,
        },
        {
          ['border-l-[1px] border-l-[#3f3f3f]']: payType === 'paypal',
        },
      )}
    >
      {/* originPrice */}
      {originPrice !== 0 && (
        <div
          className={cn(
            'originPrice text-[#FF323A] relative font-semibold mr-[8px] mo:mr-0',
            "after:content-[''] after:w-[55px] after:mo:w-[36px] after:absolute after:left-[50%] after:top-[50%] after:translate-x-[-50%] after:translate-y-[-50%] after:inline-block after:border-[#FF323A] after:border-solid after:border-t-[2px] after:rotate-[17deg]",
            {
              'text-[21px] mo:text-[15px]':
                windowMode === PaymentWindowModeType.LARGE,
              'text-[15px] after:w-[36px]':
                windowMode === PaymentWindowModeType.MINI,
            },
          )}
        >
          {currencyAndPriceToCurrencySymbol(
            'ko-KR',
            currencyCode,
            originPrice,
            isOneDollar,
          )}
        </div>
      )}
      {/* originPrice */}
      {/* salePrice */}
      <div
        className={cn('price', {
          // 'text-[26px]': windowMode === PaymentWindowModeType.LARGE,
          // 'text-[24px]': windowMode === PaymentWindowModeType.MINI,
        })}
      >
        {currencyAndPriceToCurrencySymbol(
          'ko-KR',
          currencyCode,
          price,
          isOneDollar,
        )}
      </div>
      {/* salePrice */}
    </div>
  );
};

ProductPrice.Normal = Normal;
export default ProductPrice;
