import { UserDefault } from '@src/lib';
import AppManager from '@src/lib/app/AppManager';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import { CloseBtn } from '@src/view/common/closeBtn';
import { PaymentWindowModeType } from '@src/view/components/payment/data/_constants';
import { WithPaymentHoc } from '@src/view/components/payment/hoc';
import {
  PaymentMain,
  PaymentStoreAppMain,
} from '@src/view/components/payment/view';

const key = 'miniPaymentModal';

export const openMiniPaymentModal = () => {
  ModalInstance.getInstance().push({
    key,
    shouldCloseOnOverlayClick: true,
    component: <MiniPaymentModal />,
  });
};

export const closeMiniPaymentModal = () => {
  ModalInstance.getInstance().delete(key);
};

const MiniPaymentModal = () => {
  const isStoreApp = AppManager.getInstance().isStoreApp();
  return (
    <div className="bg-[#1e1f21] rounded-[5px] text-[1rem] max-w-[450px] max-h-[76vh] w-[94vw] overflow-y-auto overflow-hidden scrollbar-hide">
      <div className="pt-[15px] w-full bg-[#1e1f21] relative z-[1] rounded-[0] break-all">
        <CloseBtn
          imgPath="/images/alert/close/ico_close.png"
          className="absolute right-[4px] top-[4px] text-transparent z-[1] w-[35px] h-[35px] inline-block cursor-pointer"
        />
        <WithPaymentHoc
          isStoreApp={isStoreApp}
          windowMode={PaymentWindowModeType.MINI}
          WrappedComponent={isStoreApp ? PaymentStoreAppMain : PaymentMain}
        />
      </div>
    </div>
  );
};

export default MiniPaymentModal;
