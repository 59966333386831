import { PaymentWindowModeType } from '../../../data/_constants';
import cn from 'clsx';

interface ProductBenefitPropTypes {
  windowMode: PaymentWindowModeType;
  comment: string; // 첫번째 Item
  description: string; // 두번째 Item
}

const ProductBenefit = (props: ProductBenefitPropTypes) => {
  const { windowMode, comment, description } = props;

  return (
    <div
      className={cn('flex items-center justify-center mo:flex-col mo:px-3px')}
    >
      <div
        id="benefitCon"
        className={cn(
          'flex items-center justify-center mo:flex-col gap-x-[4px]',
          '[&>*]:hidden [&>*:nth-child(1)]:block [&>*:nth-child(2)]:block',
          {
            'text-[0.9rem] mo:text-[0.825rem] mo:leading-[16px] [&>*:nth-child(2)]:mo:text-[0.65rem] [&>*:nth-child(2)]:mo:leading-[14px]':
              windowMode === PaymentWindowModeType.LARGE,
            'flex-col text-[0.825rem] leading-[16px] [&>*:nth-child(2)]:text-[0.65rem] [&>*:nth-child(2)]:leading-[14px]':
              windowMode === PaymentWindowModeType.MINI,
          },
        )}
        dangerouslySetInnerHTML={{ __html: description }}
      >
        {/* {description} */}
        {/* <div
          className={cn({
            'text-center text-[17px] mo:text-[11px] mo:leading-[16px]':
              windowMode === PaymentWindowModeType.LARGE,
            'text-[11px] leading-[16px]':
              windowMode === PaymentWindowModeType.MINI,
          })}
        >
          {comment}
        </div>
        <div
          className={cn(
            'text-[#64B5FF] ml-[4px] text-center text-[15px] mo:ml-0 mo:text-[9.5px] mo:leading-[14px]',
            {
              'ml-[4px] text-[15px]':
                windowMode === PaymentWindowModeType.LARGE,
              'ml-0 text-[9.5px] leading-[14px]':
                windowMode === PaymentWindowModeType.MINI,
            },
          )}
        >
          <span>{description}</span>
        </div> */}
      </div>
    </div>
  );
};
export default ProductBenefit;
