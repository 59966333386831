import { create } from 'zustand';
import { ProductItemViewModel } from '../viewModel';
import { PaymentMethodViewModel } from '../viewModel/TopcoPaymentViewModel';

export interface StateType {
  selectItem: ProductItemViewModel | null;
  pgList: PaymentMethodViewModel[];
  recurringPgList: PaymentMethodViewModel[];
}

export interface StoreType extends StateType {
  setSelectItem: (selectItem: ProductItemViewModel | null) => void;
  setPgList: (pgList: PaymentMethodViewModel[]) => void;
  setRecurringPgList: (pgList: PaymentMethodViewModel[]) => void;
}

const useStore = create<StoreType>(set => ({
  selectItem: null,
  pgList: [],
  recurringPgList: [],
  setSelectItem: selectItem => {
    set({ selectItem });
  },
  setPgList: pgList => {
    set({ pgList });
  },
  setRecurringPgList: recurringPgList => {
    set({ recurringPgList });
  },
}));

export default useStore;
