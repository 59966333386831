import React, { useEffect, useRef, useState } from 'react';
// import Style from './loading.module.css';
import Lottie from 'react-lottie';
import Load from '@src/lib/lottieData/Loading.json';

const load = {
  loop: true,
  autoplay: true,
  animationData: Load,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
    eventListeners: [
      {
        eventName: 'complete',
        callback: () => console.log('the animation completed:'),
      },
    ],
  },
};

const Loading = (children: React.ReactNode) => {
  return <span>{children}</span>;
};

// const Circle = () => {
//   return (
//     <div className={Style.circle}>
//       <span>
//         <img src="/images/loading/logo_T.png" alt="" />
//       </span>
//       <span>
//         <img src="/images/loading/logo_O.png" alt="" />
//       </span>
//       <span>
//         <img src="/images/loading/logo_P.png" alt="" />
//       </span>
//       <span>
//         <img src="/images/loading/logo_T2.png" alt="" />
//       </span>
//       <span>
//         <img src="/images/loading/logo_O2.png" alt="" />
//       </span>
//       <span>
//         <img src="/images/loading/logo_O3.png" alt="" />
//       </span>
//       <span>
//         <img src="/images/loading/logo_N.png" alt="" />
//       </span>
//     </div>
//   );
// };

// const Text = () => {
//   return (
//     <div id="topcoLoading" className={Style.text}>
//       <span>D</span>
//       <span>A</span>
//       <span>Y</span>
//       <span className={Style.color}>C</span>
//       <span className={Style.color}>O</span>
//       <span className={Style.color}>M</span>
//       <span className={Style.color}>I</span>
//       <span className={Style.color}>C</span>
//       <span className={Style.color}>S</span>
//     </div>
//   );
// };

const LottieLoading = () => {
  return (
    <div
      id="topcoLoading"
      className="fixed flex items-center justify-center w-[100vw] h-[70vh] bg-[#ffffff0d] z-[9]"
    >
      <span className="block -mt-[2px] ml-[6px] w-[400px] mo:w-[200px]">
        <Lottie isClickToPauseDisabled options={load} isPaused={false} />
      </span>
    </div>
  );
};

// Loading.Text = Text;
Loading.LottieLoading = LottieLoading;
export default Loading;

export namespace TopcoLoadingController {
  export const hide = () => {
    if (!document) return;

    const dom = document.getElementById('topcoLoading') as HTMLElement;
    if (dom) dom.style.opacity = '0';
    dom.style.transition = 'all ease-in-out 0.5s';
    setTimeout(function () {
      dom.style.display = 'none';
    }, 800);
  };

  export const show = () => {
    if (!document) return;
    const dom = document.getElementById('topcoLoading') as HTMLElement;
    if (dom) dom.style.opacity = '0.5';
    dom.style.transition = 'all ease-in-out 0.5s';
    dom.style.display = 'flex';
  };
}
