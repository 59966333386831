import { atom } from 'recoil';

export interface ServerCheckEndDateType {
  endDateAt: string;
}
/**
 * apiResponse에서 서버점검 에러값이 왔을때, endDate를 저장
 * 기존(2023.01.10)에는 백엔드에서 endDate를 바꾸면 cookie에 저장하기 때문에
 * 새로고침 시켜야 serverCheckComponent에 적용이 되었기때문에 백엔드에서 endDate값이 적용이 안되었음
 * 이를 개선하기 위해 recoil 추가
 */
export const ServerCheckState = atom<ServerCheckEndDateType>({
  key: `serverCheckState`,
  default: { endDateAt: '' },
});
