import React from 'react';
import cn from 'clsx';
import { PaymentWindowModeType } from '../../data/_constants';
import { PaymentMethodViewModel } from '../../viewModel';
import { toLower } from 'lodash';

const PayMethodItem = (children: React.ReactNode) => {
  return <span>{children}</span>;
};

interface PgMethodPropTypes {
  pgItem: PaymentMethodViewModel;
  windowMode: PaymentWindowModeType;
  onClick: (e: any) => void;
}

/**
 * 결제 수단 dom, eximbay, payletter, unionpay, paymentwall, gpay, applepay
 * @returns
 */

const Eximbay = (props: PgMethodPropTypes) => {
  const { pgItem, windowMode, onClick } = props;
  const { pg, display } = pgItem;

  return (
    <button
      type="button"
      className={cn('h-full w-full flex flex-col justify-end items-center', {
        'min-h-[100px] mo:min-h-[74px]':
          windowMode === PaymentWindowModeType.LARGE,
        'min-h-[74px] my-0 mx-auto': windowMode === PaymentWindowModeType.MINI,
      })}
      onClick={onClick}
    >
      <span
        className={cn('text-[#ccc]', 'mo:text-[#222]', 'tablet:text-[#bbb]', {
          'min-h-[34px]': windowMode === PaymentWindowModeType.LARGE,
        })}
      >
        <img
          className={cn('w-full mx-auto', {
            'max-w-[170px] mo:max-w-[130px]':
              windowMode === PaymentWindowModeType.LARGE,
            'max-w-[130px]': windowMode === PaymentWindowModeType.MINI,
          })}
          src={`/images/payment/pgIcons/icon_${pg}_dk.png`}
          alt={`icon-${pg}`}
        />
      </span>

      <div
        className={cn('w-full bg-[#1A1B1C] flex items-center justify-center', {
          'min-h-[44px] mo:min-h-[30px]':
            windowMode === PaymentWindowModeType.LARGE,
          'min-h-[26px] max-h-[26px]':
            windowMode === PaymentWindowModeType.MINI,
        })}
      >
        {['visa', 'mastercard', 'amex', 'jcb'].map(item => {
          return (
            <span key={`pg-item-${item}`} className="inline-block">
              <img
                className={cn({
                  'max-w-[45px] mo:max-h-[20px]':
                    windowMode === PaymentWindowModeType.LARGE,
                  'max-h-[20px]': windowMode === PaymentWindowModeType.MINI,
                })}
                src={`/images/payment/${item}.png`}
                alt="visa"
              />
            </span>
          );
        })}
      </div>

      {/* 개발자 모드 뱃지 */}
      {display === 2 && <DevBadge />}
    </button>
  );
};

const Payletter = (props: PgMethodPropTypes) => {
  const { pgItem, windowMode, onClick } = props;
  const { pg, display } = pgItem;

  return (
    <button
      type="button"
      className={cn('h-full w-full flex flex-col justify-end items-center', {
        'min-h-[100px] mo:min-h-[74px]':
          windowMode === PaymentWindowModeType.LARGE,
        'min-h-[74px] my-0 mx-auto': windowMode === PaymentWindowModeType.MINI,
      })}
      onClick={onClick}
    >
      <span
        className={cn('text-[#ccc]', 'mo:text-[#222]', 'tablet:text-[#bbb]', {
          'min-h-[34px]': windowMode === PaymentWindowModeType.LARGE,
        })}
      >
        <img
          className={cn('w-full mx-auto', {
            'max-w-[170px] mo:max-w-[130px]':
              windowMode === PaymentWindowModeType.LARGE,
            'max-w-[130px]': windowMode === PaymentWindowModeType.MINI,
          })}
          src={`/images/payment/pgIcons/icon_${pg}_dk.png`}
          alt={`icon-${pg}`}
        />
      </span>

      <div
        className={cn('w-full bg-[#1A1B1C] flex items-center justify-center', {
          'min-h-[44px] mo:min-h-[30px]':
            windowMode === PaymentWindowModeType.LARGE,
          'min-h-[26px] max-h-[26px]':
            windowMode === PaymentWindowModeType.MINI,
        })}
      >
        {['visa', 'mastercard', 'jcb'].map(item => {
          return (
            <span key={`pg-item-${item}`} className="inline-block">
              <img
                className={cn({
                  'max-w-[45px] mo:max-h-[20px]':
                    windowMode === PaymentWindowModeType.LARGE,
                  'max-h-[20px]': windowMode === PaymentWindowModeType.MINI,
                })}
                src={`/images/payment/${item}.png`}
                alt="visa"
              />
            </span>
          );
        })}
      </div>

      {/* 개발자 모드 뱃지 */}
      {display === 2 && <DevBadge />}
    </button>
  );
};

const UnionPay = (props: PgMethodPropTypes) => {
  const { pgItem, windowMode, onClick } = props;
  const { display, method } = pgItem;

  return (
    <button
      type="button"
      className={cn('block h-full w-full', {
        'min-h-[100px] mo:min-h-[74px] px-[30px] mo:px-[5px]':
          windowMode === PaymentWindowModeType.LARGE,
        'min-h-[74px] my-0 mx-auto': windowMode === PaymentWindowModeType.MINI,
      })}
      onClick={onClick}
    >
      <span
        className={cn('text-[#ccc]', 'mo:text-[#222]', 'tablet:text-[#bbb]', {
          'min-h-[34px]': windowMode === PaymentWindowModeType.LARGE,
        })}
      >
        <img
          className={cn('w-full mx-auto', {
            'max-w-[170px] mo:max-w-[130px]':
              windowMode === PaymentWindowModeType.LARGE,
            'max-w-[130px]': windowMode === PaymentWindowModeType.MINI,
          })}
          src={`/images/payment/pgIcons/icon_${method}_dk.png`}
          alt={`icon-${method}`}
        />
      </span>
      {/* 개발자 모드 뱃지 */}
      {display === 2 && <DevBadge />}
    </button>
  );
};

const PaymentWall = (props: PgMethodPropTypes) => {
  const { pgItem, windowMode, onClick } = props;
  const { pg, display } = pgItem;

  return (
    // <button
    //   type="button"
    //   className={cn('h-full w-full flex flex-col justify-end items-center', {
    //     'min-h-[100px] mo:min-h-[74px]':
    //       windowMode === PaymentWindowModeType.LARGE,
    //     'min-h-[74px] my-0 mx-auto': windowMode === PaymentWindowModeType.MINI,
    //   })}
    //   onClick={onClick}
    // >
    //   <span
    //     className={cn('text-[#ccc]', 'mo:text-[#222]', 'tablet:text-[#bbb]', {
    //       'min-h-[34px]': windowMode === PaymentWindowModeType.LARGE,
    //     })}
    //   >
    //     <img
    //       className={cn('w-full mx-auto', {
    //         'max-w-[170px] mo:max-w-[130px]':
    //           windowMode === PaymentWindowModeType.LARGE,
    //         'max-w-[130px]': windowMode === PaymentWindowModeType.MINI,
    //       })}
    //       src={`/images/payment/pgIcons/icon_${pg}_dk.png`}
    //       alt={`icon-${pg}`}
    //     />
    //   </span>

    //   <div
    //     className={cn('w-full bg-[#1A1B1C] flex items-center justify-center', {
    //       'min-h-[44px] mo:min-h-[30px]':
    //         windowMode === PaymentWindowModeType.LARGE,
    //       'min-h-[26px] max-h-[26px]':
    //         windowMode === PaymentWindowModeType.MINI,
    //     })}
    //   >
    //     {['visa', 'mastercard', 'jcb'].map(item => {
    //       return (
    //         <span key={`pg-item-${item}`} className="inline-block">
    //           <img
    //             className={cn({
    //               'max-w-[45px] mo:max-h-[20px]':
    //                 windowMode === PaymentWindowModeType.LARGE,
    //               'max-h-[20px]': windowMode === PaymentWindowModeType.MINI,
    //             })}
    //             src={`/images/payment/${item}.png`}
    //             alt="visa"
    //           />
    //         </span>
    //       );
    //     })}
    //   </div>

    //   {/* 개발자 모드 뱃지 */}
    //   {display === 2 && <DevBadge />}
    // </button>
    // TODO: paymentwall 임시디자인으로 적용, 추후 상위 코드 복구 예정
    <button
      type="button"
      className={cn('block h-full w-full px-[30px] mo:px-[5px]', {
        'min-h-[100px] mo:min-h-[74px]':
          windowMode === PaymentWindowModeType.LARGE,
        'min-h-[74px] my-0 mx-auto': windowMode === PaymentWindowModeType.MINI,
      })}
      onClick={onClick}
    >
      <span
        className={cn('text-[#ccc]', 'mo:text-[#222]', 'tablet:text-[#bbb]')}
      >
        <img
          className={cn('w-full mx-auto', {
            'max-w-[170px] mo:max-w-[130px]':
              windowMode === PaymentWindowModeType.LARGE,
            'max-w-[130px]': windowMode === PaymentWindowModeType.MINI,
          })}
          src={`/images/payment/pgIcons/icon_${pg}_dk.png`}
          alt={`icon-${pg}`}
        />
      </span>
      {/* 개발자 모드 뱃지 */}
      {display === 2 && <DevBadge />}
    </button>
  );
};

const NormalPay = (props: PgMethodPropTypes) => {
  const { pgItem, windowMode, onClick } = props;
  const { pg, display, method } = pgItem;
  const methodLowerCase = toLower(method);

  return (
    <button
      type="button"
      className={cn('block h-full w-full px-[30px] mo:px-[5px]', {
        'min-h-[100px] mo:min-h-[74px]':
          windowMode === PaymentWindowModeType.LARGE,
        'min-h-[74px] my-0 mx-auto': windowMode === PaymentWindowModeType.MINI,
      })}
      onClick={onClick}
    >
      <span
        className={cn('text-[#ccc]', 'mo:text-[#222]', 'tablet:text-[#bbb]')}
      >
        <img
          className={cn('w-full mx-auto', {
            'max-w-[170px] mo:max-w-[130px]':
              windowMode === PaymentWindowModeType.LARGE,
            'max-w-[130px]': windowMode === PaymentWindowModeType.MINI,
          })}
          src={`/images/payment/pgIcons/icon_${
            method ? methodLowerCase : pg
          }_dk.png`}
          alt={`icon-${method ? methodLowerCase : pg}`}
        />
      </span>
      {/* 개발자 모드 뱃지 */}
      {display === 2 && <DevBadge />}
    </button>
  );
};

/**
 * 개발모드 뱃지 중복 하나로 통합
 * @returns
 */
const DevBadge = () => {
  return (
    <div className="absolute leading-[1.05rem] mo:leading-[0.95rem] bottom-[0] right-[0] min-w-[60px] mo:min-w-[30px] px-[6px] text-center text-[#1e1f21] text-[14px] mo:text-[10px] bg-[#fdff89]">
      dev
    </div>
  );
};

PayMethodItem.Eximbay = Eximbay;
PayMethodItem.Payletter = Payletter;
PayMethodItem.UnionPay = UnionPay;
PayMethodItem.PaymentWall = PaymentWall;
PayMethodItem.NormalPay = NormalPay;
export default PayMethodItem;
