import { AppStateType, PackageNamesType } from './types';

/**
 * localstorage key 값
 */
export const enum APP_KEY {
  PACKAGE_NAME = 'app_package_name',
  FCM_TOKEN = 'app_fcm_token',
  VERSION = 'app_version',
}

/**
 * store app 구분
 */
export const enum APP_MARKET_STATE {
  NONE = 'none',
  STORE_APP = 'store_app',
  ORIGIN_APP = 'origin_app',
}

/**
 * 앱 환경 구분값
 */
export const enum APP_ENV_STATE {
  WEB = 'web',
  ANDROID = 'android',
  IOS = 'ios',
}

/**
 * 앱호출 스키마
 */
export const enum APP_SCHEME {
  NONE = '',
  ORIGIN = 'toptoonplus://',
  STORE = 'gtoptoon://',
}

/**
 * android 패키지 이름
 * * 최종 수정 : 12 / 19
 *  * store : com.topco.toptoonglobal.iap
 *  * origin : com.topco.toptoonglobal.global_toptoonplus_app
 */
export const ANDROID_PACKAGE_NAME: PackageNamesType = {
  STORE_APP: ['com.topco.toptoonglobal.iap'],
  ORIGIN_APP: ['com.topco.toptoonglobal.global_toptoonplus_app'],
};

/**
 * ios 패키지 이름
 * * 최종 수정 : 12 / 19
 *  * store : com.topco.global.toptoon.ios.iap.store
 *  * origin : com.toptoonplus.global.ios
 */
export const IOS_PACKAGE_NAME: PackageNamesType = {
  STORE_APP: ['com.topco.global.toptoon.ios.iap.store'],
  ORIGIN_APP: ['com.toptoonplus.global.ios'],
};

/**
 * app의 env, scheme, market 상태값
 * * web / android_store/ android_origin / ios_store / ios_origing 4개로 구분
 */
export const APP_STATE: {
  [key: string]: AppStateType;
} = {
  WEB: {
    appEnv: APP_ENV_STATE.WEB,
    appMarket: APP_MARKET_STATE.NONE,
    appScheme: APP_SCHEME.NONE,
  },
  ANDROID_STORE: {
    appEnv: APP_ENV_STATE.ANDROID,
    appMarket: APP_MARKET_STATE.STORE_APP,
    appScheme: APP_SCHEME.STORE,
  },
  ANDROID_ORIGIN: {
    appEnv: APP_ENV_STATE.ANDROID,
    appMarket: APP_MARKET_STATE.ORIGIN_APP,
    appScheme: APP_SCHEME.ORIGIN,
  },
  IOS_STORE: {
    appEnv: APP_ENV_STATE.IOS,
    appMarket: APP_MARKET_STATE.STORE_APP,
    appScheme: APP_SCHEME.STORE,
  },
  IOS_ORIGIN: {
    appEnv: APP_ENV_STATE.IOS,
    appMarket: APP_MARKET_STATE.ORIGIN_APP,
    appScheme: APP_SCHEME.ORIGIN,
  },
};
