import { useEffect } from 'react';
import * as Yup from 'yup';

export const ErrorMsg = () =>
  Yup.object().shape({
    password: Yup.string()
      .min(6, 'Your password should be at least 6 characters or more.')
      .max(15, 'Your password should be less than 15 characters.'),

    email: Yup.string()
      .email('Please enter a valid email address.')
      .matches(
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
        'Please enter a valid email address.',
      ),
  });

export const ErrorMsgV2 = () =>
  Yup.object().shape({
    password: Yup.string()
      .min(6, 'Your password should be at least 6 characters or more.')
      .max(15, 'Your password should be less than 15 characters.'),

    email: Yup.string().matches(
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*/i,
      'Please enter a valid email.',
    ),

    domain: Yup.string().matches(
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
      'Please enter a valid domain.',
    ),
  });

/**
 * 빈 문자열을 제외한 실제 문자열 조건 처리
 */
const notEmpty = Yup.string()
  .ensure() // 정의되지 않은 값과 null값을 빈 문자열로 변환.
  .test('Only Empty?', 'Must contain at least 5 characters', value => {
    const isValid =
      value.split(' ').join('').length === 0 ||
      value.split(' ').join('').length > 4;
    return isValid;
  });

export const QnaErrorMsg = () =>
  Yup.object().shape({
    qnaEmail: Yup.string()
      .email('Please check your email')
      .matches(
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
        'Please check your email',
      ),

    categoryValue: Yup.string().min(1, 'Cannot be blank'),
    deviceValue: Yup.string().min(1, 'Cannot be blank'),

    subjectValue: notEmpty.max(50, 'The text less than 50 characters.'),
    descriptionValue: notEmpty.max(500, 'The text less than 500 characters.'),
  });

export const CouponErrorMsg = () =>
  Yup.object().shape({
    couponValue: Yup.string()
      .min(1, 'Cannot be blank')
      .max(12, 'Cannot be 12charectors'),
  });
