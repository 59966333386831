import { UserDefault } from '@src/lib';
import { UserSession } from '@src/lib/user/UserSession';
import TopcoPageHistory from '@src/lib/utils/topcoPageHistory';
import { openJoinModal } from '@src/view/modal/loginAndJoin/join/JoinComponent';
import { openLoginModal } from '@src/view/modal/loginAndJoin/login/LoginComponent';
import { TypeCheck } from '@toptoon-developers/global.toptoonplus.common.lib';
import ApiLogin from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/rest/ApiLogin';
import ApiSignUp from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/rest/ApiSignUp';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import useAppData from './useAppData';
import { BlackListException } from '@src/exceptions/BlackListException';
import { TopcoPartner } from '@src/lib/partner';
import AppManager from '@src/lib/app/AppManager';
import Cookies from 'js-cookie';
import { useToasts } from 'react-toast-notifications';
import ApiUser from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/rest/ApiUser';
import { isMobile, isTablet, isAndroid, isIOS } from 'react-device-detect';
import { showExistIdNoticeModal } from '@src/view/modal/loginAndJoin/existIdNotice/ExistIdNoticeModal';
import dayjs from 'dayjs';

interface LoginCallback {
  onLogin: () => void;
  onError: (action: string) => void;
}

export interface UserByVisitorId {
  loginId: string;
  method: string;
  isEmail: boolean;
}

export interface BlockJoinAction {
  onPossibleJoin: () => void;
  onClose: () => void;
}

const useLogin = () => {
  // const dispatch = useDispatch();
  const router = useRouter();
  const { updateFcmToken } = useAppData();
  const { addToast } = useToasts();

  const logout = useCallback((url: string = '') => {
    const afterAction = () => {
      UserDefault.coinResetManual();
      UserSession.clearUserSession();
      UserSession.clearUserCoin();
      UserDefault.categoryEffect.clear();
      UserDefault.removeIsTestAccout();
      TopcoPageHistory.getInstance().clearFix();
      if (AppManager.getInstance().isApp()) {
        AppManager.getInstance().action.logout();
      } else {
        if (url.length > 0) {
          window.location.replace(url);
        } else {
          window.location.reload();
        }
      }
    };

    if (!UserSession.getUserInfo().token) {
      window.location.replace('/');
      return;
    }

    const inFifteenMinutes = new Date(new Date().getTime() + 2 * 1000);
    Cookies.set('logoutTimeCheck', '1', { expires: inFifteenMinutes });

    addToast('You have been logged out.');

    new ApiLogin(UserDefault.getUserSession())
      .logoutProc(UserSession.getUserInfo().token)
      .then(res => {
        afterAction();
        // addToast('You have been logged out.', {
        //   onDismiss: () => {
        //     afterAction();
        //   },
        // });
      })
      .catch(err => {
        afterAction();
        // addToast('You have been logged out.', {
        //   onDismiss: () => {
        //     afterAction();
        //   },
        // });
      });
  }, []);

  const emailLogin = (
    userId: string,
    password: string,
    auth: number,
    callback: LoginCallback,
  ) => {
    const api = new ApiLogin(UserDefault.getApiVaildateHeaders());
    const { code, is17 } = TopcoPartner.getInfo();

    const req = {
      userId,
      password,
      auth,
      is17,
      deviceId: UserDefault.env.getUdid(),
      partnerCode: code,
    };
    api
      .loginProc('', false, req)
      .then(res => {
        const { data } = res.data;

        // PG 페이먼트 심사용 계정인지 정보 저장
        if (_.has(data, 'isSigned') && data.isSigned) {
          UserDefault.setIsTestAccout(true);
        }

        if (_.has(data, 'token')) {
          setTimeout(() => {
            storeUserSession(res.data.data, 'idAndPassword');
            callback.onLogin();
          }, 500);
        }
      })
      .catch(err => {
        let action = '';
        const res = err.response;
        if (_.has(res, 'data.action')) {
          action = res.data.action;
        }
        callback.onError(action);
      });
  };

  const emailJoin = (
    email: string,
    password: string,
    callback: LoginCallback,
  ) => {
    const { code, is17 } = TopcoPartner.getInfo();

    const auth: number = UserDefault.get18Over();

    const req = {
      userId: email,
      password,
      auth,
      is17,
      deviceId: UserDefault.env.getUdid(),
      partnerCode: code,
    };

    const api = new ApiSignUp(UserDefault.getApiVaildateHeaders());

    api
      .signUpProc('', null, req)
      .then((res: any) => {
        const { code, is17 } = TopcoPartner.getInfo();

        const result = res.data.data;
        if (TypeCheck.itemsByPath(result, 'token')) {
          setTimeout(() => {
            storeUserSession(result);

            if (_.has(result, 'loginId')) {
              // 마지막 로그인 이메일 저장
              UserSession.setLastUserEmail(result.loginId);
            }
            callback.onLogin();
          }, 500);
        } else {
          callback.onError(`500`);
        }
      })
      .catch(err => {
        if (_.has(err, 'response.status')) {
          callback.onError(err.response.status);
        } else {
          callback.onError(`500`);
        }
      });
  };

  /**
   * 유저 정보 저장
   * @param data
   */
  const storeUserSession = (data: any, provider: string = 'idAndPassword') => {
    try {
      UserSession.setUserInfo({
        token: data.token ?? '',
        loginId: data.loginId ?? '',
        provider: provider.replace('.com', ''),
        userId: data.userId ?? 0,
        mature: data.mature,
        auth: data.auth,
      });

      /**
       * revokeToken은 자동로그인 시 필요.
       * 로그아웃 후에도 남아있어야 하기 때문에 따로 저장.
       */
      UserSession.setRevokeToken(data.revokeToken ?? '');

      const fcmToken = AppManager.getInstance().getFcmToken();
      const appVersion = AppManager.getInstance().getAppVersion();

      updateFcmToken(data.token, fcmToken, `${appVersion}`);
    } catch (e) {
      console.warn(e);
    }
  };

  const showJoinModal = (redirectUrl: string = '') => {
    if (redirectUrl !== '') {
      TopcoPageHistory.getInstance().setter({
        path: redirectUrl,
        scroll: 0,
        fix: 'post',
      });
    }

    openJoinModal();
  };

  const showLoginModal = (redirectUrl: string = '') => {
    if (redirectUrl !== '') {
      TopcoPageHistory.getInstance().setter({
        path: redirectUrl,
        scroll: 0,
        fix: 'post',
      });
    }

    openLoginModal();
  };

  const showJoinAndLoginModal = (redirectUrl: string = '') => {
    if (redirectUrl !== '') {
      TopcoPageHistory.getInstance().setter({
        path: redirectUrl,
        scroll: 0,
        fix: 'post',
      });
    }

    if (UserSession.getRevokeToken() === '') {
      openJoinModal();
    } else {
      openLoginModal();
    }
  };

  const loginAfterAction = useCallback(() => {
    // if (AppManager.getInstance().isStoreApp()) {
    // const { token, userId } = UserSession.getUserInfo();
    // AppManager.getInstance().action.setUser(
    //   `userId=${userId}&accessToken=${token}&isRedirect=${false}`,
    // );
    // return;
    // }
    const fixHistory = TopcoPageHistory.getInstance().getFixHistory('post');
    TopcoPageHistory.getInstance().clearFix();

    if (!fixHistory) {
      window.location.reload();
    } else if (
      router.pathname === '/comic/[comicId]/[episodeId]' ||
      router.pathname === '/content/[comicId]/[episodeId]'
    ) {
      router.replace(fixHistory.path);
    } else {
      router.push(fixHistory.path);
    }

    // TODO: set coinInfo
  }, [router]);

  const updateMature = ({
    token,
    mature,
    auth,
    authEventCode,
    comicId,
    episodeId,
    callback,
  }: {
    token: string;
    mature: number;
    auth: number;
    authEventCode?: string;
    comicId?: number;
    episodeId?: number;
    callback?: {
      onComplete: (
        action: string,
        result: boolean,
        message: string,
        code: string,
      ) => void;
      onError: (err: any) => void;
    };
  }) => {
    const api = new ApiLogin(UserDefault.getApiVaildateHeaders());
    api
      .updateMatureProc(
        token,
        mature,
        auth,
        authEventCode,
        comicId,
        episodeId,
        UserDefault.env.getUdid(),
      )
      .then((res: any) => {
        const { data } = res.data;
        const { action, result, message } = data;

        UserSession.setUserInfo({
          ...UserSession.getUserInfo(),
          mature,
          auth: mature === 1 ? 1 : auth,
        });

        callback?.onComplete(
          action,
          result,
          message,
          _.has(data, 'code') ? data.code : '',
        );
      })
      .catch(err => {
        callback?.onError(err);
      });
  };

  const snsLoginApi = (provider: any, response: any) => {
    const { code, is17 } = TopcoPartner.getInfo();

    const body = {
      credential: response,
      auth: 0,
      partnerCode: code,
      is17,
      deviceId: UserDefault.env.getUdid(),
    };

    new ApiLogin(UserDefault.getApiVaildateHeaders())
      .snsLoginProc(body, false, '')
      .then((res: any) => {
        const { data } = res.data;
        const result = {
          loginId: data.loginId,
          token: data.token,
          userId: data.userId,
          provider,
          mature: data.mature,
          auth: data.auth,
        };
        UserSession.setUserInfo(result);
        UserSession.setRevokeToken(data.revokeToken ?? ''); // 자동로그인을 위한 revoke token추가 : apple

        // 의심자 혹은 유출자 블랙리스트 처리
        if (data.type === 'suspicion') {
          throw new BlackListException();
        }

        setTimeout(() => {
          loginAfterAction();
        }, 500);
      })
      .catch(err => {
        if (err instanceof BlackListException) {
          // 의심자 소셜 로그인 블럭 처리
          UserSession.clearUserSession();
          router.replace('/?message=blacklist');
        } else {
          UserSession.clearUserSession();
        }
      });
  };

  /**
   * 자동로그인 시 동작
   * @revokeToken : 값이 있을때만 자동 로그인 가능.
   * @onLogin : 로그인 시 콜백
   * @onError : 에러시 콜백
   * 자동로그인 된 아이디와 핑거프린트에 연결된 아이디가 다를때 안내 팝업창이 뜬다.
   */
  const autoLoginAction = (
    revokeToken: string,
    callback: { onLogin: () => void; onError: () => void },
  ) => {
    try {
      ApiUser.getInstance(UserDefault.getUserSession())
        .checkRevokeToken(revokeToken)
        .then(res => {
          const result = res.data.data;

          if (TypeCheck.itemsByPath(result, 'token')) {
            // sns, email 동시 처리하기 때문에 분기처리
            if (result.method && result.method !== 'global') {
              // sns 로그인
              storeUserSession(result, result.method);
            } else {
              // email 로그인
              storeUserSession(result);
              // 최근 로그인한 아이디 저장
              if (_.has(result, 'loginId')) {
                UserSession.setLastUserEmail(result.loginId);
              }
            }
            // 자동로그인 한 아이디와 핑거프린트에 연결된 아이디가 같으면 null
            // null 일때 toast,  아니면 팝업
            // 페이지 새로고침 후, toast 및 팝업 띄우기 위해 쿠키 저장 => userCoinResponse에서 동작.
            if (result.matchingUser) {
              const { loginId, method, isEmail } = result.matchingUser;
              UserDefault.setIsAutoLoginNotice({ loginId, method, isEmail });
            } else {
              UserDefault.setTempToast();
            }
            setTimeout(() => {
              callback.onLogin();
            }, 500);
          }
        })
        .catch(err => {
          callback.onError();
        });
    } catch {
      callback.onError();
    }
  };

  /**
   * fingerPrintId로 가입된 아이디에 대한 정보.
   * @onExistId : 가입된 아이디가 존재할때 콜백
   * @onNotExistId : 가입된 아이디가 존재하지 않을때 콜백
   * @onError : 에러났을경우 콜백
   */
  const getUserByVisitorId = (callback?: {
    onExistId: (user: UserByVisitorId) => void;
    onNotExistId: () => void;
    onError: () => void;
  }) => {
    try {
      ApiUser.getInstance(UserDefault.getUserSession())
        .getUserByVisitorId(UserSession.getFingerPrintId())
        .then(res => {
          const { user } = res.data.data;

          if (!user) {
            return callback?.onNotExistId();
          }
          callback?.onExistId(user);
        })
        .catch((err: any) => {
          callback?.onError();
        });
    } catch {
      callback?.onError();
    }
  };

  /**
   * 회원가입 가능 조건 (모바일 판단 조건에 tablet 포함)
   * 현재 signup 버튼 모든 곳에 적용중(2023.02.09)
   * 1. fingerPrintId가 없을때 회원가입 시킴
   * 2. fingerPrintId가 있지만, 가입한 적이 없을때 회원가입 시킴.
   * 3. fingerPrintID가 잘못되면 회원가입 시킴
   * 4. fingerPrintId가 누가 사용중이면 정상처리하고 매칭된 아이디 팝업 보여줌(pc)
   * 5. 자동로그인 조건 : revokeToken이 있고 (모바일 / tablet 환경이면서 안드로이드이거나 ios일 경우만)
   */
  const blockJoinAction = useCallback(
    (props: BlockJoinAction) => {
      const { onPossibleJoin, onClose } = props;

      // staging 환경일땐 회원가입 가능하게 설정
      if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
        onPossibleJoin();
        return;
      }

      if (UserSession.getFingerPrintId() === '') {
        onPossibleJoin();
        return;
      }

      const revokeToken = UserSession.getRevokeToken();

      /**
       * 자동로그인 조건 : revokeToken이 있고 (모바일 / tablet 환경이면서 안드로이드이거나 ios일 경우만)
       * 조건 외 : notice 창 뜨거나 회원가입
       */

      // notice 창 뜨거나 회원가입 callback
      const userByVisitorIdCallback = {
        onExistId: (user: UserByVisitorId) => {
          showExistIdNoticeModal(user);
          onClose();
        },
        onNotExistId: () => {
          onPossibleJoin();
        },
        onError: () => {
          onPossibleJoin();
        },
      };

      if (
        revokeToken.length &&
        (isMobile || isTablet) &&
        (isAndroid || isIOS)
      ) {
        autoLoginAction(revokeToken, {
          onLogin: () => {
            onClose();
            loginAfterAction();
          },
          onError: () => {
            getUserByVisitorId(userByVisitorIdCallback);
          },
        });
        return;
      }

      getUserByVisitorId(userByVisitorIdCallback);
    },
    [isMobile, isTablet, isAndroid, isIOS],
  );

  return {
    logout,
    emailLogin,
    emailJoin,
    storeUserSession,
    showJoinModal,
    showLoginModal,
    loginAfterAction,
    showJoinAndLoginModal,
    updateMature,
    snsLoginApi,
    autoLoginAction,
    getUserByVisitorId,
    blockJoinAction,
  };
};

export default useLogin;
