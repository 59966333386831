import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export interface GnbMenuItemVM {
  name: string;
  link: string;
  isEffect: boolean;
  isActive: boolean;
  mature: number;
}

export interface GnbMenuVM {
  main: GnbMenuItemVM[];
  sub: GnbMenuItemVM[];
}

// export const defaultGnbMenuItems: GnbMenuItemVM[] = [];

export const sempleDefault: GnbMenuVM = {
  main: [
    {
      name: 'Ongoing',
      link: '/ongoing',
      isEffect: false,
      isActive: false,
      mature: 2,
    },
    {
      name: 'New',
      link: '/new',
      isEffect: false,
      isActive: false,
      mature: 2,
    },
    {
      name: 'Completed',
      link: '/completed',
      isEffect: false,
      isActive: false,
      mature: 2,
    },
    {
      name: 'Ranking',
      link: '/ranking',
      isEffect: false,
      isActive: false,
      mature: 2,
    },
    {
      name: 'Genres',
      link: '/genres',
      isEffect: false,
      isActive: false,
      mature: 2,
    },
    {
      name: 'Artworks',
      link: '/artworks',
      isEffect: false,
      isActive: false,
      mature: 1,
    },
    {
      name: 'Newsfeed',
      link: '/newsfeed',
      isEffect: false,
      isActive: false,
      mature: 0,
    },
  ],
  sub: [
    {
      name: 'Comics',
      link: '/',
      isEffect: false,
      isActive: false,
      mature: 0,
    },
    // {
    //   name: 'Unveil',
    //   link: '/unveil',
    //   isEffect: false,
    //   isActive: false,
    //   mature: 0,
    // },
  ],
};

export const GnbMenuState = atom<GnbMenuVM>({
  key: `gnbMenuState`,
  default: sempleDefault,
});
