import { CompanyInfo } from '@src/constants';
import { PaymentWindowModeType } from '../../data/_constants';
import cn from 'clsx';

interface PropsType {
  windowMode: PaymentWindowModeType;
}

const Notice = (props: PropsType) => {
  const { windowMode } = props;

  const liClassName = cn(
    'text-[0.7rem] leading-[17px] text-[#888] mb-[5px] ml-[13px] list-disc marker:text-[12px] marker:text-[#888]',
    {
      'mo:text-[12px] mo:leading-[16px]':
        windowMode === PaymentWindowModeType.LARGE,
      'text-[12.6px]': windowMode === PaymentWindowModeType.MINI,
    },
  );

  return (
    <div
      className={cn('w-full relative opacity-1', {
        'mb-[50px]': windowMode === PaymentWindowModeType.LARGE,
        'pb-[30px] ': windowMode === PaymentWindowModeType.MINI,
      })}
    >
      <p
        className={cn(
          'text-[0.9rem] text-[#fff] font-[500] tracking-[-0.2px] mb-[15px]',
          {
            'mb-[10px] mt-[18px] mr-[5px]':
              windowMode === PaymentWindowModeType.LARGE,
            'mb-[10px]': windowMode === PaymentWindowModeType.MINI,
          },
        )}
      >
        Disclosure
      </p>
      <ul
        className={cn('ml-[3px]', {
          'mt-[10px]': windowMode === PaymentWindowModeType.MINI,
        })}
      >
        <li className={liClassName}>
          Day Comics is an official platform and provides safe, online payment
          service.
        </li>
        <li className={liClassName}>
          By purchasing this product, you agree to our Terms of Services and
          Privacy Policy.
        </li>
        <li className={liClassName}>
          Coins can only be used on official Day Comics platform.
        </li>
        {/* <li className={liClassName}>
          This membership will automatically be renewed at the same price, and
          for the same period as the option you initially signed up for.
        </li>
        <li className={liClassName}>
          The membership product only grants access to episodes for temporary
          viewing. You may purchase individual episodes with coins. (Upon expiry
          of your membership, you will be unable to view episodes that you do
        not own)
        </li>
        <li className={liClassName}>
          Monthly membership payments will be made on the same date every month.
          If the set date does not exist during a particular month, it will
          default to the last day of the month.
        </li>
        <li className={liClassName}>
          To cancel your membership, please contact customer service.{' '}
        </li>
        <li className={liClassName}>
          Bonus coins provided will expire 30 days from the date of purchase.
        </li> */}
        <li className={liClassName}>
          The following service may be changed or terminated without prior
          notice depending on circumstances.
        </li>
        <li className={liClassName}>
          Bonus coins will expire within 30 days from the date of purchase.
        </li>
        <li className={liClassName}>
          If you need any help or questions, please contact to our customer
          support at{' '}
          <a
            className="text-[#999] font-[500]"
            href={`mailto:${CompanyInfo.EMAIL_CS}`}
            target="_self"
          >
            {CompanyInfo.EMAIL_CS}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Notice;
