import { MembershipCode } from '@src/constants';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export interface MenuArrTypes {
  title: string;
  link: string;
  imgPath: string;
  color: string;
  type?: string;
}
const menuArr: MenuArrTypes[] = [
  // {
  //   title: 'Purchase Coins',
  //   link: '/payment',
  //   imgPath: '/images/common/icon_hd_goldchase_shake.gif',
  //   color: 'gold',
  // },
  // { title: 'My Account', link: '/mypage', imgPath: '', color: '' },
  // {
  //   title: 'Unveil',
  //   link: '/unveil',
  //   imgPath: 'special',
  //   color: '',
  //   type: 'action',
  // },
  {
    title: 'Coins',
    link: '/payment',
    imgPath: 'payment',
    color: '',
  },
  {
    title: 'My Library',
    link: '/library',
    imgPath: 'library',
    color: '',
  },
  // { title: 'GIFT', link: '/gift', imgPath: 'gift', color: '' },
  // {
  //   title: 'Newsfeed',
  //   link: '/newsfeed',
  //   imgPath: 'newsfeed',
  //   color: '',
  // },
  // {
  //   title: 'Notice',
  //   link: '/help/notice',
  //   imgPath: 'notice',
  //   color: '',
  // },
  // { title: 'FAQ', link: '/help/faq', imgPath: 'faq', color: '' },
  {
    title: '1:1 Q&A',
    link: '/help/qna',
    imgPath: 'qna',
    color: '',
  },
];

export enum HeaderType {
  login = 'Login',
  guest = 'Guest',
}
interface MembershipTypes {
  code: MembershipCode;
  refreshAt: string;
}

interface BonusCoinTypes {
  coin: number;
  refreshAt: string;
}
export interface UserInfoTypes {
  provider: string;
  userId: string;
  userCoin: number;
  userBonusCoin: BonusCoinTypes;
  userMembership: MembershipTypes;
}

interface SnbMenuType {
  menuList: MenuArrTypes[];
  headerType: HeaderType;
  userInfo: UserInfoTypes;
}

export const initSnbMenu = {
  headerType: HeaderType.guest,
  menuList: menuArr,
  userInfo: {
    provider: '',
    userId: '',
    userCoin: 0,
    userBonusCoin: {
      coin: 0,
      refreshAt: '',
    },
    userMembership: {
      code: MembershipCode.NOT_SET,
      refreshAt: '',
    },
  },
};

const localStorage =
  typeof window !== 'undefined' ? window.localStorage : undefined;

// const { persistAtom } = recoilPersist({
//   key: 'snbMenuPersist',
//   storage: localStorage,
// });

export const SnbMenuState = atom<SnbMenuType>({
  key: `snbMenuState`,
  default: initSnbMenu,
  // effects_UNSTABLE: [persistAtom],
});
