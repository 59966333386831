import Countdown from 'react-countdown';
import React, { useEffect, useState } from 'react';
import { CrazyBannerType, initCrazyBanner, TimeSliceVM } from './viewModel';
import { LimitCheck } from '@src/lib/utils/utils';
import { isBetweenCheck } from '@toptoon-developers/global.toptoonplus.common.lib';
import { CrazyTimerMain } from './viewItems';
import { UserSession } from '@src/lib';
import useLogin from '@src/hooks/useLogin';
import { openMiniPaymentModal } from '@src/view/modal/payment';
import { useRouter } from 'next/router';

interface PropsType {
  children: React.ReactNode;
  banner?: CrazyBannerType | null;
  className: string;
}
/**
 * 미친결제 배너
 * @param props : children, banner, className
 * @returns mainImg, subImg, countDown
 */
const CrazyTimer = (props: PropsType) => {
  const { children, banner, className } = props;
  const [isFinsh, setIsFinish] = useState(false); // Countdown이 끝나면 true
  const [isViewCrazyTimer, setIsViewCrazyTimer] = useState(false); //Crazy Timer를 보여줄지 유무
  const { showJoinAndLoginModal } = useLogin();
  const router = useRouter();
  // Crazy Timer를 보여줄지 유무를 정하는 hook
  useEffect(() => {
    if (!banner) return;
    setIsViewCrazyTimer(
      banner !== initCrazyBanner &&
        banner.mainImgPath !== '' &&
        // isBetweenCheck(banner.availableAt, banner.expiredAt) &&
        !isFinsh,
    );
  }, [banner, isFinsh]);

  if (!banner) {
    return <>{children}</>;
  }

  const handleBannerClick = (className: string) => {
    const { token } = UserSession.getUserInfo();
    console.log(token);
    if (!token) {
      showJoinAndLoginModal('/payment');
    } else {
      router.push('/payment');
    }
  };

  const renderer = ({ days, hours, minutes, seconds, completed, api }: any) => {
    // Countdown이 끝나면
    if (completed) {
      return <></>;
    }

    const hour = days * 24 + hours;
    const timeSliceData = new TimeSliceVM(hours, minutes, seconds);

    const CrazyTimerBannerProps = {
      timeSliceData: timeSliceData.getTimeSliceData(),
      background: banner.background,
      type: banner.type,
      mainImgPath: banner.mainImgPath,
      subImgPath: banner.subImgPath,
      dotImgPath: banner.dot,
      bgColor: banner.colorCode,
      handleBannerClick: handleBannerClick,
      className, // mainTop episodeWeb, episodeMo, paymentTop, paymentMiniTop 에 따라 스타일을 다르게 함.
      isTimer: banner.isTimer === 1 ? true : false,
    };

    /**
     * * common : mainTop, episodeWeb, episodeMo 에서 사용
     * * payment : paymentTop, paymentMiniTop 에서 사용
     */

    return <CrazyTimerMain {...CrazyTimerBannerProps} />;
  };

  return (
    <>
      {isViewCrazyTimer ? (
        <Countdown
          key={banner.type}
          date={banner.expiredAt}
          renderer={renderer}
          onComplete={e => {
            setIsFinish(true);
          }}
        />
      ) : (
        children
      )}
    </>
  );
};

export default CrazyTimer;
