import { useEffect, useState } from 'react';

export const WINDOW_SIZE = {
  PC: 1024,
  TABLET: 768,
  MOBILE: 767, // 이하
  ENOM: 300,
};

/**
 * window size에 대한 custom hook
 */
const useMediaBreak = () => {
  const [isDesktop, setIsDesktop] = useState(true);
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const windowSizeCheck = () => {
    if (window.innerWidth >= WINDOW_SIZE.PC) {
      setIsDesktop(true);
      setIsTablet(false);
      setIsMobile(false);
    } else if (window.innerWidth >= WINDOW_SIZE.TABLET) {
      setIsDesktop(false);
      setIsTablet(true);
      setIsMobile(false);
    } else {
      setIsDesktop(false);
      setIsTablet(false);
      setIsMobile(true);
    }
  };

  const handle = (e: any) => {
    windowSizeCheck();
  };

  useEffect(() => {
    windowSizeCheck();
    window.addEventListener('resize', handle);
    return () => {
      window.removeEventListener('resize', handle);
    };
  }, []);

  return { isDesktop, isMobile, isTablet };
};

export default useMediaBreak;
