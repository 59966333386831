import { recoilPersist } from 'recoil-persist';
import { atom } from 'recoil';

interface FetchMatureType {
  isFetchMature: boolean;
}

const localStorage =
  typeof window !== 'undefined' ? window.localStorage : undefined;

const { persistAtom } = recoilPersist({
  key: 'fetchPersitst',
  storage: localStorage,
});

export const fetchMature = atom<FetchMatureType>({
  key: 'fetchMature',
  default: { isFetchMature: false },
  effects_UNSTABLE: [persistAtom],
});
