import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import cn from 'clsx';
import { Form, Formik } from 'formik';
import { Banner } from '@src/view/common/banner';
import { CloseBtn } from '@src/view/common/closeBtn';
import { useState } from 'react';
import useLogin from '@src/hooks/useLogin';
import { UserSession } from '@src/lib/user';
import { ErrorMsgV2 } from '@src/lib/utils/userUtils';
import {
  DomainForm,
  LoginAndJoinBtn,
  PasswordForm,
  TitleComponent,
} from '../common';
import { openResetPasswordModal } from '../resetPassword/ResetPasswordComponent';
import { openJoinModal } from '../join/JoinComponent';
import { SnsButtons } from '@src/view/components/ui/';
import { useRouter } from 'next/router';
import TopcoPageHistory from '@src/lib/utils/topcoPageHistory';
import { SnsBtnTypeCode } from '@src/view/components/ui/snsButtons/constants/SnsBtnTypeCode';
import { CompanyInfo } from '@src/constants';

const LOGIN_MODAL_KEY = 'loginModal';
export const closeLoginModal = () => {
  ModalInstance.getInstance().delete(LOGIN_MODAL_KEY);
};

export const openLoginModal = () => {
  ModalInstance.getInstance().push({
    key: LOGIN_MODAL_KEY,
    shouldCloseOnOverlayClick: false,
    component: <LoginComponent />,
  });
};

const LoginComponent = () => {
  const [message, setMessage] = useState('');
  const [isSubmitBtn, setIsSubmitBtn] = useState(true);
  const { emailLogin, loginAfterAction, blockJoinAction } = useLogin();
  const router = useRouter();

  // Sign up 버튼 클릭시
  const signUpBtnClick = (e: any) => {
    blockJoinAction({
      onPossibleJoin: () => {
        e.preventDefault();
        closeLoginModal();
        openJoinModal();
      },
      onClose: () => {
        closeLoginModal();
      },
    });
  };

  const onSubmit = (values: any, actions: any) => {
    setIsSubmitBtn(false);
    if (values.email && values.domain) {
      UserSession.setLastUserEmail(`${values.email}@${values.domain}`);
    }

    emailLogin(`${values.email}@${values.domain}`, values.password, 0, {
      onLogin() {
        closeLoginModal();
        loginAfterAction();
        setIsSubmitBtn(true);
      },
      onError(action) {
        setMessage('');
        setIsSubmitBtn(true);
        if (action.length > 0) {
          notice(action);
          setIsSubmitBtn(true);
        }
      },
    });
  };

  const notice = (msg: string) => {
    switch (msg) {
      case 'wrong':
        return setMessage('auth fail');
      case 'exist':
        return setMessage(
          'Email address already exists. Please check your other account.',
        );
      case 'userId':
        return setMessage('auth fail');
      case 'robot':
        return setMessage(
          'An error has occurred. Please contact customer support.',
        );
      case 'suspicion':
        return setMessage(
          `An error has occurred while logging into your account. For more details, please contact our customer service center at ${CompanyInfo.EMAIL_CS}`,
        );
      default:
        return setMessage(
          'Your password should be at least 6 characters or more.',
        );
    }
  };

  return (
    <div className="animation_fadein bg-white z-200 max-w-[400px] w-[88vw] max-h-[90vh] overflow-hidden overflow-y-auto scrollbar-hide">
      <div className="relative popupInner">
        <CloseBtn
          imgPath="/images/alert/close/ico_close_white.png"
          className="absolute top-[2px] right-[2px] w-[35px] p-[5px]"
          onClick={(e: any) => {
            TopcoPageHistory.getInstance().clearFix();
            const { action } = router.query;
            if (action && action === 'login') {
              router.replace('/', undefined, { shallow: true });
            } else {
              router.replace(router.asPath, undefined, { shallow: true });
            }
            closeLoginModal();
          }}
        />

        {/* topBanner */}
        <Banner.LoginAndJoin type="login" />
        {/* topBanner */}

        {/* contentArea */}
        <div className="contentCon">
          <Formik
            initialValues={{
              email: UserSession.getLastUserId(),
              domain: UserSession.getLastUserDomain(),
              password: '',
            }}
            validationSchema={ErrorMsgV2()}
            onSubmit={onSubmit}
          >
            {({ values, errors, touched, handleChange }) => {
              return (
                <Form className="p-[0_25px_30px] mo:p-[0.2rem_25px_1.7rem] underMo:p-[1rem] max-w-[350px] mx-auto text-[.8rem] text-[#666] leading-[1.1rem]">
                  {/* snsArea */}
                  {/* <div className={`text-center relative mb-[10px]`}>
                    <TitleComponent
                      title="One-click social media Sign In"
                      type="snsTit"
                    />
                  </div> */}
                  {/* <SnsButtons type={SnsBtnTypeCode.Login} /> */}
                  {/* <h1 className="socialLine bg-[#f2f2f5] w-full h-[1px] my-[15px]" /> */}
                  {/* snsArea */}
                  <TitleComponent title="Sign In with email" />

                  {/* DomainForm */}
                  <DomainForm
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    type={'login'}
                  />
                  {/* DomainForm */}

                  {/* PasswordForm */}
                  <PasswordForm
                    values={values}
                    message={message}
                    handleChange={(e: any) => {
                      handleChange(e);
                      if (message) {
                        setMessage('');
                      }
                    }}
                  />
                  {/* PasswordForm */}

                  {/* loginBtn */}
                  <LoginAndJoinBtn
                    disabled={
                      !(
                        errors.email === undefined &&
                        errors.password === undefined &&
                        values.email &&
                        values.password &&
                        isSubmitBtn
                      )
                    }
                    title="Sign In"
                  />
                  {/* loginBtn */}

                  <div
                    className={cn(
                      `subSignup mt-[10px] mx-auto overflow-hidden flex items-center justify-between relative h-[30px]`,
                    )}
                  >
                    <span
                      className={`forgotPass text-[#333] font-semibold text-[14px] underline py-[5px]`}
                      onClick={() => {
                        openResetPasswordModal();
                      }}
                    >
                      Forgot your password?
                    </span>
                    <button
                      className="text-black font-semibold text-[16px] underline py-[2px]"
                      type="button"
                      onClick={signUpBtnClick}
                    >
                      Sign Up
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default LoginComponent;
