import { isOpenCheck } from '@toptoon-developers/global.toptoonplus.common.lib/dist/sdk/common';
import { TypeCheck } from '@toptoon-developers/global.toptoonplus.common.lib/dist/utils';
import parser from '@src/lib/utils/perserUtils';
import {
  convertFormat,
  thumbnailToPath,
} from '@toptoon-developers/global.toptoonplus.common.lib';
import _ from 'lodash';

type crazyImagesType = {
  jpeg: string;
  webp: string;
};

export interface CrazyBannerDataType {
  availableAt: string;
  expiredAt: string;
  background: crazyImagesType;
  colorCode: string;
  dot: crazyImagesType;
  mainText: crazyImagesType;
  paymentMainText: crazyImagesType;
  paymentSubText: crazyImagesType;
  subText: crazyImagesType;
  isTimer: number;
  isMembershipPeekIn: number;
}

export interface CrazyBannerBaseType {
  availableAt: string;
  expiredAt: string;
  background: string;
  dot: string;
  colorCode: string;
  isTimer: number;
  isMembershipPeekIn: number;
}

export interface CrazyBannerEtcType {
  mainImgPath: string;
  subImgPath: string;
  type: 'common' | 'payment';
}
export interface CrazyBannerType
  extends CrazyBannerBaseType,
    CrazyBannerEtcType {}

export const initCrazyBanner: CrazyBannerType = {
  availableAt: '',
  expiredAt: '',
  background: '',
  colorCode: '',
  dot: '',
  mainImgPath: '',
  subImgPath: '',
  type: 'common',
  isTimer: 0,
  isMembershipPeekIn: 0,
};

/**
 * global process 에서 crazyPaymentBanner 값이 있으면 실행되는 함수
 * * 로컬 오픈시간, 종료시간 체크
 * @param data
 * @param isWebp
 * @returns 오픈 시간 체크하여 해당되지 않으면 commonBanner, paymentBanner Null
 */
export const parserCrazyBannerImage = (
  data: CrazyBannerDataType,
  isWebp: boolean = true,
): {
  commonBanner: CrazyBannerType | null;
  paymentBanner: CrazyBannerType | null;
} => {
  let commonBanner: CrazyBannerType | null = null;
  let paymentBanner: CrazyBannerType | null = null;

  // if (!isOpenCheck(data, 'availableAt', 'expiredAt'))
  //   return { commonBanner, paymentBanner };

  const baseBanner = {
    availableAt: data.availableAt,
    expiredAt: data.expiredAt,
    background: TypeCheck.itemsByPath(data.background, 'webp'),
    dot: TypeCheck.itemsByPath(data.dot, 'webp'),
    isTimer: data.isTimer,
    isMembershipPeekIn: data.isMembershipPeekIn,
    colorCode: data.colorCode,
  };
  const common: CrazyBannerEtcType = {
    mainImgPath: TypeCheck.itemsByPath(data.mainText, 'webp'),
    subImgPath: TypeCheck.itemsByPath(data.subText, 'webp'),
    type: 'common',
  };
  const payment: CrazyBannerEtcType = {
    mainImgPath: TypeCheck.itemsByPath(data.paymentMainText, 'webp'),
    subImgPath: TypeCheck.itemsByPath(data.paymentSubText, 'webp'),
    type: 'payment',
  };

  commonBanner = { ...baseBanner, ...common };
  paymentBanner = { ...baseBanner, ...payment };

  return { commonBanner, paymentBanner };
};
