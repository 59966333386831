import { PaymentBanner } from '@src/view/common/banner';
import { TopcoStorage, UserDefault, UserSession } from '@src/lib';
import AppManager from '@src/lib/app/AppManager';
import {
  isOpenCheck,
  thumbnailToPath,
} from '@toptoon-developers/global.toptoonplus.common.lib';
import ApiPaymentV2 from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/rest/ApiPaymentV2';
import { TypeCheck } from '@toptoon-developers/global.toptoonplus.common.lib/dist/utils';
import _ from 'lodash';
import { OsType } from '../data/_constants';
import { PaymentContext } from './PaymentContext';
import {
  DiscountCouponModel,
  PaymentBannerType,
  PaymentMethodViewModel,
  ProductItemViewModel,
} from './TopcoPaymentViewModel';
import { UserInfoType } from '@src/lib/user/types';

const enum MATURE_TYPE {
  NON_ADULT = 1, // 비성인
  ADULT = 2, // 성인
  BOTH = 3, // 둘다 노출
}

const enum DISPLAY_TYPE {
  HIDE = 0, // 비노출
  DISPLAY = 1, // 노출
  DEV = 2, // 개발자모드
}

export namespace TopcoPaymentRequestApi {
  export const productList = async (): Promise<any> => {
    try {
      const userInfo = UserSession.getUserInfo();
      return ApiPaymentV2.getInstance(UserDefault.getUserSession())
        .getProducts(
          userInfo.token,
          AppManager.getInstance().isStoreApp() ? 1 : 0,
        )
        .then(res => {
          const { data } = res.data;
          return new TopcoPayment(userInfo, data).getData();
        });
    } catch (e) {
      throw new Error('exception productList');
    }
  };
}

export class TopcoPayment {
  paymentContext: PaymentContext;

  constructor(userInfo: UserInfoType, response: any) {
    const { products } = response;

    const itemKeys: string[] = Object.keys(products);

    let pIndex = 0;

    // paymetListTransformObject 의 경우 동적으로 받아옴 키값이 탄력적으로 증감하기 때문에 주의 할 것
    const productList: Array<ProductItemViewModel> = [];

    itemKeys.forEach((key: string, index: number) => {
      const info = TypeCheck.itemsByPath(products[key], 'info');
      const transformObject = _.transform(
        products[key].products,
        (result: any, value, k) => {
          result.push({
            ...value,
            id: pIndex++,
            groupString: `${key}`,
            info,
          });
        },
        [],
      );

      productList.push(...transformObject);
    });

    let paymentBanner: PaymentBannerType | null = null;

    if (
      TypeCheck.isValidArray(response.paymentBanner) &&
      response.paymentBanner.length > 0
    ) {
      const bannerList = _.transform(
        response.paymentBanner,
        (result: PaymentBannerType[], bannerData) => {
          if (!bannerData || !_.has(bannerData, 'images')) return null;

          if (!isOpenCheck(bannerData, 'availableAt', 'expireAt')) return null;

          const imgs = bannerData.images;
          const banner = {
            pcImg: thumbnailToPath(imgs.pc, 'webp'),
            moImg: thumbnailToPath(imgs.mo, 'webp'),
          };

          result.push(banner);
        },
        [],
      );
      if (bannerList.length > 0) {
        paymentBanner = bannerList[0];
      }
    }
    if (TypeCheck.isValidObject(response.paymentBanner.images)) {
      if (_.has(response, 'paymentBanner.images')) {
        const imgs = response.paymentBanner.images;
        paymentBanner = {
          pcImg: thumbnailToPath(imgs.pc, 'webp'),
          moImg: thumbnailToPath(imgs.mo, 'webp'),
        };
      }
    }

    // 기본 상품 지정 (normal 상품 기준 )
    const filteredListDefaultProduct = _.filter(productList, {
      defaultProduct: 1,
    });

    // membership 상품 기본 상품 지정
    const filteredListMembershipProduct = _.filter(productList, {
      groupString: 'MEMBERSHIP',
      defaultProduct: 1,
    });

    const defaultProduct =
      filteredListMembershipProduct.length > 0
        ? filteredListMembershipProduct
        : filteredListDefaultProduct;

    // goods
    const discountCoupon: DiscountCouponModel.DiscountCoupon[] =
      TypeCheck.itemsByPath(response, 'goodsDiscountCoupon') ?? [];

    // user group code
    const userGroupCode =
      TypeCheck.itemsByPath(response, 'data.userGroupCode') ?? '';

    /**
     * 노출/비노출/개발자모드, hostname, order값 구분해서 pgList data setting
     */

    // 자동충전인지, 노말인지.
    // const pgList = this.getPgList(response.paymentMethod, 'normal');
    // const recurringPgList = this.getPgList(response.paymentMethod, 'recurring');

    const pgList: PaymentMethodViewModel[] = [];
    pgList.push({
      id: 0,
      pg: 'xsolla',
      method: 'paypal',
      deviceBit: 0,
      matureBit: 0,
      paymentType: 'normal',
      order: 0,
      display: 1,
    });
    const recurringPgList: PaymentMethodViewModel[] = [];

    this.paymentContext = {
      token: userInfo.token,
      loginInfo: userInfo,
      pgList,
      recurringPgList,
      topcoPayment: this,
      paymentBanner,
      productList,
      osType: OsType.pc,
      discountCoupon,
      userGroupCode,
      defaultProduct: defaultProduct.length > 0 ? defaultProduct[0] : null,
    };
  }

  getData(): PaymentContext {
    return this.paymentContext;
  }

  getGroupByFirstTimeTen(): Array<ProductItemViewModel> {
    return _.filter(this.paymentContext.productList, {
      groupString: 'NORMAL',
    });
  }

  getGroupByOneDollar(): Array<ProductItemViewModel> {
    return _.filter(this.paymentContext.productList, {
      groupString: 'ONE_DOLLAR_AUTO_PAYMENT',
    });
  }

  getGroupByNormal(): Array<ProductItemViewModel> {
    return _.filter(this.paymentContext.productList, {
      groupString: 'NORMAL',
    });
    // return this.paymentContext.productList;
  }

  getGroupByMembership(): Array<ProductItemViewModel> {
    return _.filter(this.paymentContext.productList, {
      groupString: 'MEMBERSHIP',
    });
  }

  getGroupByAutoPayment(): Array<ProductItemViewModel> {
    return _.filter(this.paymentContext.productList, {
      groupString: 'AUTO_PAYMENT',
    });
    // return this.paymentContext.productList;
  }

  private getPgList = (paymethodData: any, pgType: string) => {
    return _.sortBy(
      _.transform(
        paymethodData,
        (result: PaymentMethodViewModel[], payMethodItem) => {
          if (payMethodItem.display === 0) return;

          const { mature } = UserSession.getUserInfo();
          let viewPgItem = null;

          // 성인모드
          if (mature === 1) {
            // 개발자모드이고 로컬스토리지에 넣은 pg+Method값이 같으면, 노출모드인 상품에 개발자모드노출모드 상품 추가

            if (
              payMethodItem.matureBit === MATURE_TYPE.NON_ADULT ||
              payMethodItem.paymentType !== pgType
            ) {
              return;
            }

            if (payMethodItem.display === DISPLAY_TYPE.DEV) {
              // TODO : 로컬스토리지에 키가 있는지 없는지

              const currentMethod = payMethodItem.method
                ? `_${payMethodItem.method}`
                : '';
              const currentPayMethod = TopcoStorage.getItem(
                payMethodItem.pg + currentMethod,
              );

              if (currentPayMethod === undefined || currentPayMethod === null) {
                return;
              }
            }

            viewPgItem = payMethodItem;
          } else if (mature === 0) {
            if (
              payMethodItem.matureBit === MATURE_TYPE.ADULT ||
              payMethodItem.paymentType !== pgType
            ) {
              return;
            }

            if (payMethodItem.display === DISPLAY_TYPE.DEV) {
              // TODO : 로컬스토리지에 키가 있는지 없는지
              const currentMethod = payMethodItem.method
                ? `_${payMethodItem.method}`
                : '';
              const currentPayMethod = TopcoStorage.getItem(
                payMethodItem.pg + currentMethod,
              );

              if (currentPayMethod === undefined || currentPayMethod === null) {
                return;
              }
            }

            viewPgItem = payMethodItem;
          }

          result.push(viewPgItem);
        },
        [],
      ),
      'order',
    );
  };
}
