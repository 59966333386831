import { ProductItemViewModel } from '@src/view/components/payment/viewModel';
import { StoreType } from '../../../data/usePaymentReducer';
import {
  PaymentWindowModeType,
  ProductGroupType,
} from '../../../data/_constants';
import { ItemHeader } from '../item';
import ProductItem from '../item/ProductItem';

interface PropsType {
  windowMode: PaymentWindowModeType;
  groupType: ProductGroupType;
  selectProductItem: ProductItemViewModel | null;
  productItems: ProductItemViewModel[];
  setSelectItem?: (item: ProductItemViewModel) => void;
}

const ProductList = (props: PropsType) => {
  const {
    productItems,
    groupType,
    windowMode,
    selectProductItem,
    setSelectItem,
  } = props;

  if (productItems.length === 0) return null;

  return (
    <>
      {/* item-Header */}
      <ItemHeader windowMode={windowMode} />
      {/* item-Header */}

      {productItems.map(c => {
        return (
          <ProductItem
            key={`product-item-${c.id}`}
            isSelect={selectProductItem?.id === c.id}
            windowMode={windowMode}
            groupType={groupType}
            item={c}
            setSelectItem={setSelectItem}
          />
        );
      })}
    </>
  );
};

export default ProductList;
