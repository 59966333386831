import CountDown from './CountDown';
import cn from 'clsx';
import { TimeSliceType } from '../viewModel';

interface CrazyTimerMainProps {
  timeSliceData: TimeSliceType;
  background: string;
  type: string;
  mainImgPath: string;
  bgColor: string;
  subImgPath: string;
  dotImgPath: string;
  className: string;
  handleBannerClick: (type: string) => void;
  isTimer: boolean;
}

/**
 *
 * @param props timeSliceData, background, type, mainImgPath, subImgPath, dotImgPath, className
 * @returns
 * 2022.12.16 - MOON
 * type: 'common' | 'payment'
 * * common : mainTop, episodeWeb, episodeMo, sideMenu에서 사용
 * * payment : paymentTop, paymentMiniTop 에서 사용
 * className으로 스타일 제어
 * mainTop episodeWeb, episodeMo, paymentTop, paymentMiniTop, sideMenu, viewer 에 따라 스타일을 다르게 함.
 */
const CrazyTimerMain = (props: CrazyTimerMainProps) => {
  const {
    timeSliceData,
    background,
    type,
    mainImgPath,
    subImgPath,
    dotImgPath,
    bgColor,
    className,
    handleBannerClick,
    isTimer,
  } = props;

  return (
    <div
      className={cn('crazyTimerMain w-full cursor-pointer', {
        ['rounded-[8px]']: className === 'sideMenu',
      })}
      style={{ backgroundColor: bgColor }}
      onClick={() => {
        handleBannerClick(className);
      }}
    >
      <div
        className={cn(
          'CrazyTimerInner grid content-center justify-center gap-y-[2px]',
          {
            ['grid-areas-crazyMainAndEpiPc mo:grid-areas-crazyMainAndEpiMo mo:items-center']:
              type === 'common',
            ['grid-areas-crazyOtherPc justify-items-center payUnderMo:grid-areas-crazyOthertMo']:
              type === 'payment',
            ['h-[66px] mo:h-[49px] items-center gap-x-[12px]']:
              className === 'mainTop',
            ['h-[130px] pointer-events-none gap-y-[8px] gap-x-[6px] mt-[50px] mo:mt-[10px] mo:py-[4%] mo:gap-x-[12px] mo:gap-y-[4px] payUnderMo:h-auto']:
              className === 'paymentTop',
            ['mt-[21px] mx-[8px] py-[4%] h-full']:
              className === 'paymentMiniTop',
            ['grid-areas-crazyMainAndEpiMo h-[49px] gap-x-[12px]']:
              className === 'episodeWeb' || className === 'episodeMo',
            ['grid-areas-crazyMainAndEpiMo w-full h-[37px] gap-x-[6px]']:
              className === 'sideMenu',
            ['grid-areas-crazyMainAndEpiMo aspect-[720/100] gap-x-[20px]']:
              className === 'viewer',
          },
          className,
        )}
        style={{
          background: `center / cover url(${background})`,
        }}
      >
        {/* mainImg */}
        <div
          className={cn('mainImgCon grid-in-mainImg inline-block', {
            ['h-[36px] mo:h-[18px]']: className === 'mainTop',
            ['h-[48px] mo:h-[30px]']: className === 'paymentTop',
            ['h-[24px]']: className === 'paymentMiniTop',
            ['h-[18px]']:
              className === 'episodeWeb' || className === 'episodeMo',
            ['h-[15px]']: className === 'sideMenu',
            ['h-[30px] mo:h-[15px]']: className === 'viewer',
          })}
        >
          <img className="mx-auto h-full" src={mainImgPath} alt="" />
        </div>
        {/* mainImg */}

        {/* subImg */}
        <div
          className={cn('subImgCon grid-in-subImg inline-block', {
            ['h-[26px] mo:h-[13px]']: className === 'mainTop',
            ['h-[48px] mo:h-[30px]']: className === 'paymentTop',
            ['h-[24px]']: className === 'paymentMiniTop',
            ['h-[13px]']:
              className === 'episodeWeb' || className === 'episodeMo',
            ['h-[10px]']: className === 'sideMenu',
            ['h-[20px] mo:h-[10px]']: className === 'viewer',
          })}
        >
          <img className="mx-auto h-full" src={subImgPath} alt="" />
        </div>
        {/* subImg */}
        {/* countDown */}
        {isTimer && (
          <CountDown
            timeSliceData={timeSliceData}
            dotImgPath={dotImgPath}
            className={className}
          />
        )}
        {/* countDown */}
      </div>
    </div>
  );
};
export default CrazyTimerMain;
