import { UserDefault, UserSession } from '@src/lib';
import ApiLogin from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/rest/ApiLogin';
import Cookies from 'js-cookie';
import _ from 'lodash';
import {
  getAuth,
  signInWithRedirect,
  OAuthProvider,
  signInWithPopup,
} from '@firebase/auth';
import AppManager from '@src/lib/app/AppManager';

export class GoogleTopcoAuthReqeust implements TopcoAuthRequest {
  image: string;
  providerAccountId: string;
  provider: string;
  email: string;

  constructor(data: any) {
    this.image = data['image'] || '';
    this.providerAccountId = data['providerAccountId'] || '';
    this.provider = data['provider'] || '';
    this.email = data['email'] || '';
  }

  public isValidationForm() {
    return this.provider !== '' && this.providerAccountId !== '';
  }
}
export interface GoogleAuthResponse {
  id: string;
  email: string | undefined;
  verified_email: boolean | undefined;
  picture: string | undefined;
  name: string | undefined;
}

export interface TopcoAuthRequest {
  image: string;
  providerAccountId: string;
  provider: string;
  email: string;
}

export interface AppleUserInfo {
  uid: string;
}

export default class AuthController {
  static createCredential = (credentialOrigin: GoogleAuthResponse) => {
    return {
      image: credentialOrigin.name ?? '',
      providerAccountId: credentialOrigin.id,
      provider: 'google.com',
      email: credentialOrigin.email ?? '',
    };
  };
  static ownToken = async (credential: TopcoAuthRequest) => {
    try {
      return ApiLogin.getInstance(UserDefault.getUserSession())
        .jpSnsLogin(credential)
        .then(res => res.data)
        .then(res => res.data);
    } catch (e) {
      throw new Error('sns login error');
    }
  };
  static twitterLogin = (hostName: string) => {
    ApiLogin.getInstance(UserDefault.getUserSession())
      .getTwitterOauthToken(hostName)
      .then((res: any) => {
        const { data } = res.data;
        Cookies.remove('twitterToken');
        Cookies.set('twitterToken', data.twitterToken, {
          expires: 1,
        });

        if (_.has(data, 'twitterOauthUrl')) {
          window.open(data.twitterOauthUrl, '_self');
        } else {
          throw new Error('api login error');
        }
      })
      .catch(err => {
        console.warn(err);
      });
  };

  static appleLoginIn = (callback: (uid: any) => void) => {
    const appManager = AppManager.getInstance();

    if (appManager.isIosApp() && appManager.isOriginApp()) {
      const rUrl = encodeURIComponent('/appSnsCallback?provider=apple');
      appManager.action.showBrowser(rUrl);
      callback(null);
      return;
    }
    const auth = getAuth();

    if (!auth) return;
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');

    if (appManager.isAndroidApp()) {
      signInWithRedirect(auth, provider);
      return;
    }

    signInWithPopup(auth, provider)
      .then(result => {
        const { user } = result;
        const { uid } = user;
        callback(uid);
      })
      .catch(err => {
        console.warn(err);
        callback(null);
      });
  };

  static googleLogin = async (credential: string, callback: () => void) => {
    const JWTparse = (token: string) => {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const payload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );
      return JSON.parse(payload);
    };
    const cred = JWTparse(credential);
    const user = await AuthController.ownToken({
      image: cred.name,
      providerAccountId: cred.sub,
      provider: 'google.com',
      email: cred.email,
    });

    UserSession.setUserInfo({
      ...user,
      provider: 'google',
    });
    UserSession.setRevokeToken(user.revokeToken ?? ''); // 자동로그인을 위한 revoke token추가 : google
    callback();
  };
}
