import { useCallback, useEffect, useReducer } from 'react';
import { usePaymentData } from '../data';
import {
  Device,
  PaymentAction,
  PaymentWindowModeType,
  pgList,
  pgRecurringList,
} from '../data/_constants';
import {
  PaymentContext,
  PaymentMethodViewModel,
  PgItemType,
  ProductItemViewModel,
} from '../viewModel';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { UserDefault, UserSession } from '@src/lib';
import { EximbayPurchase } from '../action/EximbayPurchase';
import useStore from '../data/usePaymentReducer';
import { TopcoLoadingController } from '@src/view/error/TopcoLoading';
import {
  PayLetterPurchase,
  PaymentWallPurchase,
  PaypalPurchase,
} from '../action';
import AppManager from '@src/lib/app/AppManager';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { StripePurchase } from '../action/StripePurchase';
interface WithPaymentHocProps {
  windowMode: PaymentWindowModeType;
  isStoreApp: boolean;
  WrappedComponent: React.ComponentType<PaymentContext>;
}

const WithPaymentHoc: React.FC<WithPaymentHocProps> = props => {
  const { WrappedComponent, isStoreApp, windowMode } = props;

  const store = useStore();
  const { mutate, data: paymentContext, error } = usePaymentData();

  // 디바이스별로 set (일반상품/자동결제상품)
  useEffect(() => {
    if (!paymentContext) return;
    store.setPgList(paymentContext.pgList);
    store.setRecurringPgList(paymentContext.recurringPgList);

    // const pgListFilter = (deviceBit: number) => {
    //   // 디바이스 비트 필터 함수
    //   const payDeviceFilter = (deviceBit: number, type: string) => {
    //     return _.filter(paymentContext[type], (v: PaymentMethodViewModel) => {
    //       return v.deviceBit & deviceBit;
    //     });
    //   };

    //   store.setPgList(payDeviceFilter(deviceBit, 'pgList'));
    //   store.setRecurringPgList(payDeviceFilter(deviceBit, 'recurringPgList'));
    // };

    // if (AppManager.getInstance().isAndroidApp()) {
    //   pgListFilter(Device.APP_ANDROID);
    // } else if (AppManager.getInstance().isIosApp()) {
    //   pgListFilter(Device.APP_IOS);
    // } else if (isMobile && isIOS) {
    //   pgListFilter(Device.MOBILE_IOS);
    // } else if (isMobile && isAndroid) {
    //   pgListFilter(Device.MOBILE_ANDROID);
    // } else pgListFilter(Device.PC);
    // store.setPgList(payDeviceFilter(Device.PC, 'pgList'));
    // store.setRecurringPgList(payDeviceFilter(Device.PC, 'recurringPgList'));
  }, [isAndroid, isIOS, isMobile, paymentContext]);

  // 관리자에서 기본으로 설정한 default 상품이 있다면, 처음 진입시 선택되도록 설정.
  useEffect(() => {
    if (!paymentContext || !paymentContext.defaultProduct) return;
    store.setSelectItem(paymentContext.defaultProduct);
  }, [paymentContext]);

  const purchaseAction = useCallback(
    (pgItem: PaymentMethodViewModel, context: PaymentContext) => {
      if (!store.selectItem) return;

      Cookies.remove('coinRefresh');
      AppManager.getInstance().currentPage.setter();
      // 멤버쉽 결제
      if (store.selectItem.groupString === 'MEMBERSHIP') {
        switch (pgItem.pg) {
          case 'eximbay': {
            EximbayPurchase.selectorForPopupRecurring(context);
            break;
          }
          case 'payletter': {
            PayLetterPurchase.payLetterAutoPurchase(context);
            break;
          }
          default: {
            console.warn('not found work', pgItem);
          }
        }
      } else {
        // 일반 결제
        switch (pgItem.pg) {
          case 'eximbay': {
            EximbayPurchase.selectorForPopup(context, pgItem.method);
            break;
          }
          case 'payletter': {
            PayLetterPurchase.payLetterPurchase(context);
            break;
          }
          // case 'paypal': {
          //   PaypalPurchase.paypalSelector(context);
          //   break;
          // }
          case 'xsolla': {
            // PaypalPurchase.paypalSelector(context, pgItem.method);
            break;
          }
          case 'stripe': {
            StripePurchase.stripeSeletor(context);
          }
          case 'paymentwall': {
            PaymentWallPurchase.paymentWallPurchase(context, pgItem.method); // google과 applePay는 바로가기 기능 없음
          }
          default: {
            console.warn('not found work', pgItem);
          }
        }
      }
    },
    [store.selectItem],
  );

  if (paymentContext) {
    const context: PaymentContext = {
      ...paymentContext,
      selectProductItem: store.selectItem,
      windowMode,
      pgList:
        store.selectItem?.groupString === 'MEMBERSHIP' &&
        store.selectItem?.cycle
          ? store.recurringPgList
          : store.pgList,
      isMobile,
      isStoreApp,
      token: UserSession.getUserInfo().token,
      loginInfo: UserSession.getUserInfo(),
      setSelectItem: store.setSelectItem,
      purchaseAction,
    };

    return <WrappedComponent {...context} />;
  }

  return null;
};

export default WithPaymentHoc;
