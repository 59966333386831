export const enum DeviceBit {
  NONE = 0,
  PC = 1,
  MOBILE = 2,
  TABLET = 4,
  ALL = 7,
}

export interface LayoutItemType {
  path: string;
  bodyWrapperVisible: number;
  headerVisible: number;
  footerVisible: number;
}

export const layoutList: LayoutItemType[] = [
  {
    path: '/',
    bodyWrapperVisible: DeviceBit.NONE,
    headerVisible: DeviceBit.ALL,
    footerVisible: DeviceBit.ALL,
  },
  {
    path: '/appSnsCallback',
    bodyWrapperVisible: DeviceBit.NONE,
    headerVisible: DeviceBit.NONE,
    footerVisible: DeviceBit.NONE,
  },
  {
    path: '/transferCallback',
    bodyWrapperVisible: DeviceBit.NONE,
    headerVisible: DeviceBit.ALL,
    footerVisible: DeviceBit.ALL,
  },
  {
    path: '/ipblock',
    bodyWrapperVisible: DeviceBit.NONE,
    headerVisible: DeviceBit.ALL,
    footerVisible: DeviceBit.ALL,
  },
  {
    path: '/event/theme/[themeId]',
    bodyWrapperVisible: DeviceBit.PC,
    headerVisible: DeviceBit.ALL,
    footerVisible: DeviceBit.ALL,
  },
  {
    path: '/content/[comicId]/[episodeId]',
    bodyWrapperVisible: DeviceBit.NONE,
    headerVisible: DeviceBit.NONE,
    footerVisible: DeviceBit.NONE,
  },
  {
    path: '/comic/[comicId]/[episodeId]',
    bodyWrapperVisible: DeviceBit.NONE,
    headerVisible: DeviceBit.NONE,
    footerVisible: DeviceBit.NONE,
  },
  {
    path: '/comicprologue/[comicId]/[episodeId]',
    bodyWrapperVisible: DeviceBit.NONE,
    headerVisible: DeviceBit.NONE,
    footerVisible: DeviceBit.NONE,
  },
  {
    path: '/popup',
    bodyWrapperVisible: DeviceBit.ALL,
    headerVisible: DeviceBit.NONE,
    footerVisible: DeviceBit.NONE,
  },
  {
    path: '/cb/[pg]',
    bodyWrapperVisible: DeviceBit.ALL,
    headerVisible: DeviceBit.NONE,
    footerVisible: DeviceBit.NONE,
  },
  {
    path: '/complete/[payment]',
    bodyWrapperVisible: DeviceBit.ALL,
    headerVisible: DeviceBit.NONE,
    footerVisible: DeviceBit.NONE,
  },
  {
    path: '/auth/google',
    bodyWrapperVisible: DeviceBit.ALL,
    headerVisible: DeviceBit.NONE,
    footerVisible: DeviceBit.NONE,
  },
  {
    path: '/popup/[pgId]',
    bodyWrapperVisible: DeviceBit.ALL,
    headerVisible: DeviceBit.NONE,
    footerVisible: DeviceBit.NONE,
  },
  {
    path: '/blockUser',
    bodyWrapperVisible: DeviceBit.ALL,
    headerVisible: DeviceBit.NONE,
    footerVisible: DeviceBit.ALL,
  },
  {
    path: '/serverCheck',
    bodyWrapperVisible: DeviceBit.ALL,
    headerVisible: DeviceBit.NONE,
    footerVisible: DeviceBit.NONE,
  },
  {
    path: '/content/[comicId]',
    bodyWrapperVisible: DeviceBit.ALL,
    headerVisible: DeviceBit.PC,
    footerVisible: DeviceBit.ALL,
  },
  {
    path: '/comic/[comicId]',
    bodyWrapperVisible: DeviceBit.ALL,
    headerVisible: DeviceBit.PC,
    footerVisible: DeviceBit.ALL,
  },
  {
    path: '/hashtag/genre/[genre]',
    bodyWrapperVisible: DeviceBit.ALL,
    headerVisible: DeviceBit.PC + DeviceBit.TABLET,
    footerVisible: DeviceBit.PC + DeviceBit.TABLET,
  },
  {
    path: '/hashtag/keyword/[keyword]',
    bodyWrapperVisible: DeviceBit.ALL,
    headerVisible: DeviceBit.PC + DeviceBit.TABLET,
    footerVisible: DeviceBit.PC + DeviceBit.TABLET,
  },
  {
    path: '/event/theme/[themeId]',
    bodyWrapperVisible: DeviceBit.NONE,
    headerVisible: DeviceBit.ALL,
    footerVisible: DeviceBit.ALL,
  },
  {
    path: '/coupon',
    bodyWrapperVisible: DeviceBit.NONE,
    headerVisible: DeviceBit.ALL,
    footerVisible: DeviceBit.ALL,
  },
  {
    path: '/paytrans',
    bodyWrapperVisible: DeviceBit.NONE,
    headerVisible: DeviceBit.NONE,
    footerVisible: DeviceBit.NONE,
  },
  {
    path: '/paytrans/[pg]',
    bodyWrapperVisible: DeviceBit.NONE,
    headerVisible: DeviceBit.NONE,
    footerVisible: DeviceBit.NONE,
  },
];
